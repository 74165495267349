import * as React from "react";
import {ChangeEvent} from "react";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import {CropOriginal} from "@material-ui/icons";
import {IconButton, TextField} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FtTextFieldPercent from "../ui/FtTextFieldPercent";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FssClientData from "../data/FssClientData";
import {ETwoStatus} from "../model/FtAbstractModel";
import FssStoreCoupon, {EHowToReceive} from "./FssStoreCoupon";
import FssStoreCouponService from "../service/FssStoreCouponService";
import CloseIcon from "@material-ui/icons/Close";
import {grey} from "@material-ui/core/colors";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";

interface FssStoreCouponDetailPageProp extends RouteComponentProps<{ storeId: string, couponId: string }> {
}

export class FssStoreCouponDetailPageState {
    storeCoupon: FssStoreCoupon = new FssStoreCoupon();
    startDatetimeLabel: string = "";
    endDatetimeLabel: string = "";
}


export default class FssStoreCouponDetailPage extends React.Component<FssStoreCouponDetailPageProp, FssStoreCouponDetailPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            storeCoupon: new FssStoreCoupon(),
            startDatetimeLabel: "开始时间",
            endDatetimeLabel: "结束时间",
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    //获取详情
    load() {
        if (this.props.match.params.couponId != null && this.props.match.params.couponId != "0") {
            FssStoreCouponService.getById(this.props.match.params.couponId, (result: FssStoreCoupon) => {
                let startDatetimeLabel = i18n("领取开始时间");
                let endDatetimeLabel = i18n("领取结束时间");
                if (result.howToUse == "SYSTEM" || result.howToUse == "COUPONCODE") {
                    startDatetimeLabel = i18n("开始时间");
                    endDatetimeLabel = i18n("结束时间");
                }
                this.setState({
                    storeCoupon: result,
                    startDatetimeLabel: startDatetimeLabel,
                    endDatetimeLabel: endDatetimeLabel
                });
            });
        }
    }

    //返回
    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.storeCoupon, event.target.id, event.target.value);
        this.forceUpdate();
    }

    //保存详情
    onClickSave() {
        if (this.state.storeCoupon.status == "") {
            FtSnackbar.showText(i18n("请选择『状态』"));
            return;
        }
        if (this.state.storeCoupon.couponType == "") {
            FtSnackbar.showText(i18n("请选择『类型』"));
            return;
        }
        if (this.state.storeCoupon.actionType == "") {
            FtSnackbar.showText(i18n("请选择『抵扣金类型』"));
            return;
        }
        if (this.state.storeCoupon.howToUse == "") {
            FtSnackbar.showText(i18n("请选择『使用方式』"));
            return;
        }
        if (this.state.storeCoupon.expireStandard == "") {
            FtSnackbar.showText(i18n("请选择『优惠券有效期标准』"));
            return;
        }
        if (this.state.storeCoupon.howToReceive == "") {
            FtSnackbar.showText(i18n("请选择『如何发放』"));
            return;
        }
        if (this.state.storeCoupon.userAmountLimit <= 0) {
            FtSnackbar.showText(i18n("请填写每个用户限领数量"));
            return;
        }
        if (this.state.storeCoupon.storeId == null || this.state.storeCoupon.storeId.trim().length == 0) {
            this.state.storeCoupon.storeId = this.props.match.params.storeId;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssStoreCouponService.save(this.state.storeCoupon, (data: FssStoreCoupon) => {
            this.setState({storeCoupon: data});
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="outlined" color="primary"
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                <FtPanel panelHeader={i18n("基础信息")}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <JtTextField id="couponName" onChange={this.onChange}
                                         value={this.state.storeCoupon.couponName}
                                         label={i18n("优惠券名称")} required={true} style={{width: "100%"}}/>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("状态")} </InputLabel>
                                <Select id="status" labelId="demo-simple-select-label"
                                        value={this.state.storeCoupon.status}
                                        onChange={(event, child) => {
                                            this.state.storeCoupon.status = event.target.value as string;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={ETwoStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                    <MenuItem value={ETwoStatus.DISABLED}>{i18n("停用")}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id="createDatetime" label={i18n("创建时间")}
                                       value={FssClientData.formatDatetime(this.state.storeCoupon.createDatetime)}
                                       style={{width: "100%"}} disabled={true}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("优惠券类型")} </InputLabel>
                                <Select id="couponType" labelId="demo-simple-select-label"
                                        value={this.state.storeCoupon.couponType} onChange={(event, child) => {
                                    this.state.storeCoupon.couponType = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.storeCoupon.receiveAmount > 0}>
                                    <MenuItem value={"PERCENT"}>{i18n("打折劵")}</MenuItem>
                                    <MenuItem value={"MONEY"}>{i18n("满减劵")}</MenuItem>
                                </Select>
                            </FormControl>
                            {this.state.storeCoupon.couponType == "PERCENT" &&
                            <FtTextFieldPercent id="discount"
                                                onChangeValue={(value) => {
                                                    if (value) {
                                                        this.state.storeCoupon.discount = value;
                                                    } else {
                                                        this.state.storeCoupon.discount = 0;
                                                    }
                                                    this.forceUpdate();
                                                }}
                                                value={this.state.storeCoupon.discount}
                                                label={i18n("折扣(%)")} required={true} style={{width: "100%"}}
                                                disabled={this.state.storeCoupon.receiveAmount > 0}/>
                            }
                            <TextField id="orderTotalMoney" onChange={this.onChange}
                                       value={this.state.storeCoupon.orderTotalMoney}
                                       label={i18n("订单金额要求")} type={"number"} style={{width: "100%"}}
                                       disabled={this.state.storeCoupon.receiveAmount>0} />
                            {this.state.storeCoupon.couponType == "MONEY" &&
                            <JtTextField id="discount" onChange={this.onChange}
                                         value={this.state.storeCoupon.discount}
                                         label={i18n("降价(元)")} required={true} style={{width: "100%"}}
                                         disabled={this.state.storeCoupon.receiveAmount > 0}/>
                            }
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("抵扣金额类型")} </InputLabel>
                                <Select id="actionType" labelId="demo-simple-select-label"
                                        value={this.state.storeCoupon.actionType} onChange={(event, child) => {
                                    this.state.storeCoupon.actionType = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.storeCoupon.receiveAmount > 0}>
                                    <MenuItem value={"TOTALMONEY"}>{i18n("全部金额")}</MenuItem>
                                    <MenuItem value={"PRODUCTMONEY"}>{i18n("仅限商品金额")}</MenuItem>
                                    <MenuItem value={"SHIPMONEY"}>{i18n("仅限运费")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("如何发放")} </InputLabel>
                                <Select value={this.state.storeCoupon.howToReceive}
                                        onChange={(event, child) => {
                                            this.state.storeCoupon.howToReceive = event.target.value as string;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EHowToReceive.LANDING_PAGE}>{i18n("着陆页发放")}</MenuItem>
                                    <MenuItem value={EHowToReceive.STORE_ADMIN}>{i18n("商家后台发放")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            {/*<FormControl style={{width: "100%"}}>*/}
                            {/*    <InputLabel id="demo-simple-select-label"> {i18n("使用方式")} </InputLabel>*/}
                            {/*    <Select value={this.state.storeCoupon.howToUse} disabled={this.state.storeCoupon.receiveAmount > 0}>*/}
                            {/*        <MenuItem value={"CHOOSE"}>{i18n("用户选择")}</MenuItem>*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                            <TextField id="startDatetimeString" type="datetime-local" onChange={this.onChange}
                                       required={true} InputLabelProps={{shrink: true,}}
                                       value={this.state.storeCoupon.startDatetimeString}
                                       label={this.state.startDatetimeLabel}
                                       style={{width: "100%"}}
                                       disabled={this.state.storeCoupon.receiveAmount > 0}/>
                            <TextField id="endDatetimeString" onChange={this.onChange} InputLabelProps={{shrink: true,}}
                                       required={true} value={this.state.storeCoupon.endDatetimeString}
                                       label={this.state.endDatetimeLabel} type="datetime-local"
                                       style={{width: "100%"}}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            {this.state.storeCoupon.howToUse == "CHOOSE" &&
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("使用有效期标准")}</InputLabel>
                                <Select id="howToUse" labelId="demo-simple-select-label"
                                        value={this.state.storeCoupon.expireStandard} onChange={(event, child) => {
                                    this.state.storeCoupon.expireStandard = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.storeCoupon.receiveAmount > 0}>
                                    <MenuItem value={"EQUALS"}>{i18n("与母劵有效期一致")}</MenuItem>
                                    <MenuItem value={"DELAY"}>{i18n("领取时间延后")}</MenuItem>
                                </Select>
                            </FormControl>}
                            {this.state.storeCoupon.howToUse == "CHOOSE" && this.state.storeCoupon.expireStandard == "DELAY" &&
                            <TextField id="expireDays" required={true} onChange={this.onChange}
                                       value={this.state.storeCoupon.expireDays ? this.state.storeCoupon.expireDays : ''}
                                       label={i18n("有效期(日)")} type={"number"} style={{width: "100%"}}
                                       disabled={this.state.storeCoupon.receiveAmount > 0}/>}
                            <TextField id="totalAmountLimit" required={true} onChange={this.onChange}
                                       value={this.state.storeCoupon.totalAmountLimit ? this.state.storeCoupon.totalAmountLimit : ''}
                                       label={i18n("总发放数")} type={"number"} style={{width: "100%"}}/>
                            <TextField id="receiveAmount" disabled={true}
                                       value={this.state.storeCoupon.receiveAmount ? this.state.storeCoupon.receiveAmount : ''}
                                       label={i18n("已领取数")} type={"number"} style={{width: "100%"}}/>
                            <TextField id="userAmountLimit" required={true} onChange={this.onChange}
                                       value={this.state.storeCoupon.userAmountLimit ? this.state.storeCoupon.userAmountLimit : ''}
                                       label={i18n("每个用户限领数量")} type={"number"} style={{width: "100%"}}/>
                        </FtRow>
                        {this.getUploadComp()}
                        <FtRow cellWidthS={"1"} cellWidthM={"4"} cellWidthL={"4"} cellHorizontalSpace={32}
                               maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <JtTextField id="description" multiline onChange={this.onChange}
                                         value={this.state.storeCoupon.description} label={i18n("描述")} type={"text"}
                                         style={{width: "100%"}}/>
                        </FtRow>
                    </FtGrid>
                </FtPanel>
            </FtRow>
        </FtPage>;
    }

    getUploadComp() {
        if (this.state.storeCoupon.flyerImageUrl && this.state.storeCoupon.flyerImageUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 300}}>
                <img src={this.state.storeCoupon.flyerImageUrl} style={{width: "100%"}}/>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {
                        this.state.storeCoupon.flyerImageUrl = "";
                        this.forceUpdate();
                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 118, height: 118,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginal viewBox={"3 3 18 18"} style={{width: 40, height: 40}}/>
            </div>;
            return <FtFileUploadButtonToOss buttonStyle={buttonStyle}
                                            onFinish={(fileUrl: string, fileName: string) => {
                                                if (fileUrl != null && fileUrl.length > 0) {
                                                    this.state.storeCoupon.flyerImageUrl = fileUrl;
                                                    this.forceUpdate();
                                                }
                                            }}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }
}
