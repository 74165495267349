import * as React from "react";
import {CSSProperties} from "react";
import {RouteComponentProps} from "react-router-dom";
import {i18n} from "../util/I18n";
import FssStoreService from "../service/FssStoreService";
import FssStore from "../model/FssStore";
import FtToast from "../ui/FtToast";
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import FtTabs from "../ui/FtTabs";
import FtState from "../ui/FtState";
import FtTabPanel from "../ui/FtTabPanel";
import {theme} from "../mainframe/FssWebsiteAdminTheme";
import FssStoreCategoryPage from "./FssStoreCategoryPage";
import FssStoreDetailPage from "./FssStoreDetailPage";
import FtUtil from "../util/FtUtil";
import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import FssProductSettingPage from "../setting/FssProductSettingPage";
import FssCouponListPage from "../marketing/FssCouponListPage";
import FssUserCouponListPage from "../marketing/FssUserCouponListPage";
import FssPointRuleInListPage from "../seller/FssPointRuleInListPage";
import FssPointRuleOutPage from "../seller/FssPointRuleOutPage";

interface FssStoreDetailTabsPageProp extends RouteComponentProps<{ id: string }> {

}

export class FssStoreDetailTabsPageState {
    store: FssStore = new FssStore();
    tabValue: string = ETabValue.BASIC_INFO;
}

export enum ETabValue {
    BASIC_INFO = "BASIC_INFO",
    STORE_CATEGORY = "STORE_CATEGORY",
    PRODUCT_SETTING = "PRODUCT_SETTING",
    COUPON = "COUPON",
    USER_COUPON = "USER_COUPON",
    POINT_RULE_LIST = "POINT_RULE_LIST",
    POINT_RULE_UPDATE = "POINT_RULE_UPDATE",
}

export default class FssStoreDetailTabsPage extends React.Component<FssStoreDetailTabsPageProp, FssStoreDetailTabsPageState> {
    stateKey = "FssStoreDetailTabsPageState_tabValue";

    constructor(props: any) {
        super(props);
        let tabValue = FtState.get(this.stateKey);
        if (tabValue == null) {
            tabValue = ETabValue.BASIC_INFO;
        }
        this.state = {store: new FssStore(), tabValue: tabValue,};
        this.onCancelClick = this.onCancelClick.bind(this);
        this.load = this.load.bind(this);
        this.getTabPanel = this.getTabPanel.bind(this);
        this.onChangeStore = this.onChangeStore.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.id != null && this.props.match.params.id.trim().length > 1) {
            FtToast.showToastLoading("loading");
            FssStoreService.getStoreById(this.props.match.params.id, (store: FssStore) => {
                FtToast.closeToast();
                this.setState({store: store});
            });
        }
    }

    onChangeStore(name: string, value: string) {
        FtUtil.setProperty(this.state.store, name, value);
        this.forceUpdate();
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    render() {
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={this.onCancelClick} style={{cursor: "pointer"}}>
                    分站列表
                </Link>
                <Typography color="textPrimary">
                    {ETabValue.BASIC_INFO == this.state.tabValue && "分站基本信息"}
                    {ETabValue.STORE_CATEGORY == this.state.tabValue && "商品分类列表"}
                    {ETabValue.PRODUCT_SETTING == this.state.tabValue && "商品设置"}
                    {ETabValue.COUPON == this.state.tabValue && "优惠券列表"}
                    {ETabValue.USER_COUPON == this.state.tabValue && "已领取优惠券列表"}
                    {ETabValue.POINT_RULE_LIST == this.state.tabValue && "积分获取规则列表"}
                    {ETabValue.POINT_RULE_UPDATE == this.state.tabValue && "修改积分兑换规则"}
                </Typography>
            </Breadcrumbs>
            <FtTabs value={this.state.tabValue}
                    style={{borderBottomColor: grey[300], borderBottomStyle: "solid", borderBottomWidth: 1}}
                    indicatorColor="primary" textColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        this.setState({tabValue: newValue});
                        FtState.put(this.stateKey, newValue);
                    }}>
                {this.getTabPanel(ETabValue.BASIC_INFO, "分站基本信息")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.STORE_CATEGORY, "商品分类列表")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.PRODUCT_SETTING, "商品设置")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.COUPON, "优惠券列表")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.USER_COUPON, "已领取优惠券列表")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.POINT_RULE_LIST, "积分获取规则列表")}
                {this.state.store.id != null && this.state.store.id.trim().length > 0 && this.getTabPanel(ETabValue.POINT_RULE_UPDATE, "修改积分兑换规则")}
            </FtTabs>
            <FtMediaViewerDialog ref={(element: any) => {
                FtMediaViewerDialog.FtMediaViewerDialog = element;
            }}/>
        </div>
    }

    getTabPanel(tabValue: ETabValue, label: string) {
        const styles: CSSProperties = {
            // borderTopLeftRadius: 25,
            // borderTopRightRadius: 25,
            backgroundColor: "white",
            color: theme.palette.primary.main,
            borderRight: 0,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
        };
        const selectedStyles: CSSProperties = {
            // borderTopLeftRadius: 25,
            // borderTopRightRadius: 25,
            color: "white",
            backgroundColor: theme.palette.primary.main,
            borderRight: 0,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
        };
        if (tabValue == ETabValue.BASIC_INFO) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssStoreDetailPage store={this.state.store} onChange={this.onChangeStore}/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.STORE_CATEGORY) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssStoreCategoryPage store={this.state.store}/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.PRODUCT_SETTING) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssProductSettingPage/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.COUPON) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssCouponListPage store={this.state.store}/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.USER_COUPON) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssUserCouponListPage store={this.state.store}/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.POINT_RULE_LIST) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssPointRuleInListPage store={this.state.store}/>}
            </FtTabPanel>
        } else if (tabValue == ETabValue.POINT_RULE_UPDATE) {
            return <FtTabPanel style={this.state.tabValue == tabValue ? selectedStyles : styles}
                               label={i18n(label)} value={tabValue}>
                {this.state.tabValue == tabValue &&
                <FssPointRuleOutPage store={this.state.store}/>}
            </FtTabPanel>
        }
        return null;
    }
}
