import FtPagedList from "../model/FtPagedList";
import FssPointRule from "../model/FssPointRule";
import FssHttpService from "./FssHttpService";

export default class FssPointService {
    static getFssPointRuleList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                               pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssPointRule>) => void,
                               failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/point/getFssPointRuleList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static saveFssPointRule(jtScoreRule: FssPointRule, successFunction: (data: FssPointRule) => void,
                            failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/point/saveFssPointRule", jtScoreRule, successFunction, FssHttpService.handleError);
    }

    static getFssPointRuleByRuleCode(ruleCode: string, storeId: string, successFunction: (data: FssPointRule) => void,
                                     failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/point/getFssPointRuleByRuleCode", {ruleCode: ruleCode, storeId: storeId},
            successFunction, FssHttpService.handleError);
    }
}
