import {FtAbstractModel} from "./FtAbstractModel";

export enum ERuleCode {
    SCORE_EXCHANGE_MONEY_RATE = "SCORE_EXCHANGE_MONEY_RATE",
    MONEY_GIVE_SCORE_RATE = "MONEY_GIVE_SCORE_RATE",
    REGISTERED_GIVE_SCORE = "REGISTERED_GIVE_SCORE",
    SHARE_REGISTERED_GIVE_SCORE = "SHARE_REGISTERED_GIVE_SCORE",
    SHARE_ORDER_GIVE_SCORE = "SHARE_ORDER_GIVE_SCORE",
    PRODUCT_EVALUATION_GIVE_SCORE = "PRODUCT_EVALUATION_GIVE_SCORE",
}

export enum ERuleStatus {
    NORMAL = "NORMAL",
    STOP = "STOP"
}

export default class FssPointRule extends FtAbstractModel {
    storeId: string = "";
    ruleCode: string = "";
    ruleName: string = "";
    value: number = 0;
    scoreAmountLimit: number = 0;
    status: ERuleStatus = ERuleStatus.NORMAL;
    type: string = "";
    createDatetime?: string = "";
    description?: string = "";
    balanceAmountLimit: number = 0;
}
