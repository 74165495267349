import * as React from "react";
import {ChangeEvent, CSSProperties} from "react";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import FtUtil from "../util/FtUtil";

interface FtComboboxDynamicProp {
    onChangeInput: (inputValue: string) => void;
    label?: string;
    optionItems?: Array<any>;
    labelPropertyName?: string;
    valuePropertyName?: string;
    onSelectItem?: (item: any) => void;
    addButtonLabel?:string;
    onClickAdd?: (value: string) => void;
    selectedItem?:any;
    loading:boolean;
    style?:CSSProperties;
    variant?: "standard" | "filled" | "outlined";
}

export class FtComboboxDynamicState {
}

export default class FtComboboxDynamic extends React.Component<FtComboboxDynamicProp, FtComboboxDynamicState> {
    inputElement!: HTMLInputElement;

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onSelectItem = this.onSelectItem.bind(this);
        this.getOptionSelected = this.getOptionSelected.bind(this);
        this.getOptionLabel = this.getOptionLabel.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
    }

    onSelectItem(event: React.ChangeEvent<{}>, item: any) {
        if(this.props.addButtonLabel!=null&&item===this.props.addButtonLabel) {
            if(this.props.onClickAdd)
                return this.props.onClickAdd(item as string);
        }else {
            this.setState({selectedItem: item});
            if (this.props.onSelectItem)
                this.props.onSelectItem(item);
        }
    }

    getOptionSelected(option: any, value: any): boolean {
        if (this.props.valuePropertyName != null) {
            const optionValue = FtUtil.getProperty(option, this.props.valuePropertyName);
            const valueValue = FtUtil.getProperty(value, this.props.valuePropertyName);
            if (optionValue == valueValue)
                return true;
        }
        return false;
    }

    getOptionLabel(option: any) {
        if (this.props.labelPropertyName != null) {
            if(this.props.addButtonLabel!=null&&option===this.props.addButtonLabel)
                return this.props.addButtonLabel;
            else
                return FtUtil.getProperty(option, this.props.labelPropertyName);
        }else
            return "";
    }

    render() {
        const styles: CSSProperties | undefined = this.props.style;
        let options=this.props.optionItems;
        if(this.props.onClickAdd){
            options=new Array().concat(this.props.optionItems);
            options.push(this.props.addButtonLabel)
        }

        return <Autocomplete
            options={options ? options : []}
            getOptionLabel={this.getOptionLabel}
            style={styles} value={this.props.selectedItem}
            getOptionSelected={this.getOptionSelected}
            renderInput={(params) => <TextField {...params} variant={this.props.variant} label={this.props.label} style={styles}/>}
            onChange={this.onSelectItem}
            onInputChange={this.onChangeInput}
            loading={this.props.loading}
        />;
    }

    onChangeInput(event: ChangeEvent<{}>, value: string) {
        this.props.onChangeInput(value);
    }
}