import * as React from "react";
import {ChangeEvent} from "react";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "react-router-dom";
import FtToolbar from "../ui/FtToolbar";
import grey from "@material-ui/core/colors/grey";
import {i18n} from "../util/I18n";
import FssStoreService from "../service/FssStoreService";
import FssStore from "../model/FssStore";
import FtUtil from "../util/FtUtil";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FssClientData from "../data/FssClientData";
import {
    CardMedia,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CloseIcon from '@material-ui/icons/Close';
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import FssSellerService from "../service/FssSellerService";
import FssSeller, {ESellerStatus, ESellerType} from "../model/FssSeller";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FtSnackbar from "../ui/FtSnackbar";
import FssPointRule from "../model/FssPointRule";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";

interface FssSellerDetailPageProp extends RouteComponentProps<{ id: string }> {
}

export class FssSellerDetailState {
    seller: FssSeller = new FssSeller();
    storeList: Array<FssStore> = [];
    pointRuleList: Array<FssPointRule> = [];
    sellerType: ESellerType = ESellerType.LITE;

}


export default class FssSellerDetailPage extends React.Component<FssSellerDetailPageProp, FssSellerDetailState> {

    constructor(props: any) {
        super(props);
        this.state = {
            seller: new FssSeller(),
            storeList: [],
            pointRuleList: [],
            sellerType: ESellerType.LITE
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeBypropertyName = this.onChangeBypropertyName.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.load = this.load.bind(this);
        this.loadStoreList = this.loadStoreList.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
    }

    componentDidMount(): void {
        this.load();
        this.loadStoreList();
    }

    load() {
        if (this.props.match.params.id != null && this.props.match.params.id.trim().length > 1) {
            FtToast.showToastLoading("loading");
            FssSellerService.getSellerById(this.props.match.params.id, (seller: FssSeller) => {
                FtToast.closeToast();
                this.setState({seller: seller, sellerType: seller.sellerType});
            });
        }
    }

    loadStoreList() {
        let filterColumns = new Map<string, any>();
        FssStoreService.getStorePagedList("", filterColumns, null,
            null, 0, 999, (result: FtPagedList<FssStore>) => {
                FtToast.closeToast();
                this.setState({storeList: result.content});
            });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.seller, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeBypropertyName(id: string, value?: unknown) {
        FtUtil.setProperty(this.state.seller, id, value);
        this.forceUpdate();
    }

    onClickSave() {
        if (this.state.seller.shortName == "") {
            FtSnackbar.showText(i18n("分站名称不能为空"));
            return;
        }
        if (this.state.seller.storeId == "") {
            FtSnackbar.showText(i18n("选择分站"));
            return;
        }
        if (this.state.seller.servicePhone == "") {
            FtSnackbar.showText(i18n("手机号不能为空"));
            return;
        }
        if (this.state.seller.serviceEmail == "") {
            FtSnackbar.showText(i18n("email不能为空"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssSellerService.saveMySeller(this.state.seller, (seller: FssSeller) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功"));
        })
    }

    private onFinishUpload(fileUrl: string, fileName: string) {
        if (fileUrl != null && fileUrl.length > 0) {
            this.onChangeBypropertyName("logoUrl", fileUrl)
        }
    }

    render() {
        let isEdit = this.state.seller && this.state.seller.id && this.state.seller.id.length > 0;
        return <div style={{backgroundColor: grey[100]}}>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}>{i18n("返回")}</Button>
                <Button color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
            </div>}>
            </FtToolbar>
            <FtPanel panelHeader={i18n("基本信息")}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                        <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"}>
                            <JtTextField id="shortName" label={i18n("分站名称")}
                                         value={this.state.seller.shortName}
                                         style={{width: "100%"}} onChange={this.onChange}/>
                            {isEdit && <JtTextField id="createDatetime" label={i18n("创建时间")}
                                                    value={FssClientData.formatDatetime(this.state.seller.createDatetime)}
                                                    style={{width: "100%"}} disabled={true}/>}
                        </FtRow>
                        <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"}>
                            <JtTextField id="servicePhone" label={i18n("手机号")}
                                         value={this.state.seller.servicePhone} style={{width: "100%"}}
                                         onChange={this.onChange}
                            />

                            <JtTextField id="serviceEmail" label={i18n("Email")}
                                         value={this.state.seller.serviceEmail} style={{width: "100%"}}
                                         onChange={this.onChange}
                            />
                        </FtRow>
                        <FormControl>
                            <FormLabel>所属分站</FormLabel>
                            <RadioGroup row value={this.state.seller.storeId}
                                        onChange={(event) => {
                                            this.onChangeBypropertyName("storeId", event.target.value)
                                        }}>
                                {this.state.storeList && this.state.storeList.length > 0 &&
                                this.state.storeList.map((store: FssStore) => {
                                    return <FormControlLabel value={store.id} control={<Radio/>}
                                                             label={store.storeName}/>;
                                })}
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>等级</FormLabel>
                            <RadioGroup row value={this.state.seller.sellerType ? this.state.seller.sellerType : ""}
                                        onChange={(event) => {
                                            this.onChangeBypropertyName("sellerType", event.target.value)
                                        }}>
                                <FormControlLabel value={ESellerType.LITE} control={<Radio/>} label={"基础"}/>
                                <FormControlLabel value={ESellerType.STANDARD} control={<Radio/>} label={"高级"}/>
                                <FormControlLabel value={ESellerType.PUBLISHER} control={<Radio/>} label={"团长"}/>

                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>状态</FormLabel>
                            <RadioGroup row value={this.state.seller.sellerStatus ? this.state.seller.sellerStatus : ""}
                                        onChange={(event) => {
                                            this.onChangeBypropertyName("sellerStatus", event.target.value)
                                        }}>
                                <FormControlLabel value={ESellerStatus.NORMAL} control={<Radio/>} label={"正常"}/>
                                <FormControlLabel value={ESellerStatus.STOP} control={<Radio/>} label={"停用"}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>是否默认店铺</FormLabel>
                            <RadioGroup row value={this.state.seller.isDefaultSeller}
                                        onChange={(event) => {
                                            this.onChangeBypropertyName("isDefaultSeller", event.target.value)
                                        }}>
                                <FormControlLabel value={"1"} control={<Radio/>} label={"是"}/>
                                <FormControlLabel value={"0"} control={<Radio/>} label={"否"}/>
                            </RadioGroup>
                        </FormControl>
                        <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"}>
                            <FtTextFieldNumber id="commissionRate" label={i18n("返利比例(例:0.1表示返利10%)")}
                                               value={this.state.seller.commissionRate ? this.state.seller.commissionRate : 0}
                                               type="float"
                                               onChangeValue={value => this.onChangeBypropertyName("commissionRate", value)}
                                               style={{width: "100%"}}
                                               inputStyle={{
                                                   textAlign: "left"
                                               }}/>
                        </FtRow>
                        <Typography>LOGO</Typography>
                        {this.getUploadComp()}
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtMediaViewerDialog ref={(element: any) => {
                FtMediaViewerDialog.FtMediaViewerDialog = element;
            }}/>
        </div>
    }

    getUploadComp() {
        if (this.state.seller.logoUrl && this.state.seller.logoUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 100, height: 100}}>
                <CardMedia image={this.state.seller.logoUrl} style={{height: 100, width: 100}}
                           onClick={(event) => {
                               event.stopPropagation();
                               this.onClickZoomOut(this.state.seller.logoUrl);
                           }}>
                </CardMedia>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {
                        this.onChangeBypropertyName("logoUrl", "")
                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 98, height: 98,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginalIcon viewBox={"3 3 18 18"} style={{width: 35, height: 35}}/>
                <Typography>添加LOGO</Typography>
            </div>;
            return <FtFileUploadButtonToOss onFinish={this.onFinishUpload}
                                            buttonStyle={buttonStyle}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }

    onClickZoomOut(mediaUrl?: string) {
        if (mediaUrl && mediaUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(mediaUrl, "image");
        }
    }


}
