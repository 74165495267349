import * as React from "react";
import {ChangeEvent} from "react";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {Paper, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtToolbar from "../ui/FtToolbar";
import {Save} from "@material-ui/icons";
import FtPanel from "../ui/FtPanel";
import Typography from "@material-ui/core/Typography";
import {i18n} from "../util/I18n";
import FssPointRule, {ERuleCode, ERuleStatus} from "../model/FssPointRule";
import FtRow from "../ui/FtRow";
import FssPointService from "../service/FssPointService";
import FssStore from "../model/FssStore";
import FtGrid from "../ui/FtGrid";

interface FssPointRuleOutPageProp {
    store: FssStore;
}

export class FssPointRuleOutPageState {
    pointRule: FssPointRule = new FssPointRule();

}

export default class FssPointRuleOutPage extends React.Component<FssPointRuleOutPageProp, FssPointRuleOutPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pointRule: new FssPointRule(),
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    //获取详情
    load() {
        FssPointService.getFssPointRuleByRuleCode(ERuleCode.SCORE_EXCHANGE_MONEY_RATE, this.props.store.id, (result: FssPointRule) => {
            let pointRule: FssPointRule = FtUtil.mergeObject(this.state.pointRule, result);
            this.setState({pointRule: pointRule});
        });
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.pointRule, event.target.id, event.target.value);
        this.forceUpdate();
    }

    //保存
    onClickSave() {
        FtMessageDialog.dialog.openDialog(i18n("确认保存吗？"), () => {
            FtToast.showToastLoading(i18n("正在保存"));
            FssPointService.saveFssPointRule(this.state.pointRule, (data: FssPointRule) => {
                this.setState({pointRule: FtUtil.mergeObject(this.state.pointRule, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
                this.load();
            });
        }, () => {

        }, i18n("保存"), i18n("放弃修改"));
    }

    render() {
        return <div>
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button color="primary" variant={"outlined"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                       </FtRow>}/>
            <FtPanel panelHeader={i18n("积分兑换规则设置")} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
                <FtGrid>
                    <FtRow cellWidthS={"1,1,1,1"} cellVerticalSpace={32}>
                        <FormControl style={{width: "100%"}}>
                            <TextField select id="status" label={i18n("状态")} value={this.state.pointRule.status}
                                       style={{textAlign: "left"}}
                                       onChange={(event: any) => {
                                           this.state.pointRule.status = event.target.value as ERuleStatus;
                                           this.forceUpdate();
                                       }}>
                                <MenuItem value={ERuleStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                <MenuItem value={ERuleStatus.STOP}>{i18n("停用")}</MenuItem>
                            </TextField>
                        </FormControl>
                        <TextField id="value" label={i18n("每积分可兑换货币")} type="number"
                                   value={this.state.pointRule.value}
                                   style={{width: "100%"}}
                                   onChange={this.onChange}/>
                    </FtRow>
                    <FtRow cellWidthS={"1,1,1,1"} cellVerticalSpace={32}>
                        <TextField id="scoreAmountLimit" label={i18n("用户最低持有积分限制(0为不限制)")} type="number"
                                   value={this.state.pointRule.scoreAmountLimit ? this.state.pointRule.scoreAmountLimit : 0}
                                   style={{width: "100%"}}
                                   onChange={this.onChange}/>
                        <TextField id="balanceAmountLimit" label={i18n("金额限制")} type="number"
                                   value={this.state.pointRule.balanceAmountLimit ? this.state.pointRule.balanceAmountLimit : 0}
                                   style={{width: "100%"}}
                                   onChange={this.onChange}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={0} cellHorizontalSpace={0}>
                        <Typography variant="subtitle2">{i18n("积分兑换规则设置说明")}</Typography>
                        <Typography variant="caption">{i18n("1.平台根据用户获得的有效积分进行换算，得出积分可抵扣的订单金额")}</Typography>
                        <Typography variant="caption">{i18n("2.用户是否可使用积分进行抵扣受用户持有积分数量和该订单金额限制，此处不设置限制请填写0")}</Typography>
                        <Typography variant="caption">{i18n("3.抵扣过的积分在发生退货时不退还")}</Typography>
                        <Typography variant="caption">{i18n("4.如积分规则状态为暂停或者停用的，在订单页不会显示积分抵扣相关的提示")}</Typography>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>
    }
}
