import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tooltip,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import FtToolbar from "../ui/FtToolbar";
import {ListAlt} from "@material-ui/icons";
import FssUserProfile from "../model/FssUserProfile";
import JtTextField from "../ui/JtTextField";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import grey from "@material-ui/core/colors/grey";
import FtRow from "../ui/FtRow";
import FtGrid from "../ui/FtGrid";
import FtPanel from "../ui/FtPanel";
import FssUserBalanceTransaction, {EBalanceType} from "../model/FssUserBalanceTransaction";
import {green, red} from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import FssPointTransaction, {EScoreType} from "../model/FssPointTransaction";
import {i18n} from "../util/I18n";
import FssUser, {EStatus} from "../model/FssUser";
import FssUserService from "../service/FssUserService";
import FssClientData from "../data/FssClientData";
import FtPagedList from "../model/FtPagedList";
import FssUserAddress from "../model/FssUserAddress";

interface FssUserProfileDetailPageProp extends RouteComponentProps<{ id: string }> {
}

export class FssUserProfileDetailState {
    userProfile: FssUserProfile = new FssUserProfile();
    password: string = "";
    openUpdateDialog: boolean = false;
    updateType?: "BALANCE" | "SCORE";
    dialogTitle: string = "";
    dialogMessageText: string = "";
    amountType: string = "";
    amount: number = 0;
    dialogComment: string = "";
    balanceList?: Array<FssUserBalanceTransaction>;
    scoreList?: Array<FssPointTransaction>;
    userAddressList: Array<FssUserAddress> = [];
    showScoreTransactionDialog: boolean = false;
    showBalanceTransactionDialog: boolean = false;
}


export default class FssUserProfileDetailPage extends React.Component<FssUserProfileDetailPageProp, FssUserProfileDetailState> {

    constructor(props: any) {
        super(props);
        this.state = {
            userProfile: new FssUserProfile(),
            password: "",
            openUpdateDialog: false,
            dialogTitle: "",
            dialogMessageText: "",
            amountType: "",
            amount: 0,
            dialogComment: "",
            showBalanceTransactionDialog: false,
            showScoreTransactionDialog: false,
            userAddressList: []
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.updateBalanceOrScoreDialog = this.updateBalanceOrScoreDialog.bind(this);
        this.updateUserProfile = this.updateUserProfile.bind(this);
        this.onClickBalanceTransaction = this.onClickBalanceTransaction.bind(this);
        this.onClickScoreTransaction = this.onClickScoreTransaction.bind(this);
        this.onClickUpdateStatus = this.onClickUpdateStatus.bind(this);
        this.loadUserAddress = this.loadUserAddress.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.id != null && +this.props.match.params.id.length > 1) {
            FssUserService.getUserById(this.props.match.params.id, (result: FssUserProfile) => {
                this.setState({userProfile: result});
            });
            this.loadUserAddress();
        }
    }

    loadUserAddress() {
        const params = new Map<string, any>();
        params.set("userId", this.props.match.params.id)
        FssUserService.getUserAddressList("", params, null,
            null, 0, 999, (result: FtPagedList<FssUserAddress>) => {
                FtToast.closeToast();
                this.setState({userAddressList: result.content});
            });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        if (event.target.id.startsWith("user."))
            FtUtil.setProperty(this.state.userProfile.user, event.target.id.substring("user.".length), event.target.value);
        else
            FtUtil.setProperty(this.state.userProfile, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        const message: string = '';
        if (message == "") {
            FtToast.showToastLoading(i18n("正在保存"));
            FssUserService.saveUserWithUserProfile(this.state.userProfile,
                (data: FssUserProfile) => {
                    this.setState({userProfile: FtUtil.mergeObject(this.state.userProfile, data)});
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("保存成功"));
                    this.onCancelClick();
                });
        } else {
            FtSnackbar.showText(message);
        }
    }

    onClickResetPassword() {
        const message: string = '';
        if (message == "") {
            if (this.state.userProfile.user.id) {
                FtToast.showToastLoading(i18n("设置新密码"));
                FssUserService.resetUserPassword(this.state.userProfile.user.id, this.state.password,
                    () => {
                        FtToast.closeToast();
                        FtSnackbar.showText(i18n("密码设置成功"));
                    });
            }
        } else {
            FtSnackbar.showText(message);
        }
    }

    render() {
        let isEdit: boolean = false;
        if (this.state.userProfile.id != null && this.state.userProfile.id.length > 0)
            isEdit = true;
        let userStatusText: string = "正常";
        let statusButtonText: string = "禁用";
        if (this.state.userProfile.user.userStatus != null && this.state.userProfile.user.userStatus == EStatus.DISABLED) {
            userStatusText = "禁用";
            statusButtonText = "恢复";
        }
        return <div style={{backgroundColor: grey[100]}}>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}>{i18n("返回")}</Button>
                <Button color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
            </div>}>
            </FtToolbar>
            <FtPanel panelHeader={i18n("用户信息详情")}>
                <FtGrid>
                    <FtRow cellWidthS={"1,1"}>
                        {isEdit && <JtTextField id="createDatetimeText" label={i18n("注册时间")}
                                                value={FssClientData.formatDatetime(this.state.userProfile.user.createDatetime)}
                                                style={{width: "100%"}} disabled={true}/>}
                        {isEdit &&
                        <JtTextField id="balance" label={i18n("余额")} value={this.state.userProfile.balance}
                                     style={{width: "100%"}} disabled={true}
                                     endAdornment={<div>
                                         <Tooltip title={i18n("查看余额记录")}>
                                             <IconButton onClick={this.onClickBalanceTransaction}>
                                                 <ListAlt/>
                                             </IconButton>
                                         </Tooltip>
                                         <Button color="secondary" onClick={() => {
                                             this.setState({
                                                 updateType: "BALANCE",
                                                 openUpdateDialog: true,
                                                 dialogTitle: i18n("当前余额") + ":" + this.state.userProfile.balance,
                                                 amountType: "",
                                                 amount: 0,
                                                 dialogComment: ""
                                             });
                                         }}>{i18n("调整余额")}</Button>
                                     </div>}/>}
                        {isEdit &&
                        <JtTextField id="score" label={i18n("积分")} value={this.state.userProfile.point}
                                     style={{width: "100%"}} disabled={true}
                                     endAdornment={<div>
                                         <Tooltip title={i18n("查看积分记录")}>
                                             <IconButton onClick={this.onClickScoreTransaction}>
                                                 <ListAlt/>
                                             </IconButton>
                                         </Tooltip>
                                         <Button color="secondary" onClick={() => {
                                             this.setState({
                                                 updateType: "SCORE",
                                                 openUpdateDialog: true,
                                                 dialogTitle: i18n("当前积分") + ":" + this.state.userProfile.point,
                                                 amountType: "",
                                                 amount: 0,
                                                 dialogComment: ""
                                             });
                                         }}>{i18n("调整积分")}</Button>
                                     </div>
                                     }/>}
                    </FtRow>
                    <FtRow cellWidthS={"1,1,1"}>
                        <JtTextField id="inviterId" label={i18n("邀请人ID")}
                                     value={this.state.userProfile.inviterId}
                                     style={{width: "100%"}} disabled={isEdit} onChange={this.onChange}/>
                        <JtTextField id="inviterCode" label={i18n("邀请码")}
                                     value={this.state.userProfile.inviterCode}
                                     style={{width: "100%"}} disabled={isEdit} onChange={this.onChange}/>
                        <JtTextField id="referral" label={i18n("注册来源")} value={this.state.userProfile.referral}
                                     style={{width: "100%"}} disabled={isEdit} onChange={this.onChange}/>
                        <JtTextField id="user.nickName" label={i18n("昵称")}
                                     value={this.state.userProfile.user.nickName || ""}
                                     onChange={this.onChange} style={{width: "100%"}}/>

                        <JtTextField id="realName" label={i18n("姓名")} value={this.state.userProfile.realName}
                                     onChange={this.onChange}
                                     style={{width: "100%"}}/>
                        <JtTextField id="userCode" label={i18n("用户编码")}
                                     value={this.state.userProfile.user.userCode}
                                     onChange={this.onChange} disabled={true}
                                     style={{width: "100%"}}/>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"}>
                        <JtTextField id="user.mobile" label={i18n("手机号")}
                                     value={this.state.userProfile.user.mobile}
                                     onChange={this.onChange}
                                     style={{width: "100%"}}/>

                        <JtTextField id="user.email" label={i18n("Email")}
                                     value={this.state.userProfile.user.email}
                                     onChange={this.onChange}
                                     style={{width: "100%"}}/>
                        {isEdit &&
                        <JtTextField id="user.userStatus" label={i18n("用户状态")} value={i18n(userStatusText)}
                                     style={{width: "100%"}} disabled={true}
                                     endAdornment={<div>
                                         <Button color="secondary"
                                                 onClick={this.onClickUpdateStatus}>{i18n(statusButtonText)}</Button>
                                     </div>
                                     }/>}
                        <FormControl style={{width: "100%"}}>
                            <InputLabel>{i18n("用户级别")}</InputLabel>
                            <Select
                                id="user.userLevel" value={this.state.userProfile.user.userLevel}
                                onChange={(event, child) => {
                                    this.state.userProfile.user.userLevel = event.target.value as number;
                                    this.forceUpdate();
                                }}>
                                <MenuItem value={0}>{i18n("无级别")}</MenuItem>
                                <MenuItem value={1}>{i18n("一级别")}</MenuItem>
                                <MenuItem value={2}>{i18n("二级别")}</MenuItem>
                                <MenuItem value={3}>{i18n("三级别")}</MenuItem>
                            </Select>
                        </FormControl>
                        <JtTextField id="comment" label={i18n("备注")} rows={3} multiline={true}
                                     value={this.state.userProfile.comment}
                                     onChange={this.onChange} style={{width: "100%"}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n("用户地址")} marginVertical={12}>
                <FtGrid>
                    {this.state.userAddressList != null && this.state.userAddressList.length > 0 &&
                    this.state.userAddressList.map((userAddress: FssUserAddress, index: number) => {
                        return <Typography>
                            {((index + 1) + ". ") + userAddress.address}
                        </Typography>
                    })}
                </FtGrid>
            </FtPanel>
            {isEdit &&
            <FtPanel panelHeader={i18n("修改密码")} marginVertical={12}>
                <FtGrid>
                    <JtTextField id="password" label={i18n("新密码")} placeholder={i18n("请输入新密码")}
                                 type={"password"}
                                 value={this.state.password}
                                 onChange={event => {
                                     this.setState({password: event.target.value});
                                 }}
                                 style={{width: "100%"}} endAdornment={
                        <Button color="primary" onClick={this.onClickResetPassword}>{i18n("保存密码")}</Button>
                    }/>
                </FtGrid>
            </FtPanel>}
            {this.updateBalanceOrScoreDialog()}
            {this.getScoreTransactionDialog()}
            {this.getBalanceTransactionDialog()}
        </div>
    }

    onClickBalanceTransaction() {
        if (this.state.userProfile.user.id) {
            FtToast.showToastLoading();
            FssUserService.getBalanceListByUserId(this.state.userProfile.user.id, (balanceList: Array<FssUserBalanceTransaction>) => {
                FtToast.closeToast();
                this.setState({balanceList: balanceList, showBalanceTransactionDialog: true});
            });
        }
    }

    onClickScoreTransaction() {
        if (this.state.userProfile.user.id) {
            FtToast.showToastLoading();
            FssUserService.getPointListByUserId(this.state.userProfile.user.id, (scoreList: Array<FssPointTransaction>) => {
                FtToast.closeToast();
                this.setState({scoreList: scoreList, showScoreTransactionDialog: true});
            });
        }
    }

    onClickUpdateStatus() {
        if (this.state.userProfile.id) {
            FtToast.showToastLoading();
            FssUserService.updateUserStatus(this.state.userProfile.id, (user: FssUser) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("修改成功！"));
                this.state.userProfile.user.userStatus = user.userStatus;
                this.forceUpdate();
            });
        }
    }

    /**
     * 更新金额或这积分
     */
    updateBalanceOrScoreDialog() {
        let updateType = (this.state.updateType && this.state.updateType == "BALANCE") ? i18n("修改余额") : i18n("修改积分");
        let inputType: "currency" | "integer" = (this.state.updateType && this.state.updateType == "BALANCE") ? "currency" : "integer";
        return <Dialog open={this.state.openUpdateDialog} PaperProps={{style: {minWidth: 300, textAlign: "center"}}}
                       onClose={(e) => {
                           this.setState({openUpdateDialog: false});
                       }}>
            <DialogTitle>
                {updateType}
            </DialogTitle>
            <DialogContent>
                <div style={{minWidth: 200}}>
                    <Typography variant="subtitle1">
                        {this.state.dialogTitle}
                    </Typography>
                    <FormControl>
                        <RadioGroup row onChange={(event, value) => {
                            this.setState({dialogMessageText: "", amountType: value});
                        }}>
                            <FormControlLabel value={"ADD"} control={<Radio color="primary"/>} label={i18n("增加")}/>
                            <FormControlLabel value={"SUBTRACT"} control={<Radio color="primary"/>} label={i18n("减少")}/>
                        </RadioGroup>
                    </FormControl>
                    <div style={{width: "100%", marginTop: 10, minWidth: 200}}/>
                    <FtTextFieldNumber label={i18n("数量")} type={inputType} size={"medium"}
                                       inputStyle={{textAlign: "center"}}
                                       onChangeValue={(value) => {
                                           if (value) {
                                               this.setState({dialogMessageText: "", amount: value});
                                           }
                                       }}/>
                    <div style={{width: "100%", height: 10}}/>
                    <JtTextField label={i18n("描述")} type={"text"} size={"medium"} multiline rows={"3"}
                                 variant={"outlined"}
                                 style={{width: "75%"}}
                                 onChange={(event) => {
                                     this.setState({dialogMessageText: "", dialogComment: event.target.value});
                                 }}/>
                    <Typography variant="body1" color={"primary"} style={{height: 30}}>
                        {this.state.dialogMessageText}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({openUpdateDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    if (this.state.amountType == "" || this.state.amountType.length == 0) {
                        this.setState({dialogMessageText: i18n("请选择增加或者减少")});
                        return;
                    } else if (this.state.amount == 0) {
                        this.setState({dialogMessageText: i18n("请输入数量")});
                        return;
                    } else if (this.state.dialogComment == "" || this.state.dialogComment.length == 0) {
                        this.setState({dialogMessageText: i18n("请输入描述内容")});
                    } else {
                        this.setState({openUpdateDialog: false});
                        this.updateUserProfile();
                    }
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    updateUserProfile() {
        if (this.state.userProfile.id) {
            FtToast.showToastLoading();
            if (this.state.updateType && this.state.updateType == "BALANCE" && this.state.userProfile.user.id) {
                FssUserService.updateUserBalance(this.state.userProfile.user.id,
                    this.state.amountType, this.state.amount, this.state.dialogComment, (FssUserProfile: FssUserProfile) => {
                        FtToast.closeToast();
                        this.setState({openUpdateDialog: false, dialogMessageText: ""}, () => {
                            this.load();
                        });
                    });
            }
            if (this.state.updateType && this.state.updateType == "SCORE" && this.state.userProfile.user.id) {
                FssUserService.updateUserPoint(this.state.userProfile.user.id,
                    this.state.amountType, this.state.amount, this.state.dialogComment, (FssUserProfile: FssUserProfile) => {
                        FtToast.closeToast();
                        this.setState({openUpdateDialog: false, dialogMessageText: ""}, () => {
                            this.load();
                        });
                    });
            }
        }
    }

    getBalanceTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showBalanceTransactionDialog} onClose={(e) => {
            this.setState({showBalanceTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("余额记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("时间")}</TableCell>
                            <TableCell>{i18n("描述")}</TableCell>
                            <TableCell align={"right"}>{i18n("金额")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.balanceList && this.state.balanceList.map((balanceTransaction: FssUserBalanceTransaction, index: number) => {
                        return <TableRow>
                            <TableCell>{balanceTransaction.createDatetimeText}</TableCell>
                            <TableCell>{balanceTransaction.comment}</TableCell>
                            <TableCell align={"right"}>
                                {balanceTransaction.type == EBalanceType.OUT &&
                                <Typography
                                    style={{color: green[500]}}>-{balanceTransaction.balanceAmount && FtUtil.toCurrencyText(balanceTransaction.balanceAmount).replace("-", "")}</Typography>}
                                {balanceTransaction.type == EBalanceType.IN &&
                                <Typography
                                    style={{color: red[500]}}>+{balanceTransaction.balanceAmount && FtUtil.toCurrencyText(balanceTransaction.balanceAmount)}</Typography>}
                            </TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showBalanceTransactionDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getScoreTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showScoreTransactionDialog} onClose={(e) => {
            this.setState({showScoreTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("积分记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("时间")}</TableCell>
                            <TableCell>{i18n("描述")}</TableCell>
                            <TableCell align={"right"}>{i18n("积分")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.scoreList && this.state.scoreList.map((pointTransaction: FssPointTransaction) => {
                        return <TableRow>
                            <TableCell>{pointTransaction.createDatetimeText}</TableCell>
                            <TableCell>{pointTransaction.comment}</TableCell>
                            <TableCell
                                align={"right"}>
                                {pointTransaction.type == EScoreType.OUT &&
                                <Typography style={{color: green[500]}}>{pointTransaction.pointAmount}</Typography>}
                                {pointTransaction.type == EScoreType.IN &&
                                <Typography style={{color: red[500]}}>+{pointTransaction.pointAmount}</Typography>}
                            </TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showScoreTransactionDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }
}
