import FssHttpService from "./FssHttpService";
import {FssWebSession} from "../model/FssWebSession";
import FssUserProfile from "../model/FssUserProfile";
import FtPagedList from "../model/FtPagedList";
import FssUser from "../model/FssUser";
import FssUserBalanceTransaction from "../model/FssUserBalanceTransaction";
import FssPointTransaction from "../model/FssPointTransaction";
import FssUserAddress from "../model/FssUserAddress";

export default class FssUserService {

    public static loginBySuperAdmin(loginName: string, password: string, successFunction: (webSession: FssWebSession) => void) {
        let params = {loginName: loginName, password: password};
        FssHttpService.postFormData("/api/user/loginBySuperAdmin", params, successFunction);
    }

    static getUserList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                       pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssUserProfile>) => void) {
        FssHttpService.search("/api/user/getUserProfileWithUserList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static exportUserCsv(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                         pageNo: number, pageSize: number, successFunction: (fileUrl: string) => void): void {
        FssHttpService.search("/api/user/exportUserCsv", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static getUserById(id: string, successFunction: (data: FssUserProfile) => void): void {
        FssHttpService.get("/api/user/getUserById", {id: id}, successFunction);
    }

    static saveUserWithUserProfile(data: FssUserProfile, successFunction: (data: FssUserProfile) => void): void {
        FssHttpService.postJson("/api/user/saveJtUserProfileWithUser", data, successFunction);
    }

    static resetUserPassword(userId: string, password: string, successFunction: (data: FssUser) => void): void {
        let params = {uid: userId, password: password};
        FssHttpService.postFormData("/api/user/resetJtUserPassword", params, successFunction);
    }

    static getBalanceListByUserId(userId: string, successFunction: (balanceList: Array<FssUserBalanceTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getBalanceListByUserId", {id: userId}, successFunction);
    }

    static getPointListByUserId(userId: string, successFunction: (balanceList: Array<FssPointTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getScoreListByUserId", {id: userId}, successFunction);
    }

    static updateUserStatus(userId: string, successFunction: (jtUser: FssUser) => void) {
        FssHttpService.postFormData("/api/user/updateUserStatus", {userId: userId}, successFunction);
    }

    static updateUserBalance(userId: string, amountType: string, amount: number, comment: string, successFunction: (jtUserProfile: FssUserProfile) => void): void {
        let params = {uid: userId, amountType: amountType, amount: amount, comment: comment};
        FssHttpService.postFormData("/api/user/updateUserBalance", params, successFunction);
    }

    static updateUserPoint(userId: string, amountType: string, amount: number, comment: string, successFunction: (jtUserProfile: FssUserProfile) => void): void {
        let params = {uid: userId, amountType: amountType, amount: amount, comment: comment};
        FssHttpService.postFormData("/api/user/updateUserPoint", params, successFunction);
    }

    static getUserAddressList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                              pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssUserAddress>) => void) {
        FssHttpService.search("/api/user/getUserAddressList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }

    static checkImportUserFile(fileUrl: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/user/checkImportUserFile", {fileUrl: fileUrl}, successFunction, FssHttpService.handleError);
    }

    static importUserFile(fileUrl: string, resetPassword: boolean, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/user/importUserFile", {
            fileUrl: fileUrl,
            resetPassword: resetPassword
        }, successFunction, FssHttpService.handleError);
    }
}
