import FssStoreCoupon from "./FssStoreCoupon";
import FssUser from "../model/FssUser";

export enum ECouponChannel {
    STORE = "STORE",
    SELLER = "SELLER",
}

export default class FssUserCoupon {
    id: string = "";
    storeId: string = "";
    sellerId: string = "";
    receiveUserId: string = "";
    receiveUserName: string = "";
    receiveUser: FssUser = new FssUser();
    couponId: string = "";
    orderId: string = "";
    status: string = "";
    statusText: string = "";
    createDatetimeText: string = "";
    usedDatetime: string = "";
    usedDatetimeText?: string = "";
    storeCoupon: FssStoreCoupon = new FssStoreCoupon();
    // coupon?: FssStoreCoupon;
    startDatetime: string = "";
    startDatetimeText: string = "";
    expireDatetime: string = "";
    expireDatetimeText: string = "";
    couponTags: Array<string> = [];
    couponChannel: ECouponChannel = ECouponChannel.STORE;
}
