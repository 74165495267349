import * as React from "react";
import {CSSProperties} from "react";
import FtUtil from "../util/FtUtil";
import {BorderColorProperty, BorderWidthProperty} from "csstype";

export interface FtGridProp extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    elevation?: number;
    paddingHorizontal?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    paddingVertical?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    marginHorizontal?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    marginVertical?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    borderWidth?:BorderWidthProperty<string|0>;
    borderColor?:BorderColorProperty;

}

export default class FtGrid extends React.Component<FtGridProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles: CSSProperties = {
            boxSizing: "border-box",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            borderWidth:this.props.borderWidth,
            borderColor:this.props.borderColor
        };
        if(this.props.marginHorizontal){
            styles.marginLeft = this.props.marginHorizontal;
            styles.marginRight = this.props.marginHorizontal;
        }
        if(this.props.marginVertical){
            styles.marginTop = this.props.marginVertical;
            styles.marginBottom = this.props.marginVertical;
        }
        if(this.props.paddingHorizontal!=null){
            styles.paddingLeft = this.props.paddingHorizontal;
            styles.paddingRight = this.props.paddingHorizontal;
        }
        if(this.props.paddingVertical!=null){
            styles.paddingTop = this.props.paddingVertical;
            styles.paddingBottom = this.props.paddingVertical;
        }
        styles = FtUtil.mergeObject(styles, this.props.style);
        return <div style={styles} onClick={this.props.onClick}  id={this.props.id} className={this.props.className}>
            {this.props.children}
        </div>;
    }
}