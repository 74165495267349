import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {Button, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../../ui/FtDataTable";
import FssUserBalanceRule, {EStatus} from "../../model/FssUserBalanceRule";
import FtToast from "../../ui/FtToast";
import FtPagedList from "../../model/FtPagedList";
import {i18n} from "../../util/I18n";
import FtUtil from "../../util/FtUtil";
import FtPage from "../../ui/FtPage";
import FtMessageDialog from "../../ui/FtMessageDialog";
import FtSnackbar from "../../ui/FtSnackbar";
import FtApiResponse from "../../model/FtApiResponse";
import FssUserBalanceService from "../../service/FssUserBalanceService";
import {EBalanceRechargeDefault} from "../../model/FssPaymentAccount";

class FssBalanceRuleListPageState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    userBalanceRule: FssUserBalanceRule = new FssUserBalanceRule();
    openUpdateDialog: boolean = false;
}

class JtTableAdapter extends FtDataTableAdapter<FssUserBalanceRule> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssUserBalanceService.getUserBalanceRuleList("", this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssUserBalanceRule>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssBalanceRuleListPage extends React.Component<any, FssBalanceRuleListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("rechargeBalance", i18n("充值金额"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("giveBalance", i18n("赠送金额"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column3);
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("id", i18n("编辑"));
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            return <Button onClick={() => {
                this.onRowClick(rowData, rowIndex);
            }} variant={"outlined"} color={"primary"}>编辑</Button>
        };
        columnProps.push(column0);
        let tableAdapter: JtTableAdapter = new JtTableAdapter();
        tableAdapter.filterColumns.set("storeId", EBalanceRechargeDefault.BALANCE_RECHARGE_STORE_ID);
        tableAdapter.filterColumns.set("sellerId", EBalanceRechargeDefault.BALANCE_RECHARGE_SELLER_ID);
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openUpdateDialog: false,
            userBalanceRule: new FssUserBalanceRule()
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onNewClick = this.onNewClick.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.userBalanceRule, event.target.id, event.target.value);
        this.forceUpdate();
    }

    render() {
        return <FtPage onCancelClick={() => {
            this.props.history.goBack()
        }}
                       toolbarLeftNode={<Typography variant="h6">{i18n("余额充值规则列表")}</Typography>}>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}>
            </FtDataTable>
            {this.getUpdateDialog()}
        </FtPage>;
    }

    onRowClick(rowData: object, rowIndex: number) {
        this.setState({openUpdateDialog: true, userBalanceRule: rowData as FssUserBalanceRule});
    }

    onNewClick() {
        this.setState({openUpdateDialog: true, userBalanceRule: new FssUserBalanceRule()});
    }

    onClickSave() {
        FtMessageDialog.dialog.openDialog(i18n("确认保存吗？"), () => {
            let userBalanceRule = this.state.userBalanceRule;
            if (userBalanceRule.storeId === null || userBalanceRule.storeId.trim().length === 0) {
                userBalanceRule.storeId = EBalanceRechargeDefault.BALANCE_RECHARGE_STORE_ID;
            }
            if (userBalanceRule.sellerId === null || userBalanceRule.sellerId.trim().length === 0) {
                userBalanceRule.sellerId = EBalanceRechargeDefault.BALANCE_RECHARGE_SELLER_ID;
            }
            FtToast.showToastLoading(i18n("正在保存"));
            FssUserBalanceService.saveUserBalanceRule(userBalanceRule, (data: FssUserBalanceRule) => {
                this.setState({userBalanceRule: FtUtil.mergeObject(userBalanceRule, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.setState({openUpdateDialog: false});
                this.state.tableAdapter.loadData();
            });
        }, () => {
            this.setState({openUpdateDialog: false});
        }, i18n("保存"), i18n("放弃修改"));


    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openUpdateDialog} onClose={(e) => {
            this.setState({openUpdateDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("设置充值规则")}</DialogTitle>
            <DialogContent>
                <div>
                    <TextField id="rechargeBalance" label={i18n("充值金额")} type="number"
                               value={this.state.userBalanceRule.rechargeBalance}
                               onChange={this.onChange} style={{width: "50%"}}/>
                </div>
                <div>
                    <TextField id="giveBalance" label={i18n("赠送金额")} type="number"
                               value={this.state.userBalanceRule.giveBalance}
                               onChange={this.onChange} style={{width: "50%"}}/>
                </div>
                <FormControl style={{width: "50%"}}>
                    <InputLabel id="demo-simple-select-label"> {i18n("状态")} </InputLabel>
                    <Select id="status" labelId="demo-simple-select-label" value={this.state.userBalanceRule.status}
                            onChange={(event, child) => {
                                this.state.userBalanceRule.status = event.target.value as EStatus;
                                this.forceUpdate();
                            }}>
                        <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                        <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                    </Select>
                </FormControl>
                <div style={{display: "flex", flexDirection: "column", marginTop: 50}}>
                    <Typography variant="subtitle2">{i18n("余额充值规则设置说明")}</Typography>
                    <Typography variant="caption">{i18n("1.余额充值不可退还")}</Typography>
                    <Typography variant="caption">{i18n("2.赠送的金额在订单支付中等同于充值货币")}</Typography>
                    <Typography variant="caption">{i18n("3.余额使用原则上不受限制")}</Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openUpdateDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="contained" color="primary" onClick={this.onClickSave}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Button onClick={this.onNewClick}>
                <Add/>{i18n("新增")}
            </Button>
        </div>;
    }

    getToolbarWithSelect(): ReactNode {
        return <div><Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.onClickDelete}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        </div>;
    }

    onClickDelete() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除") + this.state.tableAdapter.selectedRow.length + i18n("个记录吗？"), () => {
                    FtMessageDialog.dialog.closeDialog();
                    const idList: Array<string> = [];
                    this.state.tableAdapter.selectedRow.forEach((rowData: any) => {
                        idList.push((rowData as FssUserBalanceRule).id);
                    });
                    FssUserBalanceService.deleteUserBalanceRule(idList, (data: FtApiResponse) => {
                        if (data && data.message) {
                            FtSnackbar.showText(data.message);
                        }
                        this.state.tableAdapter.selectedRow = new Array<any>();
                        this.state.tableAdapter.loadData();
                    });
                },
                () => {
                    FtMessageDialog.dialog.closeDialog();
                }
            )
        }
    }
}