import * as React from "react";
import {Avatar, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AppClientInfo from "../data/AppClientInfo";
import FtRow from "../ui/FtRow";
import {ExitToApp} from "@material-ui/icons";
import FtDivFlex from "../ui/FtDivFlex";
import {grey} from "@material-ui/core/colors";
import {i18n} from "../util/I18n";
import FtToast from "../ui/FtToast";
import FtAdminMenu from "../model/FtAdminMenu";
import FtAdminFrame from "../ui/FtAdminFrame";
import {Route, RouteComponentProps} from "react-router-dom";
import FssClientData from "../data/FssClientData";
import FssAdminMenuService from "../service/FssAdminMenuService";
import FssUserProfileListPage from "../user/FssUserProfileListPage";
import FssUserProfileDetailPage from "../user/FssUserProfileDetailPage";
import FssUserAddressListPage from "../userAddress/FssUserAddressListPage";
import FssSettingMainPage from "../setting/FssSettingMainPage";
import FssStoreListPage from "../store/FssStoreListPage";

import FssSellerListPage from "../seller/FssSellerListPage";
import FssLoginWithRegisterSettingPage from "../setting/FssLoginWithRegisterSettingPage";
import FssSellerDetailPage from "../seller/FssSellerDetailPage";
import SynDataPage from "../setting/SynDataPage";
import FssMyUserDetailPage from "../user/FssMyUserDetailPage";
import FssPaymentAccountListPage from "../setting/paymentAccount/FssPaymentAccountListPage";
import FssPaymentAccountDetailPage from "../setting/paymentAccount/FssPaymentAccountDetailPage";
import FssBalanceRuleListPage from "../setting/balanceRule/FssBalanceRuleListPage";
import FssShareDomainNamePage from "../setting/FssShareDomainNamePage";
import FssGooglePage from "../setting/google/FssGooglePage";
import FssLoginDialog from "../user/FssLoginDialog";
import FtMessageDialog from "../ui/FtMessageDialog";
import FssStoreDetailTabsPage from "../store/FssStoreDetailTabsPage";
import FssMarketingMainPage from "../marketing/FssMarketingMainPage";
import FssCouponSettingTabsPage from "../marketing/FssCouponSettingTabsPage";
import FssStoreCouponDetailPage from "../marketing/FssCouponDetailPage";
import FssCountryInfoListPage from "../region/FssCountryInfoListPage";
import FssCountryInfoDetailPage from "../region/FssCountryInfoDetailPage";

export const drawerWidth = 160;
export const topBarHeight = 40;

interface FssSellerAdminMainPageProp extends RouteComponentProps {

}

class FssSellerAdminMainPageState {
    openDrawer: boolean = true;
    mobileViewMode: boolean = false;
    menuList: Array<FtAdminMenu> = [];
    unreadMessageCount: number = 9;
    openMessageBoxDialog: boolean = false;
}

export default class FssWebsiteAdminMainPage extends React.Component<FssSellerAdminMainPageProp, FssSellerAdminMainPageState> {
    constructor(props: any) {
        super(props);
        let openDrawer: boolean = true;
        let mobileViewMode: boolean = false;
        //根据屏幕大小默认是否显示左侧栏
        if (AppClientInfo.clientInfo.viewMode && (AppClientInfo.clientInfo.viewMode === "mobile" || AppClientInfo.clientInfo.viewMode === "pad")) {
            openDrawer = false;
            mobileViewMode = true;
        }
        this.state = {
            openDrawer: openDrawer,
            mobileViewMode: mobileViewMode,
            menuList: [],
            unreadMessageCount: 99,
            openMessageBoxDialog: false
        };
        this.onClickLeftDrawerMenu = this.onClickLeftDrawerMenu.bind(this);
        this.onClickUserAvatar = this.onClickUserAvatar.bind(this);
    }

    componentDidMount(): void {
        if (FssClientData.isLogin() && FssClientData.getData().webSession.isAppWebsiteAdmin) {
            FtToast.showToastLoading();
            FssAdminMenuService.getMyMenuList(response => {
                FtToast.closeToast();
                this.setState({menuList: response});
            });
        } else {
            FssLoginDialog.loginDialog.show(data => {
                FtToast.showToastLoading("登录成功，重新加载页面");
                FssClientData.updateWebSession(data);
                window.location.reload();
            });
        }
    }

    render() {
        return <FtAdminFrame topBar={this.getTopBar()} leftDrawerMenu={this.state.menuList}
                             topBarHeight={topBarHeight}
                             leftDrawerOpenDefault={!this.state.mobileViewMode}
                             onOpenLeftDrawer={(isOpen: boolean) => {
                                 this.setState({openDrawer: isOpen})
                             }} leftDrawerWidth={drawerWidth}
                             onClickLeftDrawerMenu={this.onClickLeftDrawerMenu}>
            <main style={{
                paddingLeft: (this.state.openDrawer ? drawerWidth : 0),
                paddingTop: topBarHeight,
                backgroundColor: grey[200]
            }}>
                <div style={{padding: 12, height: document.documentElement.clientHeight - 24 - topBarHeight}}>
                    <Route path="/user/userProfileList" component={FssUserProfileListPage} exact={false}/>
                    <Route path="/user/userProfileDetail/:id" component={FssUserProfileDetailPage} exact={false}/>
                    <Route path="/user/userAddressList" component={FssUserAddressListPage} exact={false}/>
                    <Route path="/settings" component={FssSettingMainPage} exact={false}/>
                    <Route path="/store/storeList" component={FssStoreListPage} exact={false}/>
                    <Route path="/store/storeDetail/:id" component={FssStoreDetailTabsPage} exact={false}/>
                    <Route path="/seller/sellerList" component={FssSellerListPage} exact={false}/>
                    <Route path="/seller/sellerDetail/:id" component={FssSellerDetailPage} exact={false}/>
                    <Route path="/countryInfoList" component={FssCountryInfoListPage} exact={false}/>
                    <Route path="/countryInfoDetail/:id?" component={FssCountryInfoDetailPage} exact={false}/>
                    <Route path="/domainLoginSetting" component={FssLoginWithRegisterSettingPage} exact={false}/>
                    <Route path="/synData" component={SynDataPage} exact={false}/>
                    <Route path="/myUserDetail" component={FssMyUserDetailPage} exact={false}/>
                    <Route path="/paymentAccountList" component={FssPaymentAccountListPage} exact={false}/>
                    <Route path="/paymentAccountDetail/:id?" component={FssPaymentAccountDetailPage} exact={false}/>
                    <Route path="/balanceRuleList" component={FssBalanceRuleListPage} exact={false}/>
                    <Route path="/shareDomainNamePage" component={FssShareDomainNamePage} exact={false}/>
                    <Route path="/googleSetting" component={FssGooglePage} exact={false}/>
                    <Route path="/marketSettings" component={FssMarketingMainPage} exact={false}/>
                    <Route path="/couponSetting" component={FssCouponSettingTabsPage} exact={false}/>
                    <Route path="/couponDetailPage/:storeId/:couponId" component={FssStoreCouponDetailPage}
                           exact={false}/>
                </div>
            </main>
        </FtAdminFrame>;
    }

    getTopBar() {
        return <FtDivFlex justifyContent={"space-between"}
                          style={{paddingLeft: 8, flexGrow: 100}}>
            {!this.state.mobileViewMode && <FtRow cellVerticalSpace={0}>
                <Typography variant={"h6"}> Yolebuy Site Admin </Typography>
            </FtRow>}
            <FtRow cellVerticalSpace={0}>
                {!this.state.mobileViewMode && <Typography>{i18n("欢迎您")}</Typography>}
                <Avatar style={{width: 32, height: 32}} alt="头像"
                        onClick={this.onClickUserAvatar}
                        src={FssClientData.getData().webSession.avatarUrl}/>
                <Button size={"small"} color={"inherit"} onClick={this.onClickLogout}><ExitToApp/></Button>
            </FtRow>
        </FtDivFlex>;
    }

    onClickLogout() {
        FtMessageDialog.dialog.openDialog(i18n("确定要退出吗？"), () => {
            FssClientData.clearWebSession();
            let number = window.location.href.indexOf("#");
            if (number > -1) {
                window.location.href = window.location.href.substr(0, number + 1);
            }
            window.location.reload();
        });
    }

    onClickUserAvatar() {
        this.props.history.push("/myUserDetail");
    }

    private onClickLeftDrawerMenu(menuId: string) {
        if (menuId === "userProfileList") {
            this.props.history.push("/user/userProfileList");
        } else if (menuId === "userAddressList") {
            this.props.history.push("/user/userAddressList");
        } else if (menuId === "Settings") {
            this.props.history.push("/settings");
        } else if (menuId === "StoreList") {
            this.props.history.push("/store/storeList");
        } else if (menuId === "publisherList") {
            this.props.history.push("/publisher/list");
        } else if (menuId === "SellerList") {
            this.props.history.push("/seller/sellerList");
        } else if (menuId === "report") {
            this.props.history.push("/report");
        } else if (menuId === "Marketing") {
            this.props.history.push("/marketSettings");
        }
    }

}
