import FssHttpService from "./FssHttpService";
import FssSeller from "../model/FssSeller";
import FtPagedList from "../model/FtPagedList";

export default class FssSellerService extends FssHttpService {

    static saveMySeller(seller: FssSeller, successFunction: (data: FssSeller) => void): void {
        this.postJson("/api/seller/saveMySeller", seller, successFunction, FssHttpService.handleError);
    }

    static getSellerById(sellerId: string, successFunction: (seller: FssSeller) => void) {
        FssHttpService.get("/api/seller/getSellerById", {sellerId: sellerId}, successFunction, FssHttpService.handleError);
    }

    static getSellerPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                              pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssSeller>) => void) {
        FssHttpService.search("/api/seller/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }

    static getSellerListByStoreId(storeId: string, success: (data: Array<FssSeller>) => void) {
        FssHttpService.get("/api/seller/getSellerListByStoreId", {storeId: storeId}, success);
    }

}