import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link, RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssSeller, {ESellerStatus, ESellerType} from "../model/FssSeller";
import Button from "@material-ui/core/Button";
import FtToast from "../ui/FtToast";
import FssSellerService from "../service/FssSellerService";
import {Add} from "@material-ui/icons";

export interface FssSellerListPageProp extends RouteComponentProps<{ storeId: string }> {
}

class FssSellerListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssSeller> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssSellerService.getSellerPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size, (result: FtPagedList<FssSeller>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssSellerListPage extends FtComponent<FssSellerListPageProp, FssSellerListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("shortName", i18n("店铺名称"));
        column0.renderCell = (rowData: object) => {
            const seller: FssSeller = rowData as FssSeller;
            return <Link to={"/seller/sellerDetail/" + seller.id}>{seller.shortName}</Link>
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("fssStore.storeName", i18n("所属分站"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("sellerStatus", i18n("等级"));
        column2.renderCell = (rowData: object) => {
            const seller: FssSeller = rowData as FssSeller;
            let result = "普通";
            if (seller && seller.sellerType && seller.sellerType == ESellerType.STANDARD) {
                result = "高级";
            }
            if (seller && seller.sellerType && seller.sellerType == ESellerType.PUBLISHER) {
                result = "团长";
            }
            return result;
        };
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("sellerStatus", i18n("状态"));
        column3.renderCell = (rowData: object) => {
            const seller: FssSeller = rowData as FssSeller;
            let result = "正常";
            if (seller && seller.sellerStatus && seller.sellerStatus == ESellerStatus.STOP) {
                result = "停用";
            }
            return result;
        };
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        columnProps.push(column4);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssSellerListPageState = FtState.pop("FssSellerListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
            if (this.props.match.params.storeId && this.props.match.params.storeId.trim().length > 0) {
                tableAdapter.filterColumns.set("storeId", this.props.match.params.storeId);
            }
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssSellerListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <FtDataTable title={i18n("店铺列表")} columnProps={this.state.columnProps}
                            adapter={this.state.tableAdapter} filterPanel={this.getFilterPanel()}
                            toolbarNoSelect={this.getToolbarNoSelect()}/>
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
    }

    getFilterPanel(): ReactNode {
        return <div style={{paddingLeft: 8}}>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 150}} onKeyDownEnter={this.onChangePageNo}/>
        </div>;
    }

    getToolbarNoSelect(): ReactNode {
        return <Link to={"/seller/sellerDetail/0"}>
            <Button>
                <Add/>{i18n("新增")}
            </Button>
        </Link>;
    }
}
