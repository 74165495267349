import * as React from "react";
import {i18n} from "../util/I18n";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import {
    CardMedia,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import FssStore from "../model/FssStore";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FssStoreCategoryService from "../service/FssStoreCategoryService";
import FssStoreCategory from "../model/FssStoreCategory";
import {ETwoStatus} from "../model/FtAbstractModel";
import FtRow from "../ui/FtRow";
import Button from "@material-ui/core/Button";
import FtToolbar from "../ui/FtToolbar";
import CloseIcon from "@material-ui/icons/Close";
import grey from "@material-ui/core/colors/grey";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import JtTextField from "../ui/JtTextField";
import FtUtil from "../util/FtUtil";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {DeleteOutline} from "@material-ui/icons";
import FtSnackbar from "../ui/FtSnackbar";
import FtMessageDialog from "../ui/FtMessageDialog";

interface FssStoreCategoryPageProp {
    store: FssStore;
}

export class FssStoreCategoryPageState {
    parentStoreCategory?: FssStoreCategory;
    childStoreCategory?: FssStoreCategory;
    paged: FtPagedList<FssStoreCategory> = new FtPagedList<FssStoreCategory>();
}

export default class FssStoreCategoryPage extends React.Component<FssStoreCategoryPageProp, FssStoreCategoryPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            paged: new FtPagedList<FssStoreCategory>(),
        };
        this.loadPaged = this.loadPaged.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount(): void {
        this.loadPaged();
    }

    loadPaged() {
        let filterColumns: Map<string, any> = new Map<string, any>();
        filterColumns.set("storeId", this.props.store.id);
        if (this.state.parentStoreCategory != null) {
            if (this.state.parentStoreCategory.id == null || this.state.parentStoreCategory.id.trim().length == 0) {
                this.setState({paged: new FtPagedList<FssStoreCategory>()});
                return;
            }
            filterColumns.set("storeCategoryId", this.state.parentStoreCategory.id);
        }
        FtToast.showToastLoading();
        FssStoreCategoryService.getPagedList(filterColumns, this.state.paged.number, this.state.paged.size, (result: FtPagedList<FssStoreCategory>) => {
            FtToast.closeToast();
            this.setState({paged: result});
        });
    }

    onAddClick(storeCategory: FssStoreCategory) {
        if (storeCategory.parentCategoryId != null && storeCategory.parentCategoryId.trim().length > 0) {
            this.setState({childStoreCategory: storeCategory});
        } else {
            this.setState({parentStoreCategory: storeCategory}, () => {
                this.loadPaged();
            });
        }
    }

    onDeleteClick(categoryId: string) {
        if (this.state.parentStoreCategory != null && this.state.parentStoreCategory.id != null && this.state.parentStoreCategory.id.trim().length > 0) {
            // 如果在详情里面，删除的是 子分类
            FtMessageDialog.dialog.openDialog(i18n("确定删除吗？"), () => {
                FtToast.showToastLoading();
                FssStoreCategoryService.delete(categoryId, (data: string) => {
                    FtToast.closeToast();
                    this.loadPaged();
                    FtSnackbar.showText(data, 10000);
                });
            });
        } else {
            FtMessageDialog.dialog.openDialog(i18n("删除父级分类同时会删除子级分类，确定删除吗？"), () => {
                FtToast.showToastLoading();
                FssStoreCategoryService.delete(categoryId, (data: string) => {
                    FtToast.closeToast();
                    this.loadPaged();
                    FtSnackbar.showText(data, 10000);
                });
            });
        }
    }

    onSaveClick(storeCategory: FssStoreCategory) {
        FtToast.showToastLoading();
        FssStoreCategoryService.save(storeCategory, (data: FssStoreCategory) => {
            FtToast.closeToast();
            if (data.parentCategoryId != null && data.parentCategoryId.trim().length > 0) {
                this.setState({childStoreCategory: undefined}, () => {
                    this.loadPaged();
                });
            } else {
                this.setState({parentStoreCategory: data});
            }
        });
    }

    render() {
        return <FtPanel style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
            {this.state.parentStoreCategory != null &&
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button color="secondary" variant={"outlined"} onClick={() => {
                               this.setState({parentStoreCategory: undefined}, () => {
                                   this.loadPaged();
                               })
                           }}>{i18n("返回")}</Button>
                           <Button color="primary" variant={"outlined"}
                                   onClick={() => {
                                       if (this.state.parentStoreCategory != null) {
                                           this.onSaveClick(this.state.parentStoreCategory)
                                       }
                                   }}>
                               {i18n("保存")}
                           </Button>
                       </FtRow>}/>}
            {this.state.parentStoreCategory != null &&
            <FtGrid>
                {this.getAddCategoryComp(this.state.parentStoreCategory)}
            </FtGrid>}
            {this.state.parentStoreCategory != null && this.state.parentStoreCategory.id != null && this.state.parentStoreCategory.id.trim().length > 0 &&
            <div style={{width: "100%"}}>
                <Divider/>
                <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                           leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                            justifyContent={"center"}>
                               <Button color="primary" variant={"outlined"} onClick={() => {
                                   if (this.state.parentStoreCategory != null) {
                                       this.onAddClick(new FssStoreCategory(this.props.store.id, this.state.parentStoreCategory.id))
                                   }
                               }}>{i18n("新增子分类")}</Button>
                           </FtRow>}/>
            </div>}
            {this.state.parentStoreCategory == null &&
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button color="primary" variant={"outlined"} onClick={() => {
                               this.onAddClick(new FssStoreCategory(this.props.store.id))
                           }}>{i18n("新增分类")}</Button>
                       </FtRow>}/>}
            {(this.state.parentStoreCategory == null || (this.state.parentStoreCategory.id != null && this.state.parentStoreCategory.id.trim().length > 0)) &&
            <FtGrid>
                <Table style={{minWidth: 500}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("分类名称")}</TableCell>
                            <TableCell>{i18n("简述")}</TableCell>
                            <TableCell>{i18n("排序系数")}</TableCell>
                            <TableCell>{i18n("封面图")}</TableCell>
                            <TableCell>{i18n("状态")}</TableCell>
                            <TableCell>{i18n("创建时间")}</TableCell>
                            <TableCell>{i18n("更新时间")}</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.paged.content && this.state.paged.content.length > 0 &&
                        this.state.paged.content.map((storeCategory: FssStoreCategory) => (
                            <TableRow>
                                <TableCell>
                                    <Link onClick={() => {
                                        this.onAddClick(storeCategory)
                                    }} style={{cursor: "pointer"}}>
                                        {storeCategory.categoryName}
                                    </Link>
                                </TableCell>
                                <TableCell>{storeCategory.shortDescription}</TableCell>
                                <TableCell>{storeCategory.sortIndex}</TableCell>
                                <TableCell>{<img src={storeCategory.coverImageUrl}
                                                 style={{width: 60}}/>}</TableCell>
                                <TableCell>{storeCategory.categoryStatus == ETwoStatus.NORMAL ? "启用" : "禁用"}</TableCell>
                                <TableCell>{storeCategory.createDatetimeText}</TableCell>
                                <TableCell>{storeCategory.modifiedDatetimeText}</TableCell>
                                <TableCell>
                                    <IconButton onClick={()=>{
                                        this.onDeleteClick(storeCategory.id);
                                    }}><DeleteOutline/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={this.state.paged.totalElements}
                    rowsPerPage={this.state.paged.size}
                    page={this.state.paged.number}
                    onChangePage={(event, page) => {
                        this.state.paged.number = page;
                        this.forceUpdate(this.loadPaged);
                    }}
                    onChangeRowsPerPage={(event) => {
                        this.state.paged.size = +event.target.value;
                        this.state.paged.number = 0;
                        this.forceUpdate(this.loadPaged);
                    }}
                />
            </FtGrid>}
            {this.getChildEditDialog()}
        </FtPanel>
    }

    getChildEditDialog() {
        return <Dialog open={this.state.childStoreCategory != null} PaperProps={{style: {minWidth: 950}}}>
            <DialogContent style={{margin: 8}}>
                {this.state.childStoreCategory != null && this.getAddCategoryComp(this.state.childStoreCategory)}
            </DialogContent>
            <DialogActions style={{display: "flex", margin: 8, paddingLeft: 20, paddingRight: 24, paddingTop: 12}}>
                <Button variant={"outlined"} style={{textTransform: "none"}}
                        onClick={() => {
                            this.setState({childStoreCategory: undefined})
                        }}>取消</Button>
                <Button variant={"outlined"} style={{textTransform: "none", marginLeft: 32}} color={"primary"}
                        onClick={() => {
                            if (this.state.childStoreCategory != null) {
                                this.onSaveClick(this.state.childStoreCategory)
                            }
                        }}>保存</Button>
            </DialogActions>
        </Dialog>
    }

    getAddCategoryComp(storeCategory: FssStoreCategory) {
        return <div style={{width: "100%", display: "flex"}}>
            <div style={{width: 130}}>
                {this.getUploadComp(storeCategory)}
            </div>
            <FtRow cellWidthS={"1"} cellVerticalSpace={16} cellHorizontalSpace={0}
                   style={{width: "calc(100% - 130px)"}}>
                <FtRow cellWidthS={"1,1,1"} cellVerticalSpace={0} cellHorizontalSpace={16} justifyContent={"center"}>
                    <JtTextField id="categoryName" label={i18n("分类名称")}
                                 value={storeCategory.categoryName}
                                 style={{width: "100%"}}
                                 onChange={(event) => {
                                     FtUtil.setProperty(storeCategory, "categoryName", event.target.value);
                                     this.forceUpdate();
                                 }}/>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel> {i18n("状态")} </InputLabel>
                        <Select id="categoryStatus" value={storeCategory.categoryStatus}
                                onChange={(event, child) => {
                                    FtUtil.setProperty(storeCategory, "categoryStatus", event.target.value);
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={"NORMAL"}>{i18n("启用")}</MenuItem>
                            <MenuItem value={"STOP"}>{i18n("禁用")}</MenuItem>
                        </Select>
                    </FormControl>
                    <JtTextField id="sortIndex" label={i18n("排序系数")}
                                 value={storeCategory.sortIndex}
                                 style={{width: "100%"}}
                                 onChange={(event) => {
                                     FtUtil.setProperty(storeCategory, "sortIndex", event.target.value);
                                     this.forceUpdate();
                                 }}/>
                </FtRow>
                {storeCategory.id != null && storeCategory.id.trim().length > 0 &&
                <FtRow cellWidthS={"1,1,1"} cellVerticalSpace={0} cellHorizontalSpace={16} justifyContent={"center"}>
                    <JtTextField id="createDatetimeText" label={i18n("创建时间")}
                                 value={storeCategory.createDatetimeText}
                                 style={{width: "100%"}} disabled={true}/>
                    <JtTextField id="modifiedDatetimeText" label={i18n("更新时间")}
                                 value={storeCategory.modifiedDatetimeText}
                                 style={{width: "100%"}} disabled={true}/>
                    <div/>
                </FtRow>}
                <FtRow cellWidthS={"1"} cellVerticalSpace={0} cellHorizontalSpace={16} justifyContent={"center"}>
                    <JtTextField id="shortDescription" label={i18n("简述")}
                                 value={storeCategory.shortDescription}
                                 style={{width: "100%"}}
                                 onChange={(event) => {
                                     FtUtil.setProperty(storeCategory, "shortDescription", event.target.value);
                                     this.forceUpdate();
                                 }}/>
                </FtRow>
            </FtRow>
        </div>
    }

    getUploadComp(storeCategory: FssStoreCategory) {
        if (storeCategory.coverImageUrl && storeCategory.coverImageUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 130}}>
                <CardMedia image={storeCategory.coverImageUrl} style={{height: 130, width: 130}}
                           onClick={(event) => {
                               event.stopPropagation();
                               FtMediaViewerDialog.FtMediaViewerDialog.show(storeCategory.coverImageUrl, "image");
                           }}>
                </CardMedia>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {

                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 128, height: 128,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginalIcon viewBox={"3 3 18 18"} style={{width: 35, height: 35}}/>
                <Typography>添加LOGO</Typography>
            </div>;
            return <FtFileUploadButtonToOss onFinish={(fileUrl: string, fileName: string) => {
                this.onFinishUpload(fileUrl, storeCategory)
            }}
                                            buttonStyle={buttonStyle}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }

    onFinishUpload(fileUrl: string, storeCategory: FssStoreCategory) {
        if (fileUrl != null && fileUrl.length > 0) {
            FtUtil.setProperty(storeCategory, "coverImageUrl", fileUrl);
            this.forceUpdate();
        }
    }
}
