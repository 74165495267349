import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link} from "react-router-dom";
import FtState from "../ui/FtState";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {i18n} from "../util/I18n";
import FssClientData from "../data/FssClientData";
import FssStoreCoupon from "./FssStoreCoupon";
import FssStoreCouponService from "../service/FssStoreCouponService";
import FssStore from "../model/FssStore";

export interface FssStoreCouponListPagePorp {
    store: FssStore;
}

class FssStoreCouponListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssStoreCoupon> {
    storeId: string = "";
    loadData(): void {
        FtToast.showToastLoading("loading");
        this.filterColumns.set("storeId", this.storeId);
        FssStoreCouponService.getCouponPaged(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssStoreCoupon>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssStoreCouponListPage extends FtComponent<FssStoreCouponListPagePorp, FssStoreCouponListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("couponName", i18n("优惠券名字"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const coupon: FssStoreCoupon = rowData as FssStoreCoupon;
            return <Link to={"/couponDetailPage/" + this.props.store.id + "/" + coupon.id}>{columnValue}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("couponTypeText", i18n("类型"));
        columnProps.push(column2);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column4);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        column6.renderCell = (rowData: object) => {
            const coupon: FssStoreCoupon = rowData as FssStoreCoupon;
            if (coupon.createDatetime) {
                return <span>{FssClientData.formatDatetime(coupon.createDatetime)}</span>
            } else {
                return null;
            }
        };
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("receiveAmount", i18n("领取数量"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("usedAmount", i18n("使用数量"));
        columnProps.push(column8);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssStoreCouponListPageState = FtState.pop("FssStoreCouponListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.storeId = this.props.store.id;
        tableAdapter.onUpdateData = (() => {
            this.forceUpdate();
        });
        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssStoreCouponListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <FtDataTable title={i18n("优惠券列表")}
                            columnProps={this.state.columnProps}
                            adapter={this.state.tableAdapter}
                            toolbarNoSelect={this.getToolbarNoSelect()}
                            filterPanel={this.getFilterPanel()}
                            style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}/>
    }

    getFilterPanel(): ReactNode {
        return <div style={{marginLeft: 8}}>
            <TextField label={i18n("优惠券名称")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("couponName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("couponName")} style={{width: 200, marginRight: 8}}/>
            <FormControl style={{minWidth: 150, marginRight: 8}}>
                <InputLabel> {i18n("优惠券类型")} </InputLabel>
                <Select id="couponType" value={this.state.tableAdapter.getFilterColumnValue("couponType")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("couponType", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={"PERCENT"} key={"PERCENT"}>{i18n("折扣劵")}</MenuItem>
                    <MenuItem value={"MONEY"} key={"MONEY"}>{i18n("满减劵")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/couponDetailPage/" + this.props.store.id + "/0"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
}
