import {ETwoStatus} from "../model/FtAbstractModel";

export enum EExpireStandard {
    EQUALS = "EQUALS", DELAY = "DELAY"
}

export enum EHowToReceive {
    LANDING_PAGE = "LANDING_PAGE",
    STORE_ADMIN = "STORE_ADMIN"

}

export default class FssStoreCoupon {
    id: string = "";
    storeId: string = "";
    couponName: string = "";
    description: string = "";
    couponCode: string = "";
    userAmountLimit: number = 1;
    discount: number = 0;
    orderTotalMoney: number = 0;
    couponType: string = "";
    startDatetime: string = "";
    startDatetimeText: string = "";
    createDatetime: string = "";
    createDatetimeText?: string = "";
    status?: string = ETwoStatus.NORMAL;
    actionType: string = "TOTALMONEY";
    howToUse: string = "CHOOSE";
    endDatetime: string = "";
    endDatetimeText: string = "";
    expireStandard?: string = EExpireStandard.EQUALS;
    expireDays?: number = 0;
    totalAmountLimit: number = 0;
    flyerImageUrl?: string = "";
    howToReceive?: string = EHowToReceive.LANDING_PAGE;
    receiveAmount: number = 0;
    usedAmount: number = 0;
    startDatetimeString: string = "";
    endDatetimeString: string = "";

    couponTags: Array<string> = [];
    tagsList: Array<string> = new Array<string>();
    defaultTagsList: Array<string> = new Array<string>();
    productMoney: number = 0;


}
