import * as React from "react";
import {ChangeEvent} from "react";
import {
    Breadcrumbs,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import JtTextField from "../ui/JtTextField";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import {ArrowBack} from "@material-ui/icons";
import FssCountryInfo from "../model/FssCountryInfo";
import FssCountryInfoService from "../service/FssCountryInfoService";
import FtToolbar from "../ui/FtToolbar";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import {Language} from "../data/AppClientInfo";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import {ETwoStatus} from "../model/FtAbstractModel";

interface FssCountryInfoDetailPageProp extends RouteComponentProps<{ id?: string }> {
}

export class FssCountryInfoDetailPageState {
    countryInfo: FssCountryInfo = new FssCountryInfo();
}


export default class FssCountryInfoDetailPage extends React.Component<FssCountryInfoDetailPageProp, FssCountryInfoDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {countryInfo: new FssCountryInfo()};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.id != null && this.props.match.params.id.length > 1) {
            FtToast.showToastLoading("loading");
            FssCountryInfoService.getById(this.props.match.params.id, (countryInfo: FssCountryInfo) => {
                FtToast.closeToast();
                this.setState({countryInfo: countryInfo})
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.countryInfo, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssCountryInfoService.save(this.state.countryInfo, (countryInfo: FssCountryInfo) => {
            FtToast.closeToast();
            this.setState({countryInfo: countryInfo})
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    render() {
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={this.onCancelClick}
                      style={{cursor: "pointer"}}>
                    <div style={{display: "flex"}}>
                        <div style={{maxHeight: 24}}><ArrowBack style={{width: 20}}/></div>
                        <span>国家信息列表</span>
                    </div>
                </Link>
                <Typography color="textPrimary">
                    {this.state.countryInfo.countryName}
                </Typography>
            </Breadcrumbs>
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button variant="outlined" color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
                       </FtRow>}/>
            <FtPanel panelHeader={"基本信息"} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
                <FtGrid>
                    <FtRow cellWidthS={"1,1,1,1"}>
                        <JtTextField id="countryName" label={i18n("国家名称")}
                                     value={this.state.countryInfo.countryName}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                        <JtTextField id="countryCode" label={i18n("国家编码")}
                                     value={this.state.countryInfo.countryCode}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                        <FormControl style={{width: '100%'}}>
                            <InputLabel> {i18n("状态")} </InputLabel>
                            <Select id="status"
                                    value={this.state.countryInfo.status}
                                    onChange={(event, child) => {
                                        this.state.countryInfo.status = event.target.value as ETwoStatus;
                                        this.forceUpdate();
                                    }}>
                                <MenuItem value={ETwoStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                <MenuItem value={ETwoStatus.DISABLED}>{i18n("停用")}</MenuItem>
                            </Select>
                        </FormControl>
                    </FtRow>
                    <FtRow cellWidthS={"1,1,1,1"}>
                        <JtTextField id="currencyUnit" label={i18n("货币单位")}
                                     value={this.state.countryInfo.currencyUnit}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                        <JtTextField id="currencySymbol" label={i18n("货币符号")}
                                     value={this.state.countryInfo.currencySymbol}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                        <JtTextField id="decimalPoint" label={i18n("货币小数点")} type={"integer"}
                                     value={this.state.countryInfo.decimalPoint}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                        <FtTextFieldNumber label={i18n("汇率")}
                                           style={{width: '100%'}}
                                           value={this.state.countryInfo.exchangeRate}
                                           type={"float"}
                                           onChangeValue={(value) => {
                                               if (value != null) {
                                                   this.state.countryInfo.exchangeRate = value;
                                               } else {
                                                   this.state.countryInfo.exchangeRate = 0;
                                               }
                                               this.forceUpdate();
                                           }}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"}>
                        <FormControl>
                            <FormLabel>默认语言</FormLabel>
                            <RadioGroup row
                                        value={this.state.countryInfo.defaultLanguage ? this.state.countryInfo.defaultLanguage : ""}
                                        onChange={(event, value) => {
                                            this.state.countryInfo.defaultLanguage = value;
                                            this.forceUpdate();
                                        }}>
                                <FormControlLabel value={Language.en_US} control={<Radio/>} label="English"/>
                                <FormControlLabel value={Language.fr_FR} control={<Radio/>} label="Français"/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>支持的语言</FormLabel>
                            <FtCheckboxGroup row={true}
                                             values={[Language.en_US, Language.fr_FR]}
                                             labels={["English", "Français"]}
                                             checkedValues={this.state.countryInfo.supportLanguageList}
                                             onCheck={(checkedValue: Array<Language>) => {
                                                 let supportLanguage = "";
                                                 if (checkedValue != null && checkedValue.length > 0) {
                                                     checkedValue.forEach((language: Language) => {
                                                         supportLanguage += "," + language;
                                                     })
                                                 }
                                                 if (supportLanguage.length > 0) {
                                                     this.state.countryInfo.supportLanguage = supportLanguage.substring(1);
                                                 }
                                                 this.state.countryInfo.supportLanguageList = checkedValue;
                                                 this.forceUpdate();
                                             }}/>
                        </FormControl>
                    </FtRow>
                    <FtRow cellWidthS={"1,1,1,1"}>
                        <JtTextField id="areaCode" label={i18n("国际区号")}
                                     value={this.state.countryInfo.areaCode}
                                     style={{width: '100%'}} onChange={this.onChange}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>
    }
}
