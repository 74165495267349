import * as React from "react";
import {ChangeEvent} from "react";
import {Button, IconButton, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FtAppBar from "../ui/FtAppBar";
import {ChevronLeft} from "@material-ui/icons";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import {i18n} from "../util/I18n";
import {grey} from "@material-ui/core/colors";
import AppClientInfo from "../data/AppClientInfo";
import FssUserService from "../service/FssUserService";
import FasUserService from "../service/FasUserService";

class FssResetPasswordDialogState {
    loginName: string = "";
    password: string = "";
    verifyCode: string = "";
    verifyCodeId: string = "";
    intervalId?: number;
    second: number = 0;
    verifyCodeMessage?: string;
    open: boolean = false;
}

export default class FssResetPasswordDialog extends React.Component<any, FssResetPasswordDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loginName: "",
            password: "",
            verifyCode: "",
            verifyCodeId: "",
            second: 0,
            verifyCodeMessage: i18n("获取验证码"),
            open: false,
        };
        this.onClickReset = this.onClickReset.bind(this);
        this.validateMobileForm = this.validateMobileForm.bind(this);
        this.onSendVerifyCode = this.onSendVerifyCode.bind(this);
        this.show = this.show.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    show(): void {
        this.setState({open: true});
    }

    onCancelClick() {
        this.setState({open: false});
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (event.target.id == "loginName")
            this.setState({loginName: event.target.value});
        else if (event.target.id == "password")
            this.setState({password: event.target.value});
        else if (event.target.id == "verifyCode")
            this.setState({verifyCode: event.target.value});
    }

    onClickReset() {
        if (this.state.loginName) {
            FtToast.showToastLoading();
            FasUserService.resetFasUserPassword(this.state.loginName, this.state.password, this.state.verifyCode, this.state.verifyCodeId,
                (data: string) => {
                    FtToast.closeToast();
                    this.setState({open: false});
                });
        }
    }

    validateMobileForm(): boolean {
        if (this.state.loginName == null || this.state.loginName.length < 10) {
            FtSnackbar.FtSnackbar.show(i18n("请输入有效的手机号"));
            return false;
        }
        if (this.state.password == null || this.state.password.length < 4) {
            FtSnackbar.FtSnackbar.show(i18n("请输入有效的密码"));
            return false;
        }
        if (this.state.verifyCode == null || this.state.verifyCode.length < 4) {
            FtSnackbar.FtSnackbar.show(i18n("请输入有效的短信验证码"));
            return false;
        }
        return true;
    }

    onSendVerifyCode() {
        if (this.state.second > 0) {
            return;
        }
        FtToast.showToastLoading(i18n("正在发送短信验证码"), 10000);
        // FssUserService.sendVerificationCode(this.state.loginName, (data: string) => {
        //     FtToast.closeToast();
        //     this.setState({verifyCodeId: data, second: 60});
        //     const intervalId = window.setInterval(() => {
        //         if (this.state.second > 0) {
        //             this.setState({
        //                 second: this.state.second - 1,
        //                 verifyCodeMessage: this.state.second + i18n("秒后可重发")
        //             });
        //         } else {
        //             window.clearInterval(this.state.intervalId);
        //             this.setState({verifyCodeMessage: i18n("获取验证码")});
        //         }
        //     }, 1000);
        //     this.setState({intervalId: intervalId});
        // })
    }

    render() {
        const fullScreen: boolean = AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode == "mobile";
        return <Dialog open={this.state.open} fullScreen={fullScreen}
                       PaperProps={{
                           className: "paddingTopSafeAreaInset",
                           style: {backgroundColor: grey[200], maxWidth: 550}
                       }}>
            {fullScreen && <FtAppBar title={i18n("重新设置密码")} position={"fixedTop"}
                                     leftNode={<IconButton onClick={this.onCancelClick}> <ChevronLeft style={{}}/>
                                     </IconButton>}>
            </FtAppBar>}
            {fullScreen && <div style={{paddingTop: 64}}/>}
            {!fullScreen && <div style={{paddingTop: 16}}/>}
            <FtPanel style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                <FtGrid style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                        <Typography variant={"h5"}>{i18n("请用注册时的手机号重置密码")}</Typography>
                        <JtTextField id="loginName" label={i18n("电话")} required={true} style={{width: "100%"}}
                                     variant={"outlined"}
                                     value={this.state.loginName}
                                     onChange={(event) => {
                                         this.setState({loginName: event.target.value})
                                     }}/>
                        <JtTextField id={"verifyCode"} type="integer" label={i18n("短信验证码")} placeholder={i18n("请输入验证码")}
                                     variant={"outlined"}
                                     onChange={this.onChange} required={true} style={{width: "100%"}}
                                     endAdornment={<Button variant={"outlined"} size={"small"}
                                                           onClick={this.onSendVerifyCode}>{this.state.verifyCodeMessage}</Button>}
                        />
                        <JtTextField id="password" type="password" label={i18n("密码")} onChange={this.onChange}
                                     required={true}
                                     style={{width: "100%"}} variant={"outlined"}/>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"} justifyContent={"space-between"} cellHorizontalAlign={"justify"}>
                        <Button onClick={this.onCancelClick} variant={"contained"} style={{marginRight: 16}}>
                            {i18n("取消")}
                        </Button>
                        <Button onClick={this.onClickReset} color="primary" variant={"contained"}>
                            {i18n("设置新密码")}
                        </Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </Dialog>;
    }
}
