import {FtAbstractModel} from "../model/FtAbstractModel";
import FssCountryInfo from "./FssCountryInfo";

export enum EStatus {
    NORMAL = "NORMAL", DISABLED = "DISABLED"
}

export default class FssUser extends FtAbstractModel {
    email: string = "";
    emailVerified?: boolean;
    mobile: string = "";
    mobileVerified?: boolean;
    createDatetime?: Date;
    userStatus?: EStatus;
    nickName?: string;
    avatarUrl?: string;
    password?: string;
    userLevel?: number;
    defaultLanguage?: string;
    userCode: string = "";
    loginName: string = "";
    countryInfo: FssCountryInfo = new FssCountryInfo();
}
