import AjaxHelper from "../service/AjaxHelper";
import FtToast from "../ui/FtToast";
import AppClientInfo from "../data/AppClientInfo";
import FtApiResponse from "../model/FtApiResponse";
import FssClientData from "../data/FssClientData";
import FssLoginDialog from "../user/FssLoginDialog";
import FtSnackbar from "../ui/FtSnackbar";


export default class FssHttpService {
    static getRequestHeaders() {
        const headers: any = [];
        if (FssClientData.getData().webSession.dataString) {
            headers.ftshop = FssClientData.getData().webSession.dataString;
        }
        if (AppClientInfo.clientInfo.language) {
            headers.lang = AppClientInfo.clientInfo.language;
        }
        if (AppClientInfo.clientInfo.packageName != null && AppClientInfo.clientInfo.packageName.length > 0) {
            headers.packageName = AppClientInfo.clientInfo.packageName;
        }
        if (AppClientInfo.clientInfo.nativeAppPackageName != null && AppClientInfo.clientInfo.nativeAppPackageName.length > 0) {
            headers.nativeAppPackageName = AppClientInfo.clientInfo.nativeAppPackageName;
        }
        headers.websiteStoreId = FssClientData.getData().websiteStore.id;
        return headers;
    }

    static get(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.get(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: FtApiResponse, textStatus: string, response: any) => {
            console.log(data);
            if (data && data.code != null && data.code === "OK") {
                successFunction(data.content);
            } else {
                FtToast.closeToast();
                if (failFunction)
                    failFunction(data);
                else {
                    FssHttpService.handleError(data);
                }
            }
            FssHttpService.getDataFromResponseHeader(response);
        }, failFunction ? failFunction : FssHttpService.handleError, FssHttpService.getRequestHeaders());
    }

    static search(uri: string, searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null, pageNo: number, pageSize: number, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        let sort = sortPropertyName == null ? "" : sortPropertyName;
        let direction = sortDirection == null ? "asc" : sortDirection;
        const params: any = {
            searchKeyword: searchKeyword,
            sort: sort + "," + direction,
            page: pageNo,
            size: pageSize
        };
        filterColumns.forEach((value: any, key: string) => {
            params[key] = value;
        });
        FssHttpService.get(uri, params, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static postFormData(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postFormData(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: FtApiResponse, textStatus: string, response: any) => {
            console.log(data)
            if (data && data.code != null && data.code === "OK") {
                successFunction(data.content);
            } else {
                FtToast.closeToast();
                if (failFunction)
                    failFunction(data);
                else {
                    FssHttpService.handleError(data);
                }
            }
            FssHttpService.getDataFromResponseHeader(response);
        }, failFunction ? failFunction : FssHttpService.handleError, FssHttpService.getRequestHeaders());
    }

    static postJson(uri: string, jsonObject: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postJson(AppClientInfo.clientInfo.apiServerUrl + uri, jsonObject,
            (data: FtApiResponse, textStatus: string, response: any) => {
                console.log(data)
                if (data && data.code != null && data.code === "OK") {
                    successFunction(data.content);
                } else {
                    FtToast.closeToast();
                    if (failFunction)
                        failFunction(data);
                    else {
                        FssHttpService.handleError(data);
                    }
                }
                FssHttpService.getDataFromResponseHeader(response);
            }, failFunction ? failFunction : FssHttpService.handleError, FssHttpService.getRequestHeaders());
    }

    static handleError(apiResponse: any): void {
        FtToast.closeToast();
        console.log(apiResponse);
        if (apiResponse && apiResponse.code) {
            if (apiResponse.code === "INVALID_REQUEST") {
                //说明请求的 header 里的参数有问题，需要清理掉
                FssClientData.clearWebSession();
                FtSnackbar.showText("错误的请求，页面将在3秒后刷新", 3000, undefined, undefined, undefined);
                // FtSnackbar.showText("错误的请求，页面将在3秒后刷新", 3000, undefined, undefined, undefined, () => {
                //     window.location.reload();
                // });
            } else if (apiResponse.code === "ERROR_LOGIN_IS_REQUIRED") {
                FssLoginDialog.loginDialog.show(data => {
                    FtToast.showToastLoading("登录成功，重新加载页面");
                    window.location.reload();
                });
            } else {
                FtSnackbar.showText((apiResponse.message ? apiResponse.message : "")
                    + (apiResponse.path ? ", path=" + apiResponse.path : ""), 10000);
            }
        } else {
            if (FtSnackbar && FtSnackbar.FtSnackbar)
                FtSnackbar.showText("Error：" + apiResponse.message + (apiResponse.status ? ", status=" + apiResponse.status : "")
                    + (apiResponse.path ? ", path=" + apiResponse.path : ""), 10000);
            else
                alert("Error：" + apiResponse.message + (apiResponse.status ? ", status=" + apiResponse.status : "")
                    + (apiResponse.path ? ", path=" + apiResponse.path : ""));
        }
    }

    // static upload(file: any, params: any, successFunction: (data: any) => void, progressFunction: (percent: number) => void, failFunction?: (error: any) => void) {
    //     var formData = new FormData();
    //     formData.append("uploadFile", file);
    //     AjaxHelper.uploadFile(AppClientInfo.clientInfo.apiServerUrl + "/open/file/upload", formData, successFunction, (e: any) => {
    //         if (e.lengthComputable) {
    //             var max = e.total;
    //             var current = e.loaded;
    //             var Percentage = (current * 100) / max;
    //             progressFunction(Percentage);
    //         }
    //     }, failFunction ? failFunction : FssHttpService.handleError, FssHttpService.getRequestHeaders());
    // }

    static getDataFromResponseHeader(response: any) {
        const dataString = response.getResponseHeader("ftshop");
        if (dataString != null && dataString.length > 0) {
            FssClientData.getData().webSession.dataString = dataString;
        }
        const lang = response.getResponseHeader("lang");
        if (lang)
            AppClientInfo.clientInfo.language = lang;
    }
}
