export enum EStatus {
    NORMAL = "NORMAL", STOP = "STOP"
}

export default class FasAppEmail {
    appId: number = 0;
    sendEmailAccount: string = "";
    sendEmailPassword: string = "";
    sendEmailSmtpHost: string = "";
    sendEmailSmtpName: string = "";
    sendPersonal: string = "";
    protocol: string = "";
    port: string = "";
    sslFlag: boolean = false;
    status: EStatus = EStatus.STOP;
    createdTimeText: string = "";
    emailVerified: boolean = false;
}