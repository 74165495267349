import * as React from "react";
import {ChangeEvent, CSSProperties, ReactNode} from "react";
import {FormControlLabel, Paper, WithTheme} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import withTheme from "@material-ui/core/styles/withTheme";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Clear, Refresh, Search} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FtPagedList from "../model/FtPagedList";
import {i18n} from "../util/I18n";
import {FtSortDirection} from "../model/FtSortDirection";
import FtUtil from "../util/FtUtil";
import FtDivFlex from "./FtDivFlex";
import FtToolbar from "./FtToolbar";
import AppClientInfo from "../data/AppClientInfo";
import FtRow from "./FtRow";


export class FtDataTableAdapter<M> {
    pagedList: FtPagedList<M> = new FtPagedList<M>();
    onUpdateData?: (pagedList: FtPagedList<any>) => void;
    keyword: string = "";
    filterColumns: Map<string, any> = new Map<string, any>();
    sortColumn?: FtDataTableColumnProps;
    sortDirection: FtSortDirection = FtSortDirection.asc;
    selectedRow: Array<M> = [];

    resetSearch() {
        this.keyword = "";
        this.filterColumns.clear();
        this.selectedRow = [];
    }

    getFilterColumnValue(columnName: string): string {
        const value = this.filterColumns.get(columnName);
        if (value == null || value == undefined)
            return "";
        else
            return value;
    }

    filter(keyword?: string, filterColumns?: Map<string, any>) {
        if (keyword)
            this.keyword = keyword;
        if (filterColumns)
            this.filterColumns = filterColumns;
        this.loadData();
    };

    loadData() {

    }

    clearSelection() {
        this.selectedRow = [];
    }

    changePageNo(newPageNo: number) {
        this.pagedList.number = newPageNo;
        this.loadData();
        this.selectedRow = [];
    };

    changePageSize(newPageSize: number) {
        this.pagedList.number = 0;
        this.pagedList.size = newPageSize;
        this.loadData();
        this.selectedRow = [];
    };

    sort(sortColumn: Array<{ propertyName: string, value: FtSortDirection }>) {

    };

    removeRow(rowData: M) {

    }

    fireUpdateData() {
        if (this.onUpdateData)
            this.onUpdateData(this.pagedList);
    }

    isSame(rowData: M, item: M): boolean {
        return rowData === item;
    }
}

export class FtDataTableColumnProps {
    label: string = "";
    sortable: boolean = false;
    filterable: boolean = false;
    i18n: boolean = false;
    align: "left" | "center" | "right" = "left";
    propertyName: string = "";
    onClickCell?: (rowData: object, rowIndex: number, columnValue: any) => void;
    renderCell?: (rowData: object, rowIndex: number, columnValue: any) => ReactNode;
    renderHeader?: (columnProps: FtDataTableColumnProps) => ReactNode;

    constructor(propertyName: string, label: string = "", align: "left" | "center" | "right" = "left", sortable: boolean = false,
                filterable: boolean = false, i18n: boolean = false) {
        this.propertyName = propertyName;
        this.label = label;
        this.align = align;
        this.sortable = sortable;
        this.filterable = filterable;
        this.i18n = i18n;
    }
}

export interface FtDataTableProp<M> extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, WithTheme {
    title?: string;
    columnProps: Array<FtDataTableColumnProps>;
    selectMode?: "single" | "multiple";
    onHighlightRow?: (rowData: object, rowIndex: number) => void;
    adapter: FtDataTableAdapter<M>;
    toolbarNoSelect?: ReactNode;
    toolbarWithSelect?: ReactNode;
    filterPanel?: ReactNode;
    renderRowForMobile?: (rowData: object, rowIndex: number) => ReactNode;
    isRowSelected?: (rowData: object, rowIndex: number) => boolean;
    onClickCheckbox?: (rowData: object, rowIndex: number) => void;
    onClickSelectAll?: (all: Array<object>, checked: boolean) => void;
}

export class FtDataTableState {
    showFilterPanel: boolean = false;
    highlightRowIndex: number = -1;
}

class FtDataTable<M> extends React.Component<FtDataTableProp<M>, FtDataTableState> {
    constructor(props: any) {
        super(props);
        let showFilterPanel: boolean = false;
        if (this.props.adapter.filterColumns.size > 0 || (this.props.adapter.keyword != null && this.props.adapter.keyword.trim().length > 0))
            showFilterPanel = true;
        this.state = {showFilterPanel: showFilterPanel, highlightRowIndex: -1};
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.isRowSelected = this.isRowSelected.bind(this)
        this.onClickSelectAll = this.onClickSelectAll.bind(this);
        this.getToolbar = this.getToolbar.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.onClickHeader = this.onClickHeader.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.getTitleRow = this.getTitleRow.bind(this);
        this.getDataRow = this.getDataRow.bind(this);
    }

    render() {
        let styles: CSSProperties = {};
        styles = FtUtil.mergeObject(styles, this.props.style);
        let rowCount: number = 0;
        if (this.props.adapter.pagedList && this.props.adapter.pagedList.content)
            rowCount = this.props.adapter.pagedList.content.length;
        return <Paper style={styles}>
            {this.getToolbar()}
            {this.props.filterPanel && this.state.showFilterPanel &&
            <FtDivFlex style={{alignItems: "flex-end"}}>
                <div> {this.props.filterPanel}</div>
                <FtDivFlex>
                    <ButtonGroup color="primary" variant={"outlined"}>
                        <Button onClick={this.onClickReset}>{"Reset"}</Button>
                        <Button onClick={this.onClickSearch}>{"Search"}</Button>
                    </ButtonGroup>
                </FtDivFlex>
            </FtDivFlex>}
            <div>
                <Table>
                    <TableHead>
                        {this.getTitleRow(rowCount)}
                    </TableHead>
                    <TableBody>
                        {this.props.adapter.pagedList && this.props.adapter.pagedList.content &&
                        this.props.adapter.pagedList.content.map((rowData: any, rowIndex: number) => {
                            return this.getDataRow(rowData, rowIndex);
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination rowsPerPageOptions={[10, 20, 50, 100, 200, 500]} component="div"
                             count={this.props.adapter.pagedList.totalElements}
                             rowsPerPage={this.props.adapter.pagedList.size}
                             page={this.props.adapter.pagedList.number}
                             labelRowsPerPage={"Page size"}
                             onChangePage={this.handleChangePage}
                             onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </Paper>;
    }

    protected getTitleRow(rowCount: number) {
        if (AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode === "mobile")
            return <TableRow>
                <TableCell padding="checkbox" colSpan={2}>
                    <FormControlLabel
                        control={<Checkbox
                            indeterminate={this.props.adapter.selectedRow.length > 0 && this.props.adapter.selectedRow.length < rowCount}
                            checked={this.props.adapter.selectedRow.length === rowCount}
                            onChange={this.onClickSelectAll}
                        />} label={"Select all"}/>
                </TableCell>
            </TableRow>;
        else
            return <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={this.props.adapter.selectedRow.length > 0 && this.props.adapter.selectedRow.length < rowCount}
                        checked={this.props.adapter.selectedRow.length === rowCount}
                        onChange={this.onClickSelectAll}
                    />
                </TableCell>
                {this.props.columnProps && this.props.columnProps.map((columnProps: FtDataTableColumnProps, index: number) => {
                    let show: boolean = true;
                    if (AppClientInfo.clientInfo.viewMode === "mobile" && this.props.renderRowForMobile)
                        return null;
                    else
                        return <TableCell key={columnProps.propertyName} align={columnProps.align}>
                            {columnProps.renderHeader ? columnProps.renderHeader(columnProps) :
                                <TableSortLabel active={this.props.adapter.sortColumn === columnProps}
                                                direction={this.props.adapter.sortDirection}
                                                onClick={(event: any) => {
                                                    this.onClickHeader(columnProps)
                                                }}>{columnProps.label}</TableSortLabel>
                            }
                        </TableCell>;
                })}
            </TableRow>;
    }

    protected getDataRow(rowData: any, rowIndex: number) {
        const isRowSelected: boolean = this.isRowSelected(rowData, rowIndex);
        return <TableRow key={rowIndex} hover onClick={() => {
            this.onClickRow(rowData, rowIndex);
        }} selected={rowIndex == this.state.highlightRowIndex} style={{}}>
            <TableCell style={{paddingLeft: 0, paddingRight: 0, verticalAlign: "top"}}>
                <Checkbox checked={isRowSelected}
                          onClick={(event: any) => {
                              this.onClickCheckbox(rowData, rowIndex);
                              event.preventDefault();
                              event.stopPropagation();
                          }}/>
            </TableCell>
            {AppClientInfo.clientInfo.viewMode === "mobile" && this.props.renderRowForMobile
            && <TableCell>
                {this.props.renderRowForMobile(rowData, rowIndex)}
            </TableCell>}
            {(AppClientInfo.clientInfo.viewMode !== "mobile" || this.props.renderRowForMobile == undefined)
            && this.props.columnProps && this.props.columnProps.map((columnProps: FtDataTableColumnProps, colIndex: number) => {
                let cellValue: any = FtUtil.getProperty(rowData, columnProps.propertyName);
                if (columnProps.i18n != null && columnProps.i18n == true)
                    cellValue = i18n(cellValue);
                return <TableCell key={columnProps.propertyName + "-" + rowIndex}
                                  style={{overflowWrap: "anywhere"}}
                                  align={columnProps.align} onClick={(event: any) => {
                    if (columnProps.onClickCell) {
                        event.preventDefault();
                        columnProps.onClickCell(rowData, rowIndex, cellValue);
                    }
                }}>
                    {columnProps.renderCell ? columnProps.renderCell(rowData, rowIndex, cellValue) : cellValue}
                </TableCell>;
            })}
        </TableRow>;
    }

    onClickRow(rowData: any, rowIndex: number) {
        if (this.props.onHighlightRow) {
            this.setState({highlightRowIndex: rowIndex}, () => {
                if (this.props.onHighlightRow)
                    this.props.onHighlightRow(rowData, rowIndex);
            });
        }
    }

    onClickHeader(columnProps: FtDataTableColumnProps) {
        if (this.props.adapter.sortColumn === columnProps) {
            if (this.props.adapter.sortDirection == FtSortDirection.asc)
                this.props.adapter.sortDirection = FtSortDirection.desc;
            else
                this.props.adapter.sortDirection = FtSortDirection.asc;
        } else {
            this.props.adapter.sortColumn = columnProps;
            this.props.adapter.sortDirection = FtSortDirection.asc;
        }
        this.props.adapter.changePageNo(0);
    }

    onClickReset() {
        this.props.adapter.resetSearch();
        this.props.adapter.changePageNo(0);
    }

    onClickSearch() {
        this.props.adapter.changePageNo(0);
    }

    private getToolbar(): ReactNode {
        const {theme} = this.props;
        const numSelected = this.props.adapter.selectedRow.length;
        const toolbarStyle: CSSProperties = {display: "flex", justifyContent: "space-between"};
        if (numSelected > 0) {
            toolbarStyle.backgroundColor = theme.palette.secondary.light;
        }
        //如果是 PC
        if (AppClientInfo.clientInfo.viewMode == "mobile")
            return <FtRow cellWidthS={"1"}>
                <Typography variant="h6" id="tableTitle" style={{textAlign: "center"}}>
                    {this.props.title}
                </Typography>
                {this.getToolbarLeftNode(numSelected)}
                {this.getToolbarRightNode(numSelected)}
            </FtRow>;
        else
            return <FtToolbar leftNode={this.getToolbarLeftNode(numSelected)}
                              centerNode={<Typography variant="h6" id="tableTitle">
                                  {this.props.title}
                              </Typography>}
                              rightNode={this.getToolbarRightNode(numSelected)}>
            </FtToolbar>;
    }

    private getToolbarRightNode(numSelected: number): ReactNode {
        return <TablePagination rowsPerPageOptions={[10, 20, 50, 100, 200, 500]} component="div"
                                count={this.props.adapter.pagedList.totalElements}
                                rowsPerPage={this.props.adapter.pagedList.size}
                                page={this.props.adapter.pagedList.number}
                                labelRowsPerPage={"Page size"}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />;
    }

    private getToolbarLeftNode(numSelected: number): ReactNode {
        if (numSelected > 0)
            return <FtDivFlex>
                <Tooltip title="Unselect">
                    <Button onClick={() => {
                        this.onClickSelectAll(null, false)
                    }}> <Clear/>{numSelected} selected</Button>
                </Tooltip>
                {this.props.toolbarWithSelect}
            </FtDivFlex>;
        else
            return <FtDivFlex>
                <Tooltip title="Refresh">
                    <IconButton onClick={() => {
                        this.props.adapter.changePageNo(0);
                    }}> <Refresh/> </IconButton>
                </Tooltip>
                {this.props.filterPanel && <Tooltip title="Search">
                    <IconButton onClick={() => {
                        this.setState({showFilterPanel: !this.state.showFilterPanel})
                    }}>
                        <Search/>
                    </IconButton>
                </Tooltip>}
                {this.props.toolbarNoSelect}
            </FtDivFlex>
    }

    onClickSelectAll(event: ChangeEvent<HTMLInputElement> | null, checked: boolean) {
        if (this.props.onClickSelectAll) {
            if (checked) {
                const newSelected: Array<any> = [];
                const temp = newSelected.concat(this.props.adapter.pagedList.content);
                this.props.onClickSelectAll(temp, checked);
            } else
                this.props.onClickSelectAll(new Array(), checked);
        } else {
            if (checked) {
                if (this.props.adapter.pagedList && this.props.adapter.pagedList.content) {
                    const newSelected: Array<any> = [];
                    const temp = newSelected.concat(this.props.adapter.pagedList.content);
                    this.props.adapter.selectedRow = temp;
                    this.forceUpdate();
                }
            } else {
                this.props.adapter.selectedRow = [];
                this.forceUpdate();
            }
        }
    }

    onClickCheckbox(rowData: any, rowIndex: number) {
        if (this.props.onClickCheckbox)
            this.props.onClickCheckbox(rowData, rowIndex);
        else {
            const index = this.props.adapter.selectedRow.indexOf(rowData);
            //之前未选中
            if (index < 0) {
                this.props.adapter.selectedRow.push(rowData);
                this.forceUpdate();
            } else {
                this.props.adapter.selectedRow.splice(index, 1);
                this.forceUpdate();
            }
        }
    }

    private handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
        this.props.adapter.changePageNo(page);
    }

    private handleChangeRowsPerPage(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.props.adapter.changePageSize(+event.target.value);
    }

    private isRowSelected(rowData: any, rowIndex: number): boolean {
        if (this.props.isRowSelected)
            return this.props.isRowSelected(rowData, rowIndex);
        else {
            let selected: boolean = false;
            this.props.adapter.selectedRow.forEach((item: any, index: number) => {
                if (this.props.adapter.isSame(rowData, item))
                    selected = true;
            });
            return selected;
        }
    }
}

export default withTheme(FtDataTable);
