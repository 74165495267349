
export enum EStatus {
    NORMAL="NORMAL", STOP="STOP", DELETED = "DELETED"
}
export default class FssUserBalanceRule {
    id: string = "";
    rechargeBalance: number = 0;
    giveBalance: number = 0;
    status: EStatus = EStatus.NORMAL;
    storeId: string = "";
    sellerId: string = "";
}
