import React from 'react';
import './App.css';
import {HashRouter, Route} from "react-router-dom";
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import FtToast from "./ui/FtToast";
import FtSnackbar from "./ui/FtSnackbar";
import FtMessageDialog from "./ui/FtMessageDialog";
import FtOptionDialog from "./ui/FtOptionDialog";
import FssLoginDialog from "./user/FssLoginDialog";
import FssWebsiteAdminMainPage from "./mainframe/FssWebsiteAdminMainPage";
import {theme} from "./mainframe/FssWebsiteAdminTheme";

const GlobalCss = withStyles({
    '@global': {
        '.MuiButton-root': {
            textTransform: "none"
        }
    },
})(() => null);

function App() {
    return <HashRouter>
        <MuiThemeProvider theme={theme}>
            <GlobalCss/>
            <div>
                <FtToast ref={(element: any) => {
                    FtToast.FtToast = element;
                }}/>
                <FtSnackbar ref={(element: any) => {
                    FtSnackbar.FtSnackbar = element;
                }}/>
                <FtMessageDialog ref={(element: any) => {
                    FtMessageDialog.dialog = element;
                }}/>
                <FtOptionDialog ref={(element: any) => {
                    FtOptionDialog.dialog = element;
                }}/>
                <FssLoginDialog defaultOnSuccess={() => {
                    // FssClientData.synShoppingCartDbAndCache(()=>{
                    //     window.location.reload();
                    // });
                }} ref={(element: any) => {
                    FssLoginDialog.loginDialog = element;
                }}/>
            </div>
            <Route path="/" component={FssWebsiteAdminMainPage} exact={false}/>
        </MuiThemeProvider>
    </HashRouter>;
}

export default App;
