import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssUserBalanceRule from "../model/FssUserBalanceRule";
import FtApiResponse from "../model/FtApiResponse";

export default class FssUserBalanceService {

    static getUserBalanceRuleList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                  pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssUserBalanceRule>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.search("/api/balanceRule/getUserBalanceRuleList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction,
            failFunction ? failFunction : FssHttpService.handleError);
    }

    static saveUserBalanceRule(userBalanceRule: FssUserBalanceRule, successFunction: (data: FssUserBalanceRule) => void, failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/balanceRule/saveUserBalanceRule", userBalanceRule, successFunction, FssHttpService.handleError);
    }

    static deleteUserBalanceRule(idList: Array<string>, successFunction: (data: FtApiResponse) => void): void {
        FssHttpService.postJson("/api/balanceRule/deleteUserBalanceRule", idList, successFunction, FssHttpService.handleError);
    }

}