import FssHttpService from "../service/FssHttpService";
import FtPagedList from "../model/FtPagedList";
import FssStoreCoupon from "../marketing/FssStoreCoupon";


export default class FssStoreCouponService {

    static getCouponPaged(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                          pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssStoreCoupon>) => void): void {
        FssHttpService.search("/api/storeCoupon/getCouponPaged", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getById(id: string, successFunction: (data: FssStoreCoupon) => void): void {
        FssHttpService.get("/api/storeCoupon/getById", {id: id}, successFunction, FssHttpService.handleError);
    }

    static save(storeCoupon: FssStoreCoupon, successFunction: (data: FssStoreCoupon) => void): void {
        FssHttpService.postJson("/api/storeCoupon/save", storeCoupon, successFunction, FssHttpService.handleError);
    }

    static getAvailCouponList(storeId: string, keyword: string, maxCount: number, successFunction: (couponList: Array<FssStoreCoupon>) => void): void {
        let params = {storeId: storeId, keyword: keyword, maxCount: maxCount}
        FssHttpService.get("/api/storeCoupon/getAvailCouponList", params, successFunction, FssHttpService.handleError);
    }
}
