import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtState from "../ui/FtState";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, Dialog, TextField} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import {i18n} from "../util/I18n";
import FssUserCoupon, {ECouponChannel} from "./FssUserCoupon";
import FssUserCouponService from "../service/FssUserCouponService";
import FssStore from "../model/FssStore";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FtSnackbar from "../ui/FtSnackbar";
import FssStoreCoupon from "./FssStoreCoupon";
import FssStoreCouponComboBox from "./FssStoreCouponComboBox";

export interface FssUserCouponListPagePorp {
    store: FssStore;
}

class FssUserCouponListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    openUserCouponDetail: boolean = false;
    userCodes: string = "";
    coupon: FssStoreCoupon = new FssStoreCoupon();
}

class JtTableAdapter extends FtDataTableAdapter<FssUserCoupon> {
    storeId: string = "";

    loadData(): void {
        FtToast.showToastLoading("loading");
        this.filterColumns.set("storeId", this.storeId);
        this.filterColumns.set("couponChannel", ECouponChannel.STORE);
        FssUserCouponService.getUserCouponPaged(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssUserCoupon>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssUserCouponListPage extends FtComponent<FssUserCouponListPagePorp, FssUserCouponListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];

        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("receiveUserName", i18n("用户名"));
        columnProps.push(column2);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("receiveUser.userCode", i18n("用户编码"));
        columnProps.push(column1);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("storeCoupon.couponName", i18n("优惠券名称"));
        columnProps.push(column3);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("usedDatetimeText", i18n("使用时间"));
        columnProps.push(column7);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssUserCouponListPageState = FtState.pop("FssUserCouponListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.storeId = this.props.store.id;
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "FssUserCouponListPage",
            openUserCouponDetail: false,
            userCodes: "",
            coupon: new FssStoreCoupon(),
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onOpenDialog = this.onOpenDialog.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onChangeCoupon = this.onChangeCoupon.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("用户领取优惠券列表")} columnProps={this.state.columnProps}
                            adapter={this.state.tableAdapter}
                            toolbarNoSelect={this.getToolbarNoSelect()}
                            filterPanel={this.getFilterPanel()}
                         style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}/>
            {this.getUserCouponDetailDialog()}
        </div>
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <TextField label={i18n("用户名")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("receiveUserName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("receiveUserName")} style={{width: 200}}/>
            <TextField label={i18n("优惠券名称")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("storeCouponName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("storeCouponName")} style={{width: 200}}/>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <Button color={"primary"} variant={"outlined"} onClick={this.onOpenDialog}>
            {i18n("发放优惠券")}
        </Button>
    }

    onOpenDialog() {
        this.setState({openUserCouponDetail: !this.state.openUserCouponDetail})
    }

    getUserCouponDetailDialog() {
        return <Dialog open={this.state.openUserCouponDetail} onClose={this.onOpenDialog}
                       PaperProps={{style: {width: 600}}}>
            <DialogTitle>{i18n("补发优惠券")}</DialogTitle>
            <DialogContent>
                <TextField id="userCodes" multiline required={true}
                           onChange={(event) => {
                               this.setState({userCodes: event.target.value ? event.target.value : ""});
                           }}
                           value={this.state.userCodes} label={i18n("用户编码,逗号分隔")}
                           type={"text"} style={{width: "100%"}}/>
                <FssStoreCouponComboBox label={i18n("优惠券")}
                                        storeId={this.props.store.id}
                                        onChangeCoupon={this.onChangeCoupon}
                                        selectedCoupon={this.state.coupon}/>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={this.onOpenDialog}>{i18n("取消")}</Button>
                <Button variant="contained" color="primary" onClick={this.onClickSave}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    onChangeCoupon(item?: FssStoreCoupon) {
        if (item != null) {
            this.setState({coupon: item});
        }
    }

    onClickSave() {
        if (this.state.userCodes === null || this.state.userCodes === "") {
            FtSnackbar.showText(i18n("请输入用户编码"));
            return;
        }
        if (this.state.coupon.id === null || this.state.coupon.id === "") {
            FtSnackbar.showText(i18n("请选择优惠券"));
            return;
        }
        FtToast.showToastLoading(i18n("正在发放"));
        FssUserCouponService.save(ECouponChannel.STORE, this.state.coupon.id, this.state.userCodes, (mobileList: Array<string>) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("发放成功"));
        });
    }
}
