import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {TextField} from "@material-ui/core";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import FssUserAddress from "../model/FssUserAddress";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssUserService from "../service/FssUserService";
import FtRow from "../ui/FtRow";

export interface FssUserAddressListPageProp {
}

class FssUserAddressListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssUserAddress> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        // JtStoreAdminService
        FssUserService.getUserAddressList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssUserAddress>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssUserAddressListPage extends FtComponent<FssUserAddressListPageProp, FssUserAddressListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();

        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("contactName", i18n("收货人"));
        // column4.renderCell = (rowData: object) => {
        //     const userAddress: FssUserAddress = rowData as FssUserAddress;
        //     return <Link to={"/user/userAddressDetail/" + userAddress.id}>{userAddress.contactName}</Link>
        // };
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("mobile", i18n("电话"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("address", i18n("收货地址"));
        columnProps.push(column6);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssUserAddressListPageState = FtState.pop("FssUserAddressListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssUserAddressListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("地址列表")}
                         columnProps={this.state.columnProps}
                         adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 150}} onKeyDownEnter={this.onChangePageNo}/>
            <TextField id="userId" label={i18n("用户ID")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("userId", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("userId") as string} style={{width: 150}}/>
            <TextField id="mobile" label={i18n("收货人手机号")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("mobile", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("mobile") as string} style={{width: 150}}/>
            <TextField id="contactName" label={i18n("收货人")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("contactName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("contactName") as string} style={{width: 150}}/>
            <TextField id="address" label={i18n("收货地址")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("address", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("address") as string} style={{width: 150}}/>
            <TextField id="comment" label={i18n("备注")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("comment", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("comment") as string} style={{width: 150}}/>
        </FtRow>;
    }
}