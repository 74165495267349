import {ETwoStatus} from "./FtAbstractModel";

export default class FssCountryInfo {
    id: string = "";
    countryName: string = "";
    countryCode: string = "";
    currencyUnit: string = "";
    currencySymbol: string = "";
    decimalPoint: number = 0;
    exchangeRate: number = 0;
    defaultLanguage: string = "";
    supportLanguage: string = "";
    supportLanguageList: Array<string> = [];
    areaCode: string = "";
    createDatetime: string = "";
    status: ETwoStatus = ETwoStatus.NORMAL;
}
