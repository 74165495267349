import * as React from "react";
import {CSSProperties, HTMLAttributes} from "react";
import {AlignItemsProperty, FlexDirectionProperty, FlexWrapProperty, JustifyContentProperty} from "csstype";
import FtUtil from "../util/FtUtil";

export interface FtDivFlexProp extends HTMLAttributes<HTMLDivElement>{
    justifyContent?:JustifyContentProperty;
    alignItems?:AlignItemsProperty;
    flexWrap?:FlexWrapProperty;
    direction?:FlexDirectionProperty;
}

export default class FtDivFlex extends React.Component<FtDivFlexProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles:CSSProperties={};
        styles.display="flex";
        styles.marginTop=2;
        styles.marginBottom=2;
        styles.justifyContent=this.props.justifyContent?this.props.justifyContent:"flex-start";
        styles.alignItems=this.props.alignItems?this.props.alignItems:"center";
        styles.flexDirection=this.props.direction?this.props.direction:"row";
        if(styles.flexDirection=="row")
            styles.flexWrap=this.props.flexWrap?this.props.flexWrap:"wrap";

        if(this.props.style!=null)
            styles=FtUtil.mergeObject(styles,this.props.style);
        return <div className={"FtDivFlex"} style={styles} onClick={this.props.onClick}>
            {this.props.children}
        </div>;
    }
}