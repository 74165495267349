import * as React from "react";
import {ChangeEvent} from "react";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FssAppWebsite from "../model/FssAppWebsite";
import FasAppEmail from "../model/FasAppEmail";
import FtToast from "../ui/FtToast";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import FtUtil from "../util/FtUtil";
import FtSnackbar from "../ui/FtSnackbar";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";

class JtLoginWithRegisterSettingPageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
    fasAppEmail: FasAppEmail = new FasAppEmail();
}

export default class FssLoginWithRegisterSettingPage extends React.Component<any, JtLoginWithRegisterSettingPageState> {

    constructor(props: any) {
        super(props);
        let fasAppEmail = new FasAppEmail();
        this.state = {appWebsite: new FssAppWebsite(), fasAppEmail: fasAppEmail};
        this.loadData = this.loadData.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSaveAppInfo = this.onClickSaveAppInfo.bind(this);
        this.onClickSaveEmailInfo = this.onClickSaveEmailInfo.bind(this);
        this.onChangeAccountSetting = this.onChangeAccountSetting.bind(this);
        this.onChangeFasAppEmail = this.onChangeFasAppEmail.bind(this);
        this.onCheckFasAppEmail = this.onCheckFasAppEmail.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.loadAppEmail();
    }
    loadData () {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }


    loadAppEmail () {
        FtToast.showToastLoading();
        FssAppWebsiteService.getFasAppEmail((fasAppEmail: FasAppEmail) => {
            FtToast.closeToast();
            if (fasAppEmail) {
                this.setState({fasAppEmail: fasAppEmail,});
            }
        });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onChangeAccountSetting(event: ChangeEvent<HTMLInputElement>) {
        FtUtil.setProperty(this.state.appWebsite, event.target.id, event.target.checked);
        this.forceUpdate();
    }

    onChangeFasAppEmail(event: ChangeEvent<HTMLInputElement>) {
        FtUtil.setProperty(this.state.fasAppEmail, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSaveAppInfo() {
        // 先验证邮箱是否设置
        if (this.state.appWebsite.emailFlag &&
            (this.state.fasAppEmail == null || this.state.fasAppEmail.emailVerified == undefined || !this.state.fasAppEmail.emailVerified)) {
            FtSnackbar.showText(i18n("保存失败，请先设置Email信息并且通过验证"), 60000, "error")
            // this.state.appAccountSetting.emailFlag = false;
            // this.forceUpdate();
        } else {
            FtToast.showToastLoading(i18n("正在保存"));
            FssAppWebsiteService.saveAppWebsite(this.state.appWebsite,
                (appWebsite: FssAppWebsite) => {
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("保存成功！"));
                });
        }
    }

    onClickSaveEmailInfo() {
        // 保存email信息
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveFasAppEmail(this.state.fasAppEmail, (fasAppEmail: FasAppEmail) => {
            FtToast.closeToast();
            this.setState({fasAppEmail: fasAppEmail});
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    onCheckFasAppEmail() {
        let fasAppEmail = this.state.fasAppEmail;
        if (!FtUtil.isEmail(fasAppEmail.sendEmailAccount)) {
            FtSnackbar.showText(i18n("Email 格式错误"), 60000, "error");
            return;
        }
        if (fasAppEmail.sendEmailSmtpHost == "") {
            FtSnackbar.showText(i18n("当前只支持 Gmail邮箱，QQ邮箱，163邮箱"), 60000, "error");
            return;
        }
        FtToast.showToastLoading(i18n("正在验证邮箱"), 60000);
        FssAppWebsiteService.checkFasAppEmail(fasAppEmail, (fasAppEmail: FasAppEmail) => {
            FtToast.closeToast();
            this.setState({fasAppEmail: fasAppEmail}, ()=>{
                this.onClickSaveEmailInfo();
            });
        }, (error: string)=>{
            FtToast.closeToast();
            FtSnackbar.showText(i18n("邮箱验证失败"), 60000, "error");
        });
    }

    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarCenterNode={<Typography variant="h6">{i18n("注册与登录设置")}</Typography>}>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("短信 / Email注册设置")}</Typography>}>
                <FormControlLabel style={{width: "100%"}}
                                  control={<Checkbox id="mobileFlag" color="primary"
                                                     checked={this.state.appWebsite.mobileFlag}
                                                     onChange={this.onChangeAccountSetting}/>}
                                  label={"是否允许移动电话注册用户，如果勾选则网店允许用户使用手机号注册"}/>
                <FormControlLabel style={{width: "100%"}}
                                  control={<Checkbox id="emailFlag" color="primary"
                                                     checked={this.state.appWebsite.emailFlag}
                                                     onChange={this.onChangeAccountSetting}/>}
                                  label={"是否允许email注册用户，如果勾选则网店允许用户使用Email注册"}/>
                <Button variant="contained"
                        color="primary"
                        onClick={this.onClickSaveAppInfo}>{i18n("保存")}</Button>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("Email网关设置")}</Typography>}>
                <FtRow cellWidthS={"2,1"} justifyContent={"space-between"} style={{alignItems: "flex-start"}}>
                    <FtRow cellWidthS={"1"}>
                        <JtTextField id="sendPersonal"
                                     label={i18n("发件人名称")} value={this.state.fasAppEmail.sendPersonal}
                                     style={{width: "50%"}}
                                     onChange={this.onChangeFasAppEmail}/>
                        <JtTextField id="sendEmailAccount"
                                     label={i18n("Email账号")} value={this.state.fasAppEmail.sendEmailAccount}
                                     style={{width: "50%"}}
                                     onChange={(event)=>{
                                         let value = event.target.value;
                                         this.state.fasAppEmail.sendEmailAccount = value;
                                         // this.state.fasAppEmail.sslFlag = false;
                                         // this.state.fasAppEmail.port = "25";
                                         // this.state.fasAppEmail.sendEmailSmtpHost = "";
                                         // if (value && value.indexOf("@gmail.com") > -1) {
                                         //     this.state.fasAppEmail.sendEmailSmtpHost = "smtp.gmail.com";
                                         //     this.state.fasAppEmail.sslFlag = true;
                                         //     this.state.fasAppEmail.port = "465";
                                         // } else if (value && value.indexOf("@qq.com") > -1) {
                                         //     this.state.fasAppEmail.sendEmailSmtpHost = "smtp.qq.com";
                                         // } else if (value && value.indexOf("@163.com") > -1) {
                                         //     this.state.fasAppEmail.sendEmailSmtpHost = "smtp.163.com";
                                         // }
                                         this.forceUpdate();
                                     }}/>
                        <JtTextField id="sendEmailSmtpHost"
                                     label={i18n("SMTP服务器")} value={this.state.fasAppEmail.sendEmailSmtpHost}
                                     style={{width: "50%"}}
                                     onChange={(event)=>{
                                         this.state.fasAppEmail.sendEmailSmtpHost = event.target.value;
                                         this.forceUpdate();
                                     }}/>
                        <JtTextField id="port"
                                     label={i18n("Port")} value={this.state.fasAppEmail.port}
                                     style={{width: "50%"}}
                                     onChange={(event)=>{
                                         this.state.fasAppEmail.port = event.target.value
                                         this.forceUpdate();
                                     }}/>
                        <FormControlLabel style={{width: "100%"}}
                                          control={<Checkbox id="sslFlag" color="primary"
                                                             checked={this.state.fasAppEmail.sslFlag}
                                                             onChange={(event)=>{
                                                                 this.state.fasAppEmail.sslFlag = event.target.checked
                                                                 this.forceUpdate();
                                                             }}/>}
                                          label={"安全链接"}/>
                        <JtTextField id="sendEmailSmtpName"
                                     label={i18n("SMTP用户名")} value={this.state.fasAppEmail.sendEmailSmtpName}
                                     style={{width: "50%"}}
                                     onChange={(event)=>{
                                         this.state.fasAppEmail.sendEmailSmtpName = event.target.value;
                                         this.forceUpdate();
                                     }}/>
                        <JtTextField id="sendEmailPassword"
                                     label={i18n("Email密码")} value={this.state.fasAppEmail.sendEmailPassword}
                                     style={{width: "50%"}}
                                     onChange={this.onChangeFasAppEmail}/>
                        <Button variant="contained"
                                color="primary"
                                onClick={this.onCheckFasAppEmail}>{i18n("保存")}</Button>
                    </FtRow>
                    {/*<List style={{marginTop: -30}}*/}
                    {/*      subheader={<ListSubheader component="div" id="nested-list-subheader">配置帮助：</ListSubheader>}>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText primary={<Typography>*/}
                    {/*            {i18n("Gmail帮助文档：")}*/}
                    {/*            <a href={"https://support.google.com/mail/?p=BadCredentials"} target={"_blank"}>{i18n("点击查看")}</a>*/}
                    {/*        </Typography>} />*/}
                    {/*    </ListItem>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText primary={<Typography>*/}
                    {/*            {i18n("QQ邮箱帮助文档：")}*/}
                    {/*            <a href={"http://service.mail.qq.com/cgi-bin/help?subtype=1&&id=28&&no=1001256"} target={"_blank"}>{i18n("点击查看")}</a>*/}
                    {/*        </Typography>} />*/}
                    {/*    </ListItem>*/}
                    {/*    <ListItem>*/}
                    {/*        <ListItemText primary={<Typography>*/}
                    {/*            {i18n("163邮箱请直接使用邮箱登录密码")}*/}
                    {/*        </Typography>} />*/}
                    {/*    </ListItem>*/}
                    {/*</List>*/}
                </FtRow>
            </FtPanel>
        </FtPage>;
    }
}