import FssHttpService from "./FssHttpService";

export default class FssAliyunService {

    static getAliyunSsoToken(successFunction: (result: AliyunSTSResponse) => void): void {
        FssHttpService.get("/api/aliyun/getAliyunSsoToken", {}, successFunction, FssHttpService.handleError);
    }
}

export interface AliyunSTSResponse {
    accessKeyId: string;
    accessKeySecret: string;
    stsToken: string;
    bucket: string;
    endpoint: string;
    region: string;
    secure: boolean;
    domainName: string;
}