import * as React from "react";
import {Button, CardMedia, IconButton, MenuItem, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FssResetPasswordDialog from "./FssResetPasswordDialog";
import FtToast from "../ui/FtToast";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtPanel from "../ui/FtPanel";
import FtPage from "../ui/FtPage";
import FtToolbar from "../ui/FtToolbar";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtMessageDialog from "../ui/FtMessageDialog";
import {Language} from "../data/AppClientInfo";
import JtTextField from "../ui/JtTextField";
import {CropOriginal, PhonelinkSetup} from "@material-ui/icons";
import FssClientData from "../data/FssClientData";
import FssUpdateMobileDialog from "./FssUpdateMobileDialog";
import FasUser from "../model/FasUser";
import CloseIcon from "@material-ui/icons/Close";
import {grey} from "@material-ui/core/colors";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";
import FasUserService from "../service/FasUserService";

export interface FssMyUserDetailPageProp extends RouteComponentProps {
}

export class FssMyUserDetailPageState extends FtComponentState {
    user: FasUser = new FasUser();
    openMobileDialog: boolean = false;
    openEmailDialog: boolean = false;
}

export default class FssMyUserDetailPage extends FtComponent<FssMyUserDetailPageProp, FssMyUserDetailPageState> {
    jtResetPasswordDialog!: FssResetPasswordDialog;

    constructor(props: any) {
        super(props);
        this.state = {user: new FasUser(), openMobileDialog: false, openEmailDialog: false};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
    }

    componentDidMount(): void {
        FtToast.showToastLoading();
        FasUserService.getFasUserDetail((data: FasUser) => {
            FtToast.closeToast();
            this.setState({user: data});
        });
    }

    onChange(id: string, value: any) {
        FtUtil.setProperty(this.state.user, id, value);
        this.forceUpdate();
    }

    private onFinishUpload(fileUrl: string, fileName: string) {
        if (fileUrl != null && fileUrl.length > 0) {
            this.onChange("avatarUrl", fileUrl);
        }
    }

    render() {
        return <FtPage>
            <FtToolbar position={"sticky"} centerNode={<Typography variant={"h6"}>{i18n("我的资料")}</Typography>}/>
            <FtPanel elevation={3}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"2,10"} cellHorizontalSpace={8} maxRowWidth={"l"}>
                        {this.getUploadComp()}
                        <FtGrid paddingVertical={0} paddingHorizontal={0}>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1"} cellHorizontalSpace={12}
                                   cellVerticalSpace={16}>
                                <JtTextField id="nickName"
                                             onChange={(event) => {
                                                 this.onChange("nickName", event.target.value);
                                             }}
                                             value={this.state.user.nickName}
                                             label={i18n("昵称")}
                                             type={"text"}
                                             required={true} style={{width: "100%"}}/>
                                <JtTextField id="createDatetimeText"
                                             value={FssClientData.formatDatetime(this.state.user.createDatetime)}
                                             label={i18n("创建日期")}
                                             disabled={true} style={{width: "100%"}}/>
                            </FtRow>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1"} cellHorizontalSpace={12}
                                   cellVerticalSpace={16}>
                                <JtTextField id="mobile"
                                             value={this.state.user.mobile}
                                             label={i18n("手机号")} type={"phone"} disabled={true}
                                             required={true} style={{width: "100%"}}
                                             endAdornment={<IconButton onClick={() => {
                                                 this.setState({openMobileDialog: true});
                                             }}><PhonelinkSetup/></IconButton>}/>
                                <JtTextField id="defaultLanguage" select label={i18n("默认语言")} style={{width: "100%"}}
                                             value={this.state.user.defaultLanguage}
                                             onChange={(event) => {
                                                 this.onChange("defaultLanguage", event.target.value);
                                             }}>
                                    <MenuItem value={Language.en_CA}>{"English"}</MenuItem>
                                    <MenuItem value={Language.zh_CN}>{"简体中文"}</MenuItem>
                                    <MenuItem value={Language.zh_TW}>{"繁体中文"}</MenuItem>
                                </JtTextField>
                            </FtRow>
                        </FtGrid>
                    </FtRow>
                    <FssUpdateMobileDialog open={this.state.openMobileDialog}
                                           updateMobile={mobile => this.onChange("mobile", mobile)}
                                           handleClose={() => {
                                               this.setState({openMobileDialog: false})
                                           }}/>
                </FtGrid>
                <FtGrid paddingVertical={32}>
                    <FtRow cellHorizontalSpace={16} justifyContent={"center"}>
                        <Button variant={"outlined"} onClick={this.onClickBack}>{i18n("返回")}</Button>
                        <Button variant={"outlined"} onClick={this.onClickResetPassword}>{i18n("修改密码")}</Button>
                        <Button variant={"contained"} color={"primary"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FssResetPasswordDialog ref={(element: any) => {
                this.jtResetPasswordDialog = element;
            }}/>
        </FtPage>;
    }

    private onClickSave() {
        const message: string = "";
        if (message.length > 0) {
            FtMessageDialog.dialog.openDialog(message);
        } else {
            FtToast.showToastLoading(i18n("正在保存"));
            FasUserService.saveFasUserDetail(this.state.user, (data: FasUser) => {
                FtToast.closeToast();
                this.setState({user: data}, ()=>{
                    FssClientData.getData().webSession.avatarUrl = data.avatarUrl;
                    this.forceUpdate();
                });
            });
        }
    }

    getUploadComp() {
        if (this.state.user.avatarUrl && this.state.user.avatarUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 120, height: 120}}>
                <CardMedia image={this.state.user.avatarUrl} style={{width: 120, height: 120}}
                           onClick={(event) => {
                               event.stopPropagation();
                           }}>
                </CardMedia>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {
                        this.onChange("avatarUrl", "");
                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 118, height: 118,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginal viewBox={"3 3 18 18"} style={{width: 40, height: 40}}/>
            </div>;
            return <FtFileUploadButtonToOss onFinish={this.onFinishUpload}
                                            buttonStyle={buttonStyle}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }

    private onClickBack() {
        this.props.history.goBack();
    }

    private onClickResetPassword() {
        this.jtResetPasswordDialog.show();
    }
}