import FtPagedList from "../model/FtPagedList";
import FtApiResponse from "../model/FtApiResponse";
import FssHttpService from "./FssHttpService";
import FssPaymentAccount from "../model/FssPaymentAccount";
import {ETwoStatus} from "../model/FtAbstractModel";

export default class FssPaymentAccountService extends FssHttpService {

    static getPaymentAccountList(successFunction: (productList: Array<FssPaymentAccount>) => void): void {
        FssHttpService.get("/api/setting/getPaymentAccountList", {}, successFunction, FssHttpService.handleError);
    }

    static getPaymentAccountPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                      pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssPaymentAccount>) => void): void {
        FssHttpService.search("/api/setting/getPaymentAccountPaged", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getPaymentAccountById(paymentAccountId: string, successFunction: (data: FssPaymentAccount) => void): void {
        FssHttpService.get("/api/setting/getPaymentAccountById", {paymentAccountId: paymentAccountId}, successFunction, FssHttpService.handleError);
    }

    static savePaymentAccount(paymentAccount: FssPaymentAccount, successFunction: (paymentAccount: FssPaymentAccount) => void) {
        FssHttpService.postJson("/api/setting/savePaymentAccount", paymentAccount, successFunction, FssHttpService.handleError);
    }

    static updateAccountStatus(paymentAccountIdArr: Array<string>, accountStatus: ETwoStatus, successFunction: (apiResponse: FtApiResponse) => void) {
        let params = {paymentAccountIdArr: paymentAccountIdArr, accountStatus: accountStatus,};
        FssHttpService.postFormData("/api/setting/updatePaymentAccountStatus", params, successFunction, FssHttpService.handleError);
    }
}
