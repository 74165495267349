import FssUser from "../model/FssUser";
import FssHttpService from "../service/FssHttpService";
import {DataType} from "../setting/SynDataPage";

export default class GrubmarketService{


    static synData(dataType:DataType,startDate:string,token:string,successFunction: (data:string) => void) {
        FssHttpService.get("/api/data/synData", {dataType:dataType,startDate:startDate,token:token}, successFunction, FssHttpService.handleError);
    }

}