import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssUserCoupon, {ECouponChannel} from "../marketing/FssUserCoupon";

export default class FssUserCouponService {

    static getUserCouponPaged(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                              pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssUserCoupon>) => void): void {
        FssHttpService.search("/api/userCoupon/getUserCouponPaged", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static save(couponChannel: ECouponChannel, couponId: string, userCodes: string, successFunction: (mobileList: Array<string>) => void): void {
        let params = {couponChannel: couponChannel, couponId: couponId, userCodes: userCodes};
        FssHttpService.postFormData("/api/userCoupon/save", params, successFunction, FssHttpService.handleError);
    }

}
