import * as React from "react";
import {
    FormControl,
    FormControlLabel, FormLabel, Radio, RadioGroup,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import GrubmarketService from "../service/GrubmarketService";
import FtTextFieldDate from "../ui/FtTextFieldDate";
import FtToolbar from "../ui/FtToolbar";

class JtLoginWithRegisterSettingPageState {
    dataType: DataType = DataType.user;
    startDate?: string = "";
    token: string = "1b9970a9-96ae-4a5c-975d-292d91dcef50";
}

export enum DataType {
    user = "user",
    userPoints = "userPoints",
    product = "product"
}

export default class SynDataPage extends React.Component<any, JtLoginWithRegisterSettingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {dataType: DataType.user, startDate: "", token: "1b9970a9-96ae-4a5c-975d-292d91dcef50"};
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    componentDidMount() {

    }


    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        if (this.state.startDate == "") {
            FtSnackbar.showText(i18n("开始时间不能空！"));
            return;
        }
        FtToast.showToastLoading(i18n("后台正在同步,不要重复提交"));
        GrubmarketService.synData(this.state.dataType, this.state.startDate || "", this.state.token,
            (data: string) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n(data));
            });
    }


    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarCenterNode={<Typography variant="h6">{i18n("同步旧数据")}</Typography>}>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}>{i18n("返回")}</Button>
                <Button variant="contained"
                        color="primary"
                        onClick={this.onClickSave}>{i18n("同步")}</Button>
            </div>}>
            </FtToolbar>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={""}>
                <FtRow cellWidthS={"1"}>
                    <JtTextField id="shortName" label={i18n("token")}
                                 value={this.state.token}
                                 style={{width: "100%"}} onChange={(event) => {
                        this.setState({token: event.target.value})
                    }
                    }/>
                    <FormControl>
                        <FormLabel>数据类型</FormLabel>
                        <RadioGroup row value={this.state.dataType ? this.state.dataType : ""}
                                    onChange={(event) => {
                                        this.setState({dataType: event.target.value as DataType})
                                    }}>
                            <FormControlLabel value={DataType.user} control={<Radio/>} label={"用户数据"}/>
                            <FormControlLabel value={DataType.userPoints} control={<Radio/>} label={"用户积分"}/>
                            {/*<FormControlLabel value={DataType.product} control={<Radio/>} label={"商品数据"}*/}
                            {/*                  />*/}
                        </RadioGroup>
                    </FormControl>
                    <FtTextFieldDate id="startDate"
                                     label={i18n("开始时间")}
                                     value={this.state.startDate}
                                     InputLabelProps={{shrink: true,}}
                                     onChangeValue={(value) => {
                                         this.setState({startDate: value});
                                     }}/>
                    {/*<FtTextFieldDate id="endDate"*/}
                    {/*                 label={i18n("结束时间")} disabled={this.state.disabled}*/}
                    {/*                 value={this.state.endDate}*/}
                    {/*                 InputLabelProps={{shrink: true,}}*/}
                    {/*                 onChangeValue={(value) => {*/}
                    {/*                     if (this.state.startDate && value && this.state.startDate > value) {*/}
                    {/*                         this.setState({endDate: this.state.endDate});*/}
                    {/*                         FtMessageDialog.dialog.openDialog("结束时间不能小于开始时间");*/}
                    {/*                     } else {*/}
                    {/*                         this.setState({endDate: value});*/}
                    {/*                     }*/}
                    {/*                 }}/>*/}
                </FtRow>
            </FtPanel>
        </FtPage>;
    }
}