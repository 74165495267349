export class FssAdminSession{
    sellerId?:string;
    storeId?:string;
    roles?:string;
    rolesList?:Array<string>;
}
export class FssWebSession{
    loginSessionId?:string;
    userId?:string;
    appId?:string;
    signature?:string;
    dataString?:string;
    language?:string;
    adminSession?:FssAdminSession;
    isSellerAdmin:boolean=false;
    isPublisher:boolean=false;
    isAppWebsiteAdmin:boolean=false;
    loginTime:number=0;
    avatarUrl?:string;
}
