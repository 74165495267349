import FssHttpService from "./FssHttpService";
import FtPagedList from "../model/FtPagedList";
import FssStoreCategory from "../model/FssStoreCategory";

export default class FssStoreCategoryService {

    static getPagedList(filterColumns: Map<string, any>, pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssStoreCategory>) => void) {
        let searchKeyword: string = "";
        let sortPropertyName = null;
        let sortDirection = null;
        FssHttpService.search("/api/storeCategory/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }

    static save(data: FssStoreCategory, successFunction: (data: FssStoreCategory) => void): void {
        FssHttpService.postJson("/api/storeCategory/save", data, successFunction);
    }

    static delete(categoryId: string, successFunction: (data: string) => void): void {
        FssHttpService.postFormData("/api/storeCategory/delete", {categoryId: categoryId}, successFunction);
    }
}
