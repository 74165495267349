import * as React from "react";
import Button from "@material-ui/core/Button";
import FtToolbar from "../ui/FtToolbar";
import grey from "@material-ui/core/colors/grey";
import {i18n} from "../util/I18n";
import FssStoreService from "../service/FssStoreService";
import FssStore from "../model/FssStore";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FssClientData from "../data/FssClientData";
import {
    CardMedia,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import FtToast from "../ui/FtToast";
import {ETwoStatus} from "../model/FtAbstractModel";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import CloseIcon from '@material-ui/icons/Close';
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import FssSeller from "../model/FssSeller";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";
import FtSnackbar from "../ui/FtSnackbar";
import FssCountryInfoService from "../service/FssCountryInfoService";
import FssCountryInfo from "../model/FssCountryInfo";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";

interface FssStoreDetailPageProp {
    store: FssStore;
    onChange: (name: string, value: string) => void;
}

export class FssStoreDetailPageState {
    countryInfoValues: Array<string> = [];
    countryInfoLabels: Array<string> = [];
}

export default class FssStoreDetailPage extends React.Component<FssStoreDetailPageProp, FssStoreDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {countryInfoValues: [], countryInfoLabels: []};
        this.onChange = this.onChange.bind(this);
        this.loadWebsiteRegion = this.loadWebsiteRegion.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
    }

    componentDidMount(): void {
        this.loadWebsiteRegion();
    }

    loadWebsiteRegion() {
        FssCountryInfoService.getAvailableList((countryInfoList: Array<FssCountryInfo>) => {
            FtToast.closeToast();
            let countryInfoValues: Array<string> = [];
            let countryInfoLabels: Array<string> = [];
            if (countryInfoList != null && countryInfoList.length > 0) {
                countryInfoList.forEach((countryInfo: FssCountryInfo) => {
                    countryInfoValues.push(countryInfo.id);
                    countryInfoLabels.push(countryInfo.countryName);
                })
            }
            this.setState({countryInfoValues: countryInfoValues, countryInfoLabels: countryInfoLabels});
        });
    }

    onChange(name: string, value: string) {
        this.props.onChange(name, value);
    }

    onClickSave() {
        FtToast.showToastLoading("loading");
        FssStoreService.saveStore(this.props.store, (store: FssStore) => {
            FtToast.closeToast();
            FtSnackbar.showText("保存成功");
        })
    }

    private onFinishUpload(fileUrl: string) {
        if (fileUrl != null && fileUrl.length > 0) {
            this.onChange("logoUrl", fileUrl)
        }
    }

    render() {
        let isEdit = this.props.store && this.props.store.id && this.props.store.id.length > 0;
        return <div>
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button color="primary" variant={"outlined"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                       </FtRow>}/>
            <FtPanel panelHeader={i18n("分站基本信息")} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                        <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"}>
                            <JtTextField id="storeName" label={i18n("分站名称")}
                                         value={this.props.store.storeName}
                                         style={{width: "100%"}}
                                         onChange={(event) => {
                                             this.onChange("storeName", event.target.value)
                                         }}/>
                            {isEdit && <JtTextField id="createDatetime" label={i18n("创建时间")}
                                                    value={FssClientData.formatDatetime(this.props.store.createDatetime)}
                                                    style={{width: "100%"}} disabled={true}/>}
                        </FtRow>
                        <FormControl>
                            <FormLabel>服务地区</FormLabel>
                            <FtCheckboxGroup row={true}
                                             values={this.state.countryInfoValues}
                                             labels={this.state.countryInfoLabels}
                                             checkedValues={this.props.store.countryInfoIdList}
                                             onCheck={(checkedValue: Array<string>) => {
                                                 let countryInfoIds = "";
                                                 if (checkedValue != null && checkedValue.length > 0) {
                                                     checkedValue.forEach((id: string) => {
                                                         countryInfoIds += "," + id;
                                                     })
                                                 }
                                                 if (countryInfoIds.length > 0) {
                                                     this.props.store.countryInfoIds = countryInfoIds.substring(1);
                                                 }
                                                 this.props.store.countryInfoIdList = checkedValue;
                                                 this.forceUpdate();
                                             }}/>
                        </FormControl>
                        <FormControl disabled={this.countryInfoIsNull()}>
                            <FormLabel>状态</FormLabel>
                            <RadioGroup row value={this.countryInfoIsNull() ? ETwoStatus.DISABLED : this.props.store.status}
                                        onChange={(event) => {
                                            this.onChange("status", event.target.value)
                                        }}>
                                <FormControlLabel value={ETwoStatus.NORMAL} control={<Radio/>} label={"正常"}/>
                                <FormControlLabel value={ETwoStatus.DISABLED} control={<Radio/>} label={"停用"}/>
                            </RadioGroup>
                        </FormControl>
                        {this.getUploadComp()}
                        <JtTextField id="defaultSeller.fullName" label={i18n("默认店铺")}
                                     value={this.getDefauleSellerName(this.props.store.defaultSeller)}
                                     style={{width: "100%"}} disabled={true}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>
    }

    countryInfoIsNull() {
        return this.props.store.countryInfoIdList == null || this.props.store.countryInfoIdList.length == 0;
    }

    getDefauleSellerName(seller?: FssSeller): string {
        let result = "暂未设置默认店铺";
        if (seller) {
            if (seller.fullName && seller.fullName.trim().length > 0) {
                result = seller.fullName;
            } else if (seller.shortName && seller.shortName.trim().length > 0) {
                result = seller.shortName;
            }
        }
        return result;
    }

    getUploadComp() {
        if (this.props.store.logoUrl && this.props.store.logoUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 100, height: 100}}>
                <CardMedia image={this.props.store.logoUrl} style={{height: 100, width: 100}}
                           onClick={(event) => {
                               event.stopPropagation();
                               this.onClickZoomOut(this.props.store.logoUrl);
                           }}>
                </CardMedia>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {
                        this.onChange("logoUrl", "")
                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 98, height: 98,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginalIcon viewBox={"3 3 18 18"} style={{width: 35, height: 35}}/>
                <Typography>添加LOGO</Typography>
            </div>;
            return <FtFileUploadButtonToOss onFinish={this.onFinishUpload}
                                            buttonStyle={buttonStyle}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }

    onClickZoomOut(mediaUrl?: string) {
        if (mediaUrl && mediaUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(mediaUrl, "image");
        }
    }
}
