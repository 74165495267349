import * as React from "react";
import FtRow from "../ui/FtRow";
import {CardHeader, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import Public from '@material-ui/icons/Public';
import FtPanel from "../ui/FtPanel";
import {grey} from "@material-ui/core/colors";
import {i18n} from "../util/I18n";
import PeopleIcon from '@material-ui/icons/People';
import ScoreIcon from '@material-ui/icons/Score';
import {Category, CreditCard, MonetizationOn, Share, ShowChart} from "@material-ui/icons";
import {CSSProperties} from "react";

class FssSettingMainPageState {
    statisticsList: Array<number> = [];
    statisticsTextList: Array<string> = [];
    updateList: Array<any> = [];
}

export default class FssSettingMainPage extends React.Component<any, FssSettingMainPageState> {

    constructor(props: any) {
        super(props);
        this.state = {statisticsList: [], statisticsTextList: [], updateList: []};
    }

    render() {
        let iconSize = 40;
        let cardStyle: CSSProperties = {cursor: "pointer", width: 220, marginRight: 8, marginBottom: 8}
        return <FtPanel elevation={0} marginVertical={0} marginHorizontal={0} style={{backgroundColor: grey[200]}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Card style={cardStyle}>
                    <CardHeader onClick={() => this.props.history.push("/countryInfoList")}
                                avatar={<Public style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("国家信息管理")} subheader={i18n("")}/>
                </Card>
                <Card style={cardStyle}>
                    <CardHeader onClick={() => this.props.history.push("/domainLoginSetting")}
                                avatar={<PeopleIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("注册与登录设置")} subheader={i18n("")}/>
                </Card>
                <Card style={cardStyle}>
                    <CardHeader onClick={() => this.props.history.push("/scoreSetting")}
                                avatar={<ScoreIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("积分规则")} subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle}>
                    <CardHeader onClick={() => this.props.history.push("/paymentAccountList")}
                                avatar={<CreditCard style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("支付网关设置")} subheader={i18n("")}/>
                </Card>
                <Card style={cardStyle}>
                    <CardHeader onClick={() => this.props.history.push("/balanceRuleList")}
                                avatar={<MonetizationOn style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("余额充值规则")} subheader={i18n("")}/>
                </Card>
            </div>
        </FtPanel>
    }
}
