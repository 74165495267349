import * as React from "react";
import {ReactNode} from "react";
import FtToast from "../../ui/FtToast";
import {Breadcrumbs, Button, Link, Typography} from "@material-ui/core";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../../ui/FtDataTable";
import {i18n} from "../../util/I18n";
import FssPaymentAccount, {EBalanceRechargeDefault} from "../../model/FssPaymentAccount";
import FssPaymentAccountService from "../../service/FssPaymentAccountService";
import FtPagedList from "../../model/FtPagedList";
import {ETwoStatus} from "../../model/FtAbstractModel";
import {ArrowBack} from "@material-ui/icons";
import {RouteComponentProps} from "react-router-dom";

interface FssPaymentAccountListPageProp extends RouteComponentProps {

}

class FssPaymentAccountListPageState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssPaymentAccount> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPaymentAccountService.getPaymentAccountPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssPaymentAccount>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssPaymentAccountListPage extends React.Component<FssPaymentAccountListPageProp, FssPaymentAccountListPageState> {
    constructor(props: any) {
        super(props);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);

        const tableAdapter = new JtTableAdapter();
        tableAdapter.filterColumns.set("storeId", EBalanceRechargeDefault.BALANCE_RECHARGE_STORE_ID);
        tableAdapter.filterColumns.set("sellerId", EBalanceRechargeDefault.BALANCE_RECHARGE_SELLER_ID);
        tableAdapter.onUpdateData = (() => {
            this.forceUpdate();
        });
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("paymentAccountName", "名称");
        column0.renderCell = (rowData: object) => {
            const paymentAccount: FssPaymentAccount = rowData as FssPaymentAccount;
            return <Link onClick={() => this.props.history.push("/paymentAccountDetail/" + paymentAccount.id)}
                         style={{cursor: "pointer"}}>
                {paymentAccount.paymentAccountName}
            </Link>
        };
        columnProps.push(column0);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("paymentProvider", i18n("支付机构"));
        columnProps.push(column2);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("paymentMethod", i18n("支付方式"));
        columnProps.push(column5);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("paymentChannel", i18n("支付渠道"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("accountStatus", i18n("状态"));
        column4.renderCell = (rowData: object) => {
            const paymentAccount: FssPaymentAccount = rowData as FssPaymentAccount;
            if (paymentAccount.accountStatus == ETwoStatus.NORMAL) {
                return "正常";
            } else {
                return "停用";
            }
        };
        columnProps.push(column4);

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps};

        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    render() {
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={this.onCancelClick} style={{cursor: "pointer"}}>
                    <div style={{display: "flex"}}>
                        <div style={{maxHeight: 24}}><ArrowBack style={{width: 20}}/></div>
                        <span>设置</span>
                    </div>
                </Link>
                <Typography color="textPrimary">
                    支付网关列表
                </Typography>
            </Breadcrumbs>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}>
            </FtDataTable>
        </div>;
    }

    getToolbarNoSelect(): ReactNode {
        return <Button variant={"outlined"} color={"primary"}
                       onClick={() => this.props.history.push("/paymentAccountDetail")}>
            {i18n("新增")}
        </Button>
    }
}
