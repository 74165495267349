import * as React from "react";
import {CSSProperties, HTMLAttributes, ReactNode} from "react";
import Typography from "@material-ui/core/Typography";
import {JustifyContentProperty} from "csstype";
import {grey} from "@material-ui/core/colors";
import FtUtil from "../util/FtUtil";
import FtRow from "./FtRow";
import AppClientInfo from "../data/AppClientInfo";

export interface FtAppBarProp extends HTMLAttributes<HTMLDivElement>{
    title?: string;
    leftNode?: React.ReactNode;
    centerNode?: React.ReactNode;
    rightNode?: React.ReactNode;
    position?: "" | 'fixedBottom' | 'fixedTop' | 'absolute' | 'stickyTop' | 'stickyBottom' | 'static' | 'relative';
    left?: string | number;
    right?: string | number;
    top?: number;
    bottom?: number;
    background?:"default"|"transparent"
    justifyContent?:JustifyContentProperty;
    cellWidthM?: string;
    cellWidthS?: string;
    cellWidthL?: string;

}

export default class FtAppBar extends React.Component<FtAppBarProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.getChildren=this.getChildren.bind(this);
    }

    render() {
        let className:string="FtAppBar";
        let styles: CSSProperties = {};
        styles.left = this.props.left;
        styles.right = this.props.right;
        styles.display="flex";
        styles.justifyContent="space-between";
        styles.alignItems="center";
        styles.zIndex=100;
        styles.paddingTop=0;
        styles.paddingBottom=0;
        styles.minHeight=47;
        styles.width="100%";
        styles.boxSizing="border-box";
        if (this.props.position == null || this.props.position == "") {

        } else if (this.props.position == "fixedTop") {
            styles.position = "fixed";
            if(this.props.top!=null&&this.props.top!=0) {
                styles.top = this.props.top+AppClientInfo.clientInfo.insetTop;
            }else {
                styles.paddingTop=AppClientInfo.clientInfo.insetTop;
                styles.minHeight=47+AppClientInfo.clientInfo.insetTop;
                styles.top = 0;
            }
            styles.backgroundColor="white";
            styles.borderBottomWidth=1;
            styles.borderBottomColor=grey[400];
            styles.borderBottomStyle="solid";
            className="FtAppBar_fixedTop";
        } else if (this.props.position == "fixedBottom") {
            styles.position = "fixed";
            if(this.props.bottom!=null&&this.props.bottom!=0) {
                styles.bottom = this.props.bottom + AppClientInfo.clientInfo.insetBottom;
            }else {
                styles.paddingBottom=AppClientInfo.clientInfo.insetBottom;
                styles.minHeight=47+AppClientInfo.clientInfo.insetBottom;
                styles.bottom = 0;
            }
            styles.backgroundColor="white";
            styles.borderTopWidth=1;
            styles.borderTopColor=grey[400];
            styles.borderTopStyle="solid";
            className="FtAppBar_fixedBottom";
        } else if (this.props.position == "absolute") {
            styles.position = "absolute";
            styles.top = this.props.top
            styles.bottom = this.props.bottom;
        } else if (this.props.position == "stickyTop") {
            styles.position = "sticky";
            if(this.props.top) {
                styles.top = this.props.top+AppClientInfo.clientInfo.insetTop;
            }else {
                styles.marginTop=AppClientInfo.clientInfo.insetTop;
                styles.top = 0;
            }
            styles.backgroundColor="white";
            styles.borderBottomWidth=1;
            styles.borderBottomColor=grey[400];
            styles.borderBottomStyle="solid";
            className="FtAppBar_stickyTop";
        } else if (this.props.position == "stickyBottom") {
            styles.position = "sticky";
            if(this.props.bottom) {
                styles.bottom = this.props.bottom + AppClientInfo.clientInfo.insetBottom;
            }else {
                styles.marginBottom=AppClientInfo.clientInfo.insetBottom;
                styles.bottom = 0;
            }
            styles.backgroundColor="white";
            styles.borderTopWidth=1;
            styles.borderTopColor=grey[400];
            styles.borderTopStyle="solid";
            className="FtAppBar_stickyBottom";
        } else if (this.props.position == "static") {
            styles.position = "static";
            if(this.props.top) {
                styles.top = this.props.top+AppClientInfo.clientInfo.insetTop;
            }
            if(this.props.bottom) {
                styles.bottom = this.props.bottom + AppClientInfo.clientInfo.insetBottom;
            }

        } else if (this.props.position == "relative") {
            styles.position = "relative";
            styles.top = this.props.top;
            styles.bottom = this.props.bottom;
        }
        if(this.props.background!=null&&this.props.background=="transparent") {
            styles.backgroundColor="";
            styles.borderBottom="";
            styles.borderTop="";
        }
        if(this.props.justifyContent&&this.props.justifyContent.length>0)
            styles.justifyContent=this.props.justifyContent;
        styles=FtUtil.mergeObject(styles,this.props.style);
        return <div style={styles} className={className}>
            {this.getChildren()}
        </div>;
    }
    private getChildren():ReactNode{
        if(this.props.children)
            return this.props.children;
        else{
            let cellWidth="2,6,2";
            return <FtRow style={{width:"100%",textAlign:"center"}} cellWidthS={this.props.cellWidthS?this.props.cellWidthS:cellWidth}
                          cellWidthM={this.props.cellWidthM?this.props.cellWidthM:cellWidth}
                          cellWidthL={this.props.cellWidthL?this.props.cellWidthL:cellWidth}
                        cellHorizontalAlign={"justify"} cellVerticalSpace={0}>
                {this.props.leftNode != null ? this.props.leftNode : <div>&nbsp;</div>}
                {this.props.centerNode != null ? this.props.centerNode : <Typography>{this.props.title}</Typography>}
                {this.props.rightNode != null ? this.props.rightNode : <div>&nbsp;</div>}
            </FtRow>
        }
    }
}