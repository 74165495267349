import FssProductSetting from "./FssProductSetting";

export enum EStatus {
    NORMAL = "NORMAL", NOT_IN_SERVICE = "NOT_IN_SERVICE", DISABLED = "DISABLED"

}

export enum EAppVisibility {
    PUBLIC = "PUBLIC", REGISTER_USER = "REGISTER_USER", USER_LEVEL_1 = "USER_LEVEL_1"
}

export enum EUserHandleFeeType {
    NONE = "NONE",//不收取
    PAYMENT_FEE = "PAYMENT_FEE",//只收取支付手续费
    PAYMENT_AND_TRANSACTION = "PAYMENT_AND_TRANSACTION",//收取支付手续费+交易手续费
    FIXED_PERCENT = "FIXED_PERCENT",//固定百分比金额
    FIXED_AMOUNT = "FIXED_AMOUNT"//固定金额
}

export default class FssAppWebsite {
    createDatetime?: Date
    defaultStoreId?: string;
    description?: string;
    id?: string;
    isPlatform?: boolean;
    isRegional?: boolean;
    language?: string;
    serialVersionUID?: string;
    status?: EStatus;
    timeZone?: string;
    userDomainName: string = "";
    websiteLogo?: string;
    websiteName?: string;
    googleMapKey?: string;
    supportLanguages: Array<string> = [];
    appVisibility: EAppVisibility = EAppVisibility.PUBLIC;
    userHandleFeeType: EUserHandleFeeType = EUserHandleFeeType.NONE;
    userHandleFee: number = 0;
    userHandleFeeDesc: string = "";
    allowRegisterPhoneCountryISOCodes: Array<string> = [];
    emailFlag: boolean = false;
    mobileFlag: boolean = false;
    facebookSetting: FssFacebookSetting = new FssFacebookSetting();
    websiteShareDomainName: string = "";
    notificationRule: ENotificationRule = ENotificationRule.STOP;
    googleGatrackingId: string = "";
    googleGaFlag: boolean = false;
    productSetting: FssProductSetting = new FssProductSetting();
    wechatLoginFlag: boolean = false;
    facebookLoginFlag: boolean = false;
}

export enum ENotificationRule {
    STOP = "STOP",
    MOBILE_AND_EMAIL = "MOBILE_AND_EMAIL",
    MOBILE_FIRST = "MOBILE_FIRST",
    EMAIL_FIRST = "EMAIL_FIRST",
}

export class FssGoogleSetting {
    gaTrackingId: string = "";
    gaFlag: boolean = false;
}

export class FssFacebookSetting {
    app_id: string = "";
    fbLoginFlag: boolean = false;
}
