import * as React from "react";
import {InputAdornment, Typography} from "@material-ui/core";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import Button from "@material-ui/core/Button";
import FssAppWebsite from "../model/FssAppWebsite";
import FtToast from "../ui/FtToast";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import FtSnackbar from "../ui/FtSnackbar";

class FssShareDomainNamePageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
}

export default class FssShareDomainNamePage extends React.Component<any, FssShareDomainNamePageState> {

    constructor(props: any) {
        super(props);
        this.state = {appWebsite: new FssAppWebsite()};
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData () {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite, (appWebsite: FssAppWebsite) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
            this.setState({appWebsite: appWebsite});
        });
    }

    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}>
            <FtPanel elevation={3} style={paperStyle} panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("分享域名设置")}</Typography>}>
                <FtRow cellWidthS={"1"}>
                    <JtTextField id="name" style={{width: "50%"}}
                                 value={this.state.appWebsite.websiteShareDomainName}
                                 startAdornment={<InputAdornment position="start" style={{marginRight: -6}}>https://</InputAdornment>}
                                 onChange={(event)=>{
                                     this.state.appWebsite.websiteShareDomainName = event.target.value + "";
                                     this.forceUpdate();
                                 }}/>
                    <Button variant="contained" color="primary" onClick={this.onClickSave}>
                        {i18n("保存")}
                    </Button>
                </FtRow>
            </FtPanel>
        </FtPage>;
    }
}