import * as React from "react";
import {ChangeEvent} from "react";
import {i18n} from "../util/I18n";
import {Button, TextField, Typography} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FtUtil from "../util/FtUtil";
import MenuItem from "@material-ui/core/MenuItem";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FtPanel from "../ui/FtPanel";
import FtPage from "../ui/FtPage";
import FssAppWebsite from "../model/FssAppWebsite";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import {EProductDisplayType} from "../model/FssProductSetting";
import FtToolbar from "../ui/FtToolbar";
import FtGrid from "../ui/FtGrid";

interface FssProductSettingPageProp {
}

class FssProductSettingPageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
}

export default class FssProductSettingPage extends React.Component<FssProductSettingPageProp, FssProductSettingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {appWebsite: new FssAppWebsite()};
        this.onClickSave = this.onClickSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.appWebsite, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite, (appWebsite: FssAppWebsite) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
            this.setState({appWebsite: appWebsite});
        });
    }

    render() {
        return <div>
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button color="primary" variant={"outlined"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                       </FtRow>}>
            </FtToolbar>
            <FtPanel style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}} panelHeader={i18n("商品库存和销量显示")}>
                <FtGrid>
                    <FtRow cellWidthS={"1,1,1"}>
                        <TextField select value={this.state.appWebsite.productSetting.saleDisplayType}
                                   id={"appProductSetting.saleDisplayType"}
                                   label={i18n("商品销量显示")}
                                   style={{width: "100%", textAlign: "left"}}
                                   onChange={(event: any) => {
                                       this.state.appWebsite.productSetting.saleDisplayType = event.target.value;
                                       this.forceUpdate();
                                   }}>
                            <MenuItem value={EProductDisplayType.NO_DISPLAY}
                                      key={EProductDisplayType.NO_DISPLAY}>{i18n("不显示")}</MenuItem>
                            <MenuItem value={EProductDisplayType.DETAIL}
                                      key={EProductDisplayType.DETAIL}>{i18n("显示准确数字")}</MenuItem>
                            <MenuItem value={EProductDisplayType.ESTIMATION}
                                      key={EProductDisplayType.ESTIMATION}>{i18n("显示大概数字")}</MenuItem>
                        </TextField>
                        <TextField select value={this.state.appWebsite.productSetting.inventoryDisplayType}
                                   id={"inventoryDisplayType"}
                                   label={i18n("商品库存显示")}
                                   style={{width: "100%", textAlign: "left"}}
                                   onChange={(event: any) => {
                                       this.state.appWebsite.productSetting.inventoryDisplayType = event.target.value;
                                       this.forceUpdate();
                                   }}>
                            <MenuItem value={EProductDisplayType.NO_DISPLAY}
                                      key={EProductDisplayType.NO_DISPLAY}>{i18n("不显示")}</MenuItem>
                            <MenuItem value={EProductDisplayType.DETAIL}
                                      key={EProductDisplayType.DETAIL}>{i18n("显示准确数字")}</MenuItem>
                            <MenuItem value={EProductDisplayType.ESTIMATION}
                                      key={EProductDisplayType.ESTIMATION}>{i18n("显示大概数字")}</MenuItem>
                        </TextField>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>;
    }
}
