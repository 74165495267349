import {ETwoStatus} from "../model/FtAbstractModel";

export enum EPaymentProvider {
    PAYNICORN = "PAYNICORN",
}

export enum EPaymentChannel {
    PAYNICORN = "PAYNICORN",
}

export enum EPaymentMethod {
    PAYNICORN = "PAYNICORN"
}

export enum EAccountStatus {
    NORMAL = 0,
    DISABLED = 99,
}

export enum EVerifyType {
    VERIFY_CVD = "VERIFY_CVD",
    VERIFY_AVS = "VERIFY_AVS"
}

export enum EBalanceRechargeDefault {
    BALANCE_RECHARGE_STORE_ID = "BALANCE_RECHARGE_STORE_ID",
    BALANCE_RECHARGE_SELLER_ID = "BALANCE_RECHARGE_SELLER_ID"
}

export default class FssPaymentAccount {
    id: string = "";
    paymentAccountName: string = "";
    description?: string;
    createDatetimeText: string = "";
    accountStatus?: ETwoStatus;
    paymentMethod?: EPaymentMethod;
    paymentMethodText: string = "";
    paymentProvider?: EPaymentProvider;
    paymentChannel?: EPaymentChannel;
    verifyType: EVerifyType = EVerifyType.VERIFY_CVD;
    paymentProviderKey0: string = "";
    paymentProviderKey1: string = "";
    paymentProviderKey2: string = "";
    paymentProviderKey3: string = "";
    accountOrder?: number;
    storeId: string = "";
    sellerId: string = "";
}
