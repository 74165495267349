import {FtAbstractModel} from "./FtAbstractModel";

export enum EStatus {
    NORMAL = "NORMAL", DISABLED = "DISABLED"
}

export default class FasUser extends FtAbstractModel {
    email: string = "";
    emailVerified: boolean = false;
    mobile: string = "";
    mobileVerified: boolean = false;
    createDatetime: string = "";
    userStatus?: EStatus;
    nickName: string = "";
    avatarUrl: string = "";
    password: string = "";
    userLevel: number = 0;
    defaultLanguage: string = "";
    publisher: string = "";
    userCode: string = "";
    loginName: string = "";
}
