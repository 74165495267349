import * as React from "react";
import {CSSProperties} from "react";
import {RouteComponentProps} from "react-router-dom";
import FtTabPanel from "../ui/FtTabPanel";
import {i18n} from "../util/I18n";
import FtTabs from "../ui/FtTabs";
import FtState from "../ui/FtState";
import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import FssCouponListPage from "./FssCouponListPage";
import FssUserCouponListPage from "./FssUserCouponListPage";
import {grey} from "@material-ui/core/colors";
import {theme} from "../mainframe/FssWebsiteAdminTheme";


interface FssCouponSettingTabsPageProp extends RouteComponentProps {
}

class FssCouponSettingTabsPageState {
    tabValue: string = "";
}

export default class FssCouponSettingTabsPage extends React.Component<FssCouponSettingTabsPageProp, FssCouponSettingTabsPageState> {
    stateKey = "FssCouponSettingTabsPageState";

    constructor(props: any) {
        super(props);
        let tabValue = FtState.get(this.stateKey);
        if (tabValue != null) {
            this.state = {tabValue: tabValue};
        } else {
            this.state = {tabValue: "coupon"};
            FtState.put(this.stateKey, "coupon");
        }
    }

    componentDidMount(): void {

    }

    render() {
        const styles: CSSProperties = {
            backgroundColor: "white",
            color: theme.palette.primary.main,
            borderRight: 0,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
        };
        const selectedStyles: CSSProperties = {
            color: "white",
            backgroundColor: theme.palette.primary.main,
            borderRight: 0,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid"
        };
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={() => this.props.history.goBack()} style={{cursor: "pointer"}}>
                    优惠券
                </Link>
                <Typography color="textPrimary">
                    {"coupon" == this.state.tabValue && "优惠券列表"}
                    {"userCoupon" == this.state.tabValue && "已领取优惠券列表"}
                </Typography>
            </Breadcrumbs>
            <FtTabs value={this.state.tabValue}
                    style={{borderBottomColor: grey[300], borderBottomStyle: "solid", borderBottomWidth: 1}}
                    indicatorColor="primary" textColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        this.setState({tabValue: newValue});
                        FtState.put(this.stateKey, newValue);
                    }}>
                {/*<FtTabPanel style={this.state.tabValue == "coupon" ? selectedStyles : styles}*/}
                {/*            label={i18n("优惠券列表")} value="coupon">{<FssCouponListPage/>}</FtTabPanel>*/}
                {/*<FtTabPanel style={this.state.tabValue == "userCoupon" ? selectedStyles : styles}*/}
                {/*            label={i18n("已领取优惠券列表")} value="userCoupon">{<FssUserCouponListPage/>}</FtTabPanel>*/}
            </FtTabs>
        </div>;
    }
}
