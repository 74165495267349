import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, TextField} from "@material-ui/core";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import FssUserProfile from "../model/FssUserProfile";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import Tooltip from "@material-ui/core/Tooltip";
import FssUserService from "../service/FssUserService";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtRow from "../ui/FtRow";
import {EStatus} from "../model/FssUser";
import FssClientData from "../data/FssClientData";

export interface FssUserProfileListPageProp {
}

class FssUserProfileListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssUserProfile> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssUserService.getUserList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssUserProfile>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssUserProfileListPage extends FtComponent<FssUserProfileListPageProp, FssUserProfileListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("user.userCode", i18n("用户编码"));
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const userProfile: FssUserProfile = rowData as FssUserProfile;
            if (userProfile.user !== null) {
                return <Link to={"/user/userProfileDetail/" + userProfile.id}>{userProfile.user.userCode}</Link>
            } else {
                return null;
            }
        };
        columnProps.push(column0);
        const column13: FtDataTableColumnProps = new FtDataTableColumnProps("user.nickName", i18n("昵称"));
        columnProps.push(column13);
        const column14: FtDataTableColumnProps = new FtDataTableColumnProps("user.loginName", i18n("登录名"));
        columnProps.push(column14);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("user.mobile", i18n("手机号"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("user.email", i18n("邮箱"));
        columnProps.push(column2);


        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("user.userStatus", i18n("状态"));
        column3.renderCell = (rowData: object) => {
            const FssUserProfile: FssUserProfile = rowData as FssUserProfile;
            let result = "正常";
            if (FssUserProfile && FssUserProfile.user && FssUserProfile.user.userStatus && FssUserProfile.user.userStatus == EStatus.DISABLED) {
                result = "停用";
            }
            return result;
        };
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("countryInfo.countryName", i18n("默认国家"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("user.createDatetimeText", i18n("注册时间"));
        column5.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const userProfile: FssUserProfile = rowData as FssUserProfile;
            if (userProfile.user !== null) {
                return FssClientData.formatDatetime(userProfile.user.createDatetime);
            } else {
                return null;
            }
        };
        columnProps.push(column5);
        // const column7: FtDataTableColumnProps = new FtDataTableColumnProps("user.avatarUrl", i18n("头像"));
        // columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("realName", i18n("真实姓名"));
        columnProps.push(column8);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("balance", i18n("账户余额"));
        columnProps.push(column9);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssUserProfileListPageState = FtState.pop("FssUserProfileListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssUserProfileListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.exportUserCsv = this.exportUserCsv.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("用户列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 150}} onKeyDownEnter={this.onChangePageNo}/>
            <TextField id="mobile" label={i18n("手机号")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("mobile", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("mobile") as string} style={{width: 150}}/>

            <TextField id="email" label={i18n("Email")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("email", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("email") as string} style={{width: 150}}/>

            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("用户状态")} </InputLabel>
                <Select id="userStatus" value={this.state.tableAdapter.getFilterColumnValue("userStatus")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("userStatus", event.target.value as number);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={""}>{i18n("全部")}</MenuItem>
                    <MenuItem value={0}>{i18n("可用")}</MenuItem>
                    <MenuItem value={99}>{i18n("禁用")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{minWidth: 150}}>
                <InputLabel>{i18n("用户级别")}</InputLabel>
                <Select
                    id="userLevel" value={this.state.tableAdapter.getFilterColumnValue("userLevel")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("userLevel", event.target.value as number);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={""}>{i18n("全部")}</MenuItem>
                    <MenuItem value={0}>{i18n("0级别")}</MenuItem>
                    <MenuItem value={1}>{i18n("1级别")}</MenuItem>
                    <MenuItem value={2}>{i18n("2级别")}</MenuItem>
                    <MenuItem value={3}>{i18n("3级别")}</MenuItem>
                </Select>
            </FormControl>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Tooltip title={i18n("导出用户到CSV文件")}>
                <Button onClick={this.exportUserCsv}>
                    {i18n("导出CSV")}
                </Button>
            </Tooltip>
        </div>;
    }

    private exportUserCsv() {
        FtToast.showToastLoading("loading");
        FssUserService.exportUserCsv(this.state.tableAdapter.keyword,
            this.state.tableAdapter.filterColumns, this.state.tableAdapter.sortColumn ? this.state.tableAdapter.sortColumn.propertyName : null,
            this.state.tableAdapter.sortDirection, 0, 999999,
            (fileUrl: string) => {
                FtToast.closeToast();
                var element = document.createElement('a');
                element.setAttribute("href", fileUrl);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
    }
}
