import Cookies from 'universal-cookie';
import {History, LocationState} from "history";
import _ from "lodash";
import FssClientData from "../data/FssClientData";

declare const device: any;

export default class FtUtil {
    /**
     * @method 深复制一个json对象
     * @param source 需要深复制的对象
     * @return 返回一个新的json对象
     * @author vincent 2018-11-29
     * @version 0.0.0
     * @example
     * @log 1. vincent,2018-11-29, func、date、reg 和 err 类型不能正常拷贝
     */
    static deepCopyJson(source: any): Object {
        const newObject: any = {};
        for (const key of Object.keys(source)) {
            newObject[key] = typeof source[key] === 'object' ? FtUtil.deepCopyJson(source[key]) : source[key];
        }
        return newObject;
    }

    static getProperty(source: any, propertyName: string): any {
        if (source == undefined || source == null)
            return source;
        let currentPropertyName: string = "";
        let subPropertyName: string = "";
        let result: any;
        const index = propertyName.indexOf(".");
        if (index > 0) {
            subPropertyName = propertyName.substring(index + 1);
            currentPropertyName = propertyName.substr(0, index);
        } else {
            currentPropertyName = propertyName;
        }
        for (const key of Object.keys(source)) {
            if (key == currentPropertyName)
                result = source[key];
        }
        if (subPropertyName == "")
            return result;
        else
            return FtUtil.getProperty(result, subPropertyName);
    }

    static setProperty(source: any, propertyName: string, value: any): Object {
        //改用lodash之后，即使属性是?undefined，也可以赋值成功
        const data: any = [];
        data[propertyName] = value;
        return _.assign(source, data);
    }

    static cloneObject<T>(value: T): T {
        return _.clone(value);
    }

    /**
     * 合并两个对象
     * @param object1
     * @param object2
     */
    static mergeObject(targetObject: any, object2?: any, ...objectN: any[]): any {
        // return $.extend(true, {}, object1, object2, ...objectN);
        // 改loadash
        return _.merge({}, targetObject, object2, ...objectN);
    }

    // currency: string,
    static toCurrencyText(src: number | undefined): string {
        if (src != null && src.toFixed) {
            let fixed = src.toFixed(2);
            return this.getCurrencyCode() + " " + parseFloat(fixed);
        } else {
            return "";
        }
    }

    static getCurrencyCode() {
        let currency: string = FssClientData.getData().websiteStore.currency;
        if (currency == "NGN") {
            return "₦";
        } else if (currency == "PKR") {
            return "₨";
        } else if (currency == "IDR") {
            return "৲৳";
        } else if (currency == "USD") {
            return "$";
        } else if (currency == "CNY") {
            return "¥";
        }
        return "$";
    }

    static toCurrency(src: number | undefined): number {
        if (src != null) {
            let fixed = src.toFixed(2);
            return +parseFloat(fixed);
        } else {
            return 0;
        }
    }

    /**
     * 把数组转换成字符串
     * @param array
     */
    static arrayToString(array: Array<any>) {
        let str: string = "";
        if (array != null) {
            array.forEach((value: any) => {
                if (value != null)
                    str += value + ",";
            });
        }
        if (str.length > 1)
            str.substring(0, str.length - 1);
        return str;
    }

    static stringToArray(source: string, delimiter: string = ",") {
        let list: Array<string> = [];
        if (source != null) {
            list = source.split(delimiter);
        }
        if (list != null && list.length > 0 && list[list.length - 1] == "")
            list.pop();
        return list;
    }

    static getOriginalPicUrl(url: string) {
        if (url == null || url.length == 0)
            return "";
        const noExtName: string = this.getFileNameNoExt(url);
        const extName: string = this.getFileExtName(url);
        if (noExtName.endsWith("_m") || noExtName.endsWith("_s") || noExtName.endsWith("_l") || noExtName.endsWith("_t"))
            return noExtName.substring(0, noExtName.length - 2) + "." + extName;
        else
            return url;
    }

    static getTinyPicUrl(url: string) {
        if (url == null || url.length == 0)
            return "";
        const noExtName: string = this.getFileNameNoExt(url);
        const extName: string = this.getFileExtName(url);
        if (noExtName.endsWith("_t"))
            return url;
        else if (noExtName.endsWith("_m") || noExtName.endsWith("_s") || noExtName.endsWith("_l"))
            return noExtName.substring(0, noExtName.length - 2) + "_t." + extName;
        else
            return noExtName + "_t." + extName;
    }

    static getSmallPicUrl(url: string) {
        if (url == null || url.length == 0)
            return "";
        const noExtName: string = this.getFileNameNoExt(url);
        const extName: string = this.getFileExtName(url);
        if (noExtName.endsWith("_s"))
            return url;
        else if (noExtName.endsWith("_m") || noExtName.endsWith("_t") || noExtName.endsWith("_l"))
            return noExtName.substring(0, noExtName.length - 2) + "_s." + extName;
        else
            return noExtName + "_s." + extName;
    }

    static getMediumPicUrl(url: string) {
        if (url == null || url.length == 0)
            return "";
        const noExtName: string = this.getFileNameNoExt(url);
        const extName: string = this.getFileExtName(url);
        if (noExtName.endsWith("_m"))
            return url;
        else if (noExtName.endsWith("_t") || noExtName.endsWith("_s") || noExtName.endsWith("_l"))
            return noExtName.substring(0, noExtName.length - 2) + "_m." + extName;
        else
            return noExtName + "_m." + extName;
    }

    static getLargePicUrl(url: string) {
        if (url == null || url.length == 0)
            return "";
        const noExtName: string = this.getFileNameNoExt(url);
        const extName: string = this.getFileExtName(url);
        if (noExtName.endsWith("_l"))
            return url;
        else if (noExtName.endsWith("_m") || noExtName.endsWith("_s") || noExtName.endsWith("_t"))
            return noExtName.substring(0, noExtName.length - 2) + "_l." + extName;
        else
            return noExtName + "_l." + extName;
    }

    static getFileNameNoExt(name: string) {
        const index = name.lastIndexOf('.');
        if (index == -1)
            return name;
        else
            return name.substring(0, index);
    }

    static getFileExtName(name: string) {
        const index = name.lastIndexOf('.');
        if (index == -1)
            return "";
        else
            return name.substring(index + 1, name.length);
    }

    static getParameterByName(name: string, url: string): string {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return "";
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static replaceUrlParam(paramName: string, paramValue: string, url: string) {
        if (paramValue == null) {
            paramValue = '';
        }
        const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
        if (url.search(pattern) >= 0) {
            return url.replace(pattern, '$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/, '');
        return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
    }

    static isWechatBroswer(): boolean {
        // return true;
        var ua = navigator.userAgent.toLowerCase();
        var isWeixin = ua.indexOf('micromessenger') != -1;
        if (isWeixin) {
            return true;
        } else {
            return false;
        }
    }

    static isWechatMiniProgram(): boolean {
        var ua = navigator.userAgent.toLowerCase();
        var isWeixin = ua.toLowerCase().indexOf('micromessenger') != -1 && ua.toLowerCase().indexOf('miniprogram') != -1;
        if (isWeixin) {
            return true;
        } else {
            return false;
        }
    }

    static isAlipayBroswer(): boolean {
        // return true;
        var ua = navigator.userAgent.toLowerCase();
        var alipayclient = ua.indexOf('alipayclient') != -1;
        if (alipayclient) {
            return true;
        } else {
            return false;
        }
    }

    static isNativeApp(): boolean {
        var ua = navigator.userAgent;
        if (ua.toLocaleLowerCase().indexOf("ft-app") >= 0)
            return true;
        else
            return false;
    }

    static isNativeAppAndroid(): boolean {
        var ua = navigator.userAgent;
        if (ua.toLocaleLowerCase().indexOf("ft-app-android") >= 0)
            return true;
        else
            return false;
    }

    static isNativeAppIos(): boolean {
        var ua = navigator.userAgent;
        if (ua.toLocaleLowerCase().indexOf("ft-app-ios") >= 0)
            return true;
        else
            return false;
    }

    static setCookie(name: string, value: string, domain: string = this.getDomain(), path: string = "/", maxAgeInSecond?: number): void {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        if (maxAgeInSecond)
            options["maxAge"] = maxAgeInSecond;
        cookies.set(name, value, options);
    }

    static getCookie(name: string, domain: string = this.getDomain(), path: string = "/"): string {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        return cookies.get(name, options);
    }

    static deleteCookie(name: string, domain: string = this.getDomain(), path: string = "/"): void {
        const cookies = new Cookies();
        let options: any = {};
        if (domain)
            options["domain"] = domain;
        if (path)
            options["path"] = path;
        cookies.remove(name, options);
    }

    static getDomain(): string {
        return window.location.hostname;
    }

    static getCurrentDatetime(): string {
        const now = new Date();
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    }

    static goBack(history: History<LocationState>, homeUrl?: string, step?: number) {
        if (history.length <= 1 || (step != null && step * -1 >= history.length))
            history.push(homeUrl ? homeUrl : "/");
        else if (step != null && step < -1)
            history.go(step);
        else
            history.goBack();
    }

    static toPercentText(num: number) {
        const roundingPrecision = 4;
        const roundedNum = _.round(num * 100, roundingPrecision);
        return roundedNum + '%';
    }

    static isEmail(email: string): boolean {
        const regexpEmail = new RegExp("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$");
        return regexpEmail.test(email);
    }

    static getRouterUrl(href: string): string {
        let result: string = "";
        const index1 = href.indexOf("?");
        if (index1 > 0) {
            const index2 = href.indexOf("#");
            if (index2 > 0) {
                if (index2 > index1) {
                    //如果hash在后，参数在前
                    return href.substr(0, index1) + href.substr(index2);
                } else {
                    //    如果hash在前，参数在后
                    return href.substr(0, index1);
                }
            } else
                return href.substr(0, index1);
        } else
            return href;
    }

    static getCurrencyCodeByCurrency(currency: string) {
        if (currency == "NGN") {
            return "₦";
        } else if (currency == "PKR") {
            return "₨";
        } else if (currency == "IDR") {
            return "Rp";
        } else if (currency == "USD") {
            return "$";
        } else if (currency == "CNY") {
            return "¥";
        }
        return "$";
    }
}