import FssStore from "./FssStore";

export enum ESellerStatus {
    NORMAL = "NORMAL",
    STOP = "STOP"
}

export enum ESellerType {
    STANDARD = "STANDARD",
    LITE = "LITE",
    PUBLISHER = "PUBLISHER",
}

export default class FssSeller {
    id: string = "";
    storeId: string = "";
    shortName: string = "";
    fullName: string = "";
    slogan: string = "";
    shortDescription: string = "";
    coverImageUrl: string = "";
    logoUrl: string = "";

    createDatetime?: Date;
    createDatetimeText: string = "";
    sellerStatus: ESellerStatus = ESellerStatus.NORMAL;
    sellerStatusText: string = "";
    sellerType: ESellerType = ESellerType.LITE;

    freeShipMinimum: number = 0;
    minimumOrderMoney: number = 0;
    userShippingFee: number = 0;
    userPackageFee: number = 0;
    advertMap: { [key: string]: any[] } = {};
    orderComment: string = "";
    storeOpeningHours: string = "";
    storeAddress: string = "";
    scheduleDatetimeDesc: string = "";
    scheduleDatetimeText: string = "";
    servicePhone: string = "";
    serviceEmail: string = "";
    wechatOfficeAccount?: string;
    serviceWechatId?: string;
    fssStore: FssStore = new FssStore();
    commissionRate: number = 0;
    isDefaultSeller: string = "0";

    constructor(id?: string) {
        if (id != null)
            this.id = id;
    }


    static clone(seller: FssSeller) {
        const sellerNew = new FssSeller();
        sellerNew.id = seller.id;
        sellerNew.shortName = seller.shortName;
        sellerNew.orderComment = seller.orderComment;
        sellerNew.storeId = seller.storeId;
        sellerNew.shortDescription = seller.shortDescription;
        sellerNew.slogan = seller.slogan;
        sellerNew.fullName = seller.fullName;
        sellerNew.coverImageUrl = seller.coverImageUrl;
        sellerNew.minimumOrderMoney = seller.minimumOrderMoney;
        sellerNew.freeShipMinimum = seller.freeShipMinimum;
        sellerNew.sellerStatus = seller.sellerStatus;
        sellerNew.logoUrl = seller.logoUrl;
        sellerNew.createDatetime = seller.createDatetime;
        sellerNew.createDatetimeText = seller.createDatetimeText;
        sellerNew.sellerStatusText = seller.sellerStatusText;
        sellerNew.sellerType = seller.sellerType;
        sellerNew.userShippingFee = seller.userShippingFee;
        sellerNew.userPackageFee = seller.userPackageFee;
        sellerNew.storeAddress = seller.storeAddress;
        sellerNew.storeOpeningHours = seller.storeOpeningHours;
        return sellerNew;
    }
}
