import {FtAbstractModel} from "../model/FtAbstractModel";
import FssUser from "./FssUser";

export default class FssUserProfile extends FtAbstractModel {
    myInviteCode?: string;
    inviterId?: string;
    inviterCode: string = "";
    referral: string = "";
    realName: string = "";
    balance: number = 0;
    defaultShipFromName: string = "";
    defaultShipFromPhone: string = "";
    defaultShipFromAddress: string = "";
    point: number = 0;
    comment: string = "";
    defaultLanguage: string = "";
    modifiedDatetime?: Date;
    user: FssUser = new FssUser();
    inviteUserCount: number = 0;
}
