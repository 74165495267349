export default class FssUserBalanceTransaction {

    id: string = "";
    userId: string = "";
    balanceBefore: number = 0;
    balanceAfter: number = 0;
    balanceAmount: number = 0;
    type: EBalanceType = EBalanceType.IN;
    thirdId: string = "";
    createDatetime: string = "";
    createDatetimeText: string = "";
    ruleId: string = "";
    comment: string = "";
    storeId: string = "";
    sellerId: string = "";
}

export enum EBalanceType {
    IN = "IN",
    OUT = "OUT",
}