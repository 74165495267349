import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssCountryInfo from "../model/FssCountryInfo";

export default class FssCountryInfoService extends FssHttpService {

    static getPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                        pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssCountryInfo>) => void) {
        FssHttpService.search("/api/countryInfo/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static getById(id: string, successFunction: (data: FssCountryInfo) => void): void {
        FssHttpService.get("/api/countryInfo/getById", {id: id}, successFunction, FssHttpService.handleError);
    }

    static getAvailableList(successFunction: (data: Array<FssCountryInfo>) => void): void {
        FssHttpService.get("/api/countryInfo/getAvailableListForAdmin", {}, successFunction, FssHttpService.handleError);
    }

    static save(fssRegion: FssCountryInfo, successFunction: (data: FssCountryInfo) => void): void {
        this.postJson("/api/countryInfo/save", fssRegion, successFunction, FssHttpService.handleError);
    }
}
