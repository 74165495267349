import {ETwoStatus, FtAbstractModel} from "./FtAbstractModel";
import FssSeller from "./FssSeller";

export default class FssStore {
    id: string = "";
    storeName: string = "";
    defaultSellerId: string = "";
    createDatetime: string = "";
    isPlatform: boolean = false;
    status?: ETwoStatus;
    logoUrl?: string;
    description?: string;
    defaultSeller?: FssSeller;
    currency: string = "";
    countryCode: string = "";
    countryInfoIdList: Array<string> = [];
    countryInfoIds: string = "";
}
