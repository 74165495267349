import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button} from "@material-ui/core";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssStore from "../model/FssStore";
import {Add} from "@material-ui/icons";
import FssStoreService from "../service/FssStoreService";

export interface FssStoreListPageProp {
}

class FssStoreListPageState extends FtComponentState{
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssStore> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssStoreService.getStorePagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size, (result: FtPagedList<FssStore>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssStoreListPage extends FtComponent<FssStoreListPageProp, FssStoreListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("storeName", i18n("分站名称"));
        column0.renderCell = (rowData: object) => {
            const store: FssStore = rowData as FssStore;
            return <Link to={"/store/storeDetail/" + store.id}>{store.storeName}</Link>
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("websiteRegion.regionName", i18n("地区"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("状态"));
        columnProps.push(column2);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssStoreListPageState = FtState.pop("FssStoreListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssStoreListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("分站列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }

    onChangePageNo () {
        this.state.tableAdapter.changePageNo(0);
    }

    getFilterPanel(): ReactNode {
        return <div style={{paddingLeft: 8}}>
            <FtTextFieldString label={i18n("分站名称")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 150}} onKeyDownEnter={this.onChangePageNo}/>
        </div>;
    }

    getToolbarNoSelect(): ReactNode {
        return <Link to={"/store/storeDetail/0"}>
            <Button>
                <Add />{i18n("新增")}
            </Button>
        </Link>;
    }
}
