import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {grey} from "@material-ui/core/colors";
import FtDivFlex from "./FtDivFlex";
import FtUtil from "../util/FtUtil";
import AppClientInfo from "../data/AppClientInfo";

interface FtMediaViewerDialogProp {
}

export class FtMediaViewerDialogState {
    open: boolean = false;
    label?: string = "";
    mediaUrl: string = "";
    mediaType?: "image" | "video" = "image";
}


export default class FtMediaViewerDialog extends React.Component<FtMediaViewerDialogProp, FtMediaViewerDialogState> {
    static FtMediaViewerDialog: FtMediaViewerDialog;

    constructor(props: any) {
        super(props);
        this.state = {open: false, label: "", mediaUrl: "", mediaType: "image"};
        this.show = this.show.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(): void {

    }

    show(mediaUrl: string, mediaType?: "image" | "video", label?: string): void {
        const url:string=FtUtil.getOriginalPicUrl(mediaUrl);
        this.setState({open: true, mediaUrl: url, mediaType: mediaType, label: label});
    }

    onClose(): void {
        this.setState({open: false});
    }

    render() {
        return <Dialog open={this.state.open} onClose={this.onClose} scroll={"paper"} fullScreen={true}>
            <div style={{position:"relative",backgroundColor:grey[500],textAlign:"center"}}>
                <img src={this.state.mediaUrl} style={{maxWidth:"100%",maxHeight:"100%"}}></img>
                <IconButton onClick={this.onClose}  style={{
                    position: "absolute",
                    right: 16,
                    top: 16+AppClientInfo.clientInfo.insetTop,
                    backgroundColor: grey[200]
                }}><CloseIcon color={"primary"}/></IconButton>
                <FtDivFlex justifyContent={"center"} style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white"
                }}>
                    {this.state.label}
                </FtDivFlex>
            </div>
        </Dialog>;
    }

}
