import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, TextField} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FtSnackbar from "../ui/FtSnackbar";
import FtUtil from "../util/FtUtil";
import FtMessageDialog from "../ui/FtMessageDialog";
import Typography from "@material-ui/core/Typography";
import {i18n} from "../util/I18n";
import FssPointRule, {ERuleCode, ERuleStatus} from "../model/FssPointRule";
import FssPointService from "../service/FssPointService";
import FssStore from "../model/FssStore";

export interface JtScoreRuleListPagePorp {
    store: FssStore;
}

class JtScoreRuleInListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    pointRule: FssPointRule = new FssPointRule();
    valueLabel: string = "";
    openUpdateDialog: boolean = false;
}

class JtTableAdapter extends FtDataTableAdapter<FssPointRule> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPointService.getFssPointRuleList("", this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssPointRule>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssPointRuleInListPage extends FtComponent<JtScoreRuleListPagePorp, JtScoreRuleInListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("ruleName", i18n("规则名称"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            return <Button onClick={() => {
                this.onRowClick(rowData, rowIndex);
            }}>{columnValue}</Button>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("value", i18n("规则数值"));
        column3.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const pointRule: FssPointRule = rowData as FssPointRule;
            if (pointRule.ruleCode == ERuleCode.MONEY_GIVE_SCORE_RATE) {
                return <span>{columnValue}</span>
            } else {
                return <span>{columnValue}</span>
            }

        };
        columnProps.push(column3);

        let tableAdapter: JtTableAdapter = new JtTableAdapter();
        tableAdapter.filterColumns.set("storeId", this.props.store.id);
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openUpdateDialog: false,
            pointRule: new FssPointRule(),
            valueLabel: ""
        };
        this.onClickSave = this.onClickSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("积分获取规则列表")}
                         columnProps={this.state.columnProps}
                         adapter={this.state.tableAdapter}
                         style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}/>
            {this.getUpdateDialog()}
        </div>;
    }

    onRowClick(rowData: object, rowIndex: number) {
        let jtscoreRule: FssPointRule = FtUtil.mergeObject(this.state.pointRule, rowData as FssPointRule);
        let valueLabel = "";
        if (jtscoreRule.ruleCode == ERuleCode.MONEY_GIVE_SCORE_RATE) {
            valueLabel = i18n("用户可获取积分比例");
        } else {
            valueLabel = i18n("用户可获取积分数值");
        }
        this.setState({openUpdateDialog: true, pointRule: rowData as FssPointRule, valueLabel: valueLabel});
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.pointRule, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        if (this.state.pointRule.ruleCode == "REGISTERED_GIVE_SCORE") {
            let value = this.state.pointRule.value;
            if (!(/(^[1-9]\d*$)/.test(value + ""))) {
                FtSnackbar.showText(i18n("用户可获取积分数值』为整数"));
                return;
            }
        }
        FtMessageDialog.dialog.openDialog(i18n("确定保存吗？"), () => {
            FtToast.showToastLoading(i18n("正在保存"));
            FssPointService.saveFssPointRule(this.state.pointRule, (data: FssPointRule) => {
                this.setState({pointRule: FtUtil.mergeObject(this.state.pointRule, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
                this.setState({openUpdateDialog: false});
                this.state.tableAdapter.loadData();
            });
        }, () => {
            this.setState({openUpdateDialog: false});
        }, i18n("保存"), i18n("放弃修改"));


    }

    getUpdateDialog(): ReactNode {
        return <Dialog open={this.state.openUpdateDialog} onClose={(e) => {
            this.setState({openUpdateDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{this.state.pointRule.ruleName}</DialogTitle>
            <DialogContent>
                <div>
                    <FormControl style={{width: "50%"}}>
                        <InputLabel id="demo-simple-select-label"> {i18n("状态")} </InputLabel>
                        <Select id="status" labelId="demo-simple-select-label" value={this.state.pointRule.status}
                                onChange={(event, child) => {
                                    this.state.pointRule.status = event.target.value as ERuleStatus;
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={ERuleStatus.NORMAL}>{i18n("正常")}</MenuItem>
                            <MenuItem value={ERuleStatus.STOP}>{i18n("停用")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <TextField id="value" label={this.state.valueLabel} type="number"
                               value={this.state.pointRule.value}
                               onChange={this.onChange} style={{width: "50%"}}/>
                </div>
                {this.state.pointRule.ruleCode == "MONEY_GIVE_SCORE_RATE" &&
                <div style={{display: "flex", flexDirection: "column", marginTop: 50}}>
                    <Typography variant="subtitle2">{i18n("积分获取规则设置说明")}</Typography>
                    <Typography variant="caption">{i18n("1.状态为正常的情况，用户才能规则获取积分")}</Typography>
                    <Typography variant="caption">{i18n("2.用户可获取积分数值设置为1个货币单位可换取的积分数量，建议数值设为整数")}</Typography>
                </div>}
                {this.state.pointRule.ruleCode == "REGISTERED_GIVE_SCORE" &&
                <div style={{display: "flex", flexDirection: "column", marginTop: 50}}>
                    <Typography variant="subtitle2">{i18n("积分获取规则设置说明")}</Typography>
                    <Typography variant="caption">{i18n("1.状态为正常的情况，用户才能规则获取积分")}</Typography>
                    <Typography variant="caption">{i18n("2.新用户注册后赠送积分数量")}</Typography>
                </div>}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openUpdateDialog: false});
                    this.state.tableAdapter.loadData();
                }}>{i18n("取消")}</Button>
                <Button variant="contained" color="primary" onClick={this.onClickSave}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }
}
