import * as React from "react";
import {ChangeEvent} from "react";
import {Breadcrumbs, Link, TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../../util/FtUtil";
import FtToast from "../../ui/FtToast";
import {RouteComponentProps} from "react-router-dom";
import FtRow from "../../ui/FtRow";
import {i18n} from "../../util/I18n";
import FssPaymentAccount, {
    EBalanceRechargeDefault,
    EPaymentChannel,
    EPaymentMethod,
    EPaymentProvider
} from "../../model/FssPaymentAccount";
import MenuItem from "@material-ui/core/MenuItem";
import FtPanel from "../../ui/FtPanel";
import FtGrid from "../../ui/FtGrid";
import FtSnackbar from "../../ui/FtSnackbar";
import FtTextFieldNumber from "../../ui/FtTextFieldNumber";
import FssPaymentAccountService from "../../service/FssPaymentAccountService";
import {ETwoStatus} from "../../model/FtAbstractModel";
import FtToolbar from "../../ui/FtToolbar";
import {ArrowBack} from "@material-ui/icons";

interface FssPaymentAccountDetailPageProp extends RouteComponentProps<{ id?: string }> {
}

export class FssPaymentAccountDetailPageState {
    paymentAccount: FssPaymentAccount = new FssPaymentAccount();
    isChangeProvider: boolean = false;
    paymentMethod?: EPaymentMethod;
}


export default class FssPaymentAccountDetailPage extends React.Component<FssPaymentAccountDetailPageProp, FssPaymentAccountDetailPageState> {
    constructor(props: any) {
        super(props);
        let fssPaymentAccount = new FssPaymentAccount();
        fssPaymentAccount.accountStatus = ETwoStatus.DISABLED;
        this.state = {
            paymentAccount: fssPaymentAccount,
            isChangeProvider: false,
        };
        this.load = this.load.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getPaymentChannel = this.getPaymentChannel.bind(this);
        this.updateAccountStatus = this.updateAccountStatus.bind(this);
        this.onClickStop = this.onClickStop.bind(this);
        this.onClickStart = this.onClickStart.bind(this);
        this.getPaymentAccount = this.getPaymentAccount.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.id != null && this.props.match.params.id.length > 0) {
            this.getPaymentAccount(this.props.match.params.id);
        }
    }

    getPaymentAccount(paymentAccountId: string) {
        FtToast.showToastLoading();
        FssPaymentAccountService.getPaymentAccountById(paymentAccountId, (result: FssPaymentAccount) => {
            FtToast.closeToast();
            let paymentMethod = result.paymentMethod;
            this.setState({paymentAccount: result, paymentMethod: paymentMethod, isChangeProvider: false});
        });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.paymentAccount, event.target.id, event.target.value);
        this.forceUpdate();
        if (event.target.id.startsWith("paymentProviderKey") && !this.state.isChangeProvider) {
            this.setState({isChangeProvider: true});
        }
    }

    onClickSave() {
        let paymentAccount = this.state.paymentAccount;
        if (paymentAccount.storeId == null || paymentAccount.storeId.trim().length == 0) {
            paymentAccount.storeId = EBalanceRechargeDefault.BALANCE_RECHARGE_STORE_ID;
        }
        if (paymentAccount.sellerId == null || paymentAccount.sellerId.trim().length == 0) {
            paymentAccount.sellerId = EBalanceRechargeDefault.BALANCE_RECHARGE_SELLER_ID;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssPaymentAccountService.savePaymentAccount(paymentAccount, (paymentAccount: FssPaymentAccount) => {
            FtToast.closeToast();
            this.setState({
                paymentAccount: paymentAccount,
                isChangeProvider: false,
                paymentMethod: paymentAccount.paymentMethod
            });
            FtSnackbar.showText(i18n("保存成功"));
        });
    }

    onClickStop() {
        this.updateAccountStatus(ETwoStatus.DISABLED);
    }

    onClickStart() {
        this.updateAccountStatus(ETwoStatus.NORMAL);
    }

    updateAccountStatus(accountStatus: ETwoStatus) {
        let paymentAccountIds: Array<string> = [this.state.paymentAccount.id];
        FtToast.showToastLoading();
        FssPaymentAccountService.updateAccountStatus(paymentAccountIds, accountStatus, () => {
            FtToast.closeToast();
            this.state.paymentAccount.accountStatus = accountStatus;
            this.forceUpdate();
            FtSnackbar.showText(i18n("操作成功"));
        });
    }

    getAccountStatusBtn() {
        if (this.state.paymentAccount.accountStatus === ETwoStatus.NORMAL) {
            if (this.state.paymentMethod !== undefined) {
                return <Button variant="outlined" color={"secondary"} onClick={this.onClickStop}>{i18n("停用")}</Button>;
            } else {
                return null;
            }
        } else if (this.state.paymentAccount.accountStatus === ETwoStatus.DISABLED) {
            return <Button variant="outlined" color={"secondary"} onClick={this.onClickStart}>{i18n("启用")}</Button>;
        }
    }

    render() {
        let isEdit: boolean = false;
        if (this.state.paymentAccount.id != null && this.state.paymentAccount.id.length > 0) {
            isEdit = true;
        }
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={this.onCancelClick} style={{cursor: "pointer"}}>
                    <div style={{display: "flex"}}>
                        <div style={{maxHeight: 24}}><ArrowBack style={{width: 20}}/></div>
                        <span>支付网关列表</span>
                    </div>
                </Link>
                <Typography color="textPrimary">
                    {this.state.paymentAccount.paymentAccountName}
                </Typography>
            </Breadcrumbs>
            <FtToolbar style={{padding: 0, backgroundColor: "white"}}
                       leftNode={<FtRow style={{paddingLeft: 8}} cellHorizontalSpace={16} cellVerticalSpace={0}
                                        justifyContent={"center"}>
                           <Button variant="outlined" color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
                           {isEdit && this.getAccountStatusBtn()}
                       </FtRow>}/>
            <FtPanel panelHeader={i18n("支付网关编辑")} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}>
                <FtGrid>
                    <FtRow cellWidthS={"1,1,1,1"}>
                        <TextField id="paymentAccountName" label={i18n("名称")} onChange={this.onChange}
                                   value={this.state.paymentAccount.paymentAccountName} style={{width: "100%"}}/>
                        <FtTextFieldNumber id="accountOrder" label={i18n("显示顺序(用户端优先显示数字大的)")}
                                           onChangeValue={(value) => {
                                               this.state.paymentAccount.accountOrder = value;
                                               this.forceUpdate();
                                           }}
                                           type={"integer"}
                                           value={this.state.paymentAccount.accountOrder} style={{width: "100%"}}/>
                        {isEdit && <TextField label={i18n("创建时间")} value={this.state.paymentAccount.createDatetimeText}
                                              style={{width: "100%"}} disabled={true}/>}
                    </FtRow>
                    <FtRow cellWidthS={"1,1,1,1"}>
                        <TextField id={"paymentMethod"} select label={"支付方式"}
                                   value={this.state.paymentAccount.paymentMethod === undefined ? "" : this.state.paymentAccount.paymentMethod}
                                   onChange={(event) => {
                                       this.state.paymentAccount.paymentMethod = event.target.value as EPaymentMethod;
                                       this.state.paymentAccount.paymentProviderKey0 = "";
                                       this.state.paymentAccount.paymentProviderKey1 = "";
                                       this.state.paymentAccount.paymentProviderKey2 = "";
                                       this.state.paymentAccount.paymentProviderKey3 = "";
                                       this.forceUpdate();
                                   }}
                                   style={{width: "100%"}}>
                            <MenuItem value={EPaymentMethod.PAYNICORN}>Paynicorn</MenuItem>
                        </TextField>
                        {this.getPaymentChannel()}
                        <TextField id={"paymentStatus"} select label={"支付机构"}
                                   value={this.state.paymentAccount.paymentProvider ? this.state.paymentAccount.paymentProvider : ""}
                                   onChange={(event) => {
                                       this.state.paymentAccount.paymentProvider = event.target.value as EPaymentProvider;
                                       this.state.paymentAccount.paymentProviderKey0 = "";
                                       this.state.paymentAccount.paymentProviderKey1 = "";
                                       this.state.paymentAccount.paymentProviderKey2 = "";
                                       this.state.paymentAccount.paymentProviderKey3 = "";
                                       this.forceUpdate();
                                   }}
                                   style={{width: "100%"}}>
                            <MenuItem value={EPaymentProvider.PAYNICORN}>Paynicorn</MenuItem>
                        </TextField>
                    </FtRow>
                    <FtRow cellWidthS={"3,1"}>
                        <TextField id="description" label={i18n("描述")} multiline={true} rows={2}
                                   value={this.state.paymentAccount.description ? this.state.paymentAccount.description : ""}
                                   onChange={this.onChange} style={{width: "100%"}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>
    }

    getPaymentChannel() {
        let paymentChannelList: Array<EPaymentChannel> = new Array<EPaymentChannel>();
        let paymentMethod = this.state.paymentAccount.paymentMethod;
        if (paymentMethod !== undefined) {
            switch (paymentMethod) {
                case EPaymentMethod.PAYNICORN:
                    paymentChannelList.push(EPaymentChannel.PAYNICORN);
                    break;
            }
        }
        return <TextField id={"paymentChannel"} select label={"支付渠道"}
                          value={this.state.paymentAccount.paymentChannel ? this.state.paymentAccount.paymentChannel : ""}
                          onChange={(event) => {
                              this.state.paymentAccount.paymentChannel = event.target.value as EPaymentChannel;
                              this.forceUpdate();
                          }}
                          disabled={paymentChannelList.length == 0}
                          style={{width: "100%"}}>
            {paymentChannelList.map((paymentChannel: EPaymentChannel) => {
                return <MenuItem value={paymentChannel}>Paynicorn</MenuItem>;
            })}
        </TextField>
    }
}
