import * as React from "react";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import {i18n} from "../util/I18n";
import FssStoreCoupon from "./FssStoreCoupon";
import FssStoreCouponService from "../service/FssStoreCouponService";

interface FssStoreCouponComboBoxProp {
    label?: string;
    selectedCoupon?: FssStoreCoupon;
    onChangeCoupon?: (coupon?: FssStoreCoupon) => void;
    storeId: string;
}

class FssStoreCouponComboBoxState {
    couponList: Array<FssStoreCoupon> = [];
    loading: boolean = false;
}

export default class FssStoreCouponComboBox extends React.Component<FssStoreCouponComboBoxProp, FssStoreCouponComboBoxState> {
    constructor(props: any) {
        super(props);
        this.state = {couponList: [], loading: false};
        this.onChangeCouponInput = this.onChangeCouponInput.bind(this);
        this.onChangeCoupon = this.onChangeCoupon.bind(this);
    }


    componentDidMount(): void {

    }

    render() {
        //如果没有商品列表，则至少要把当前选中的商品放入列表，否则不显示
        let couponList = this.state.couponList;
        if ((couponList == null || couponList.length == 0) && this.props.selectedCoupon != null) {
            couponList = [this.props.selectedCoupon];
        }
        return <FtComboboxDynamic label={this.props.label ? this.props.label : i18n("优惠券名字")} optionItems={couponList}
                                  labelPropertyName={"couponName"}
                                  valuePropertyName={"id"}
                                  selectedItem={this.props.selectedCoupon ? this.props.selectedCoupon : null}
                                  onSelectItem={this.onChangeCoupon}
                                  onChangeInput={this.onChangeCouponInput}
                                  loading={this.state.loading} style={{width: "100%"}}/>;
    }

    onChangeCouponInput(keyword: string) {
        if (!this.state.loading) {
            this.setState({loading: true}, () => {
                FssStoreCouponService.getAvailCouponList(this.props.storeId, keyword, 5, (couponList: Array<FssStoreCoupon>) => {
                    this.setState({couponList: couponList, loading: false});
                    // if (couponList == null || couponList.length == 0) {
                    //     this.onChangeCoupon(new FssStoreCoupon());
                    // }
                });
            });
        }
    }

    onChangeCoupon(item: any) {
        if (this.props.onChangeCoupon)
            this.props.onChangeCoupon(item);
    }
}
