import * as React from "react";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {i18n} from "../util/I18n";


export class FtOptionDialogProps {

}

export class FtOptionDialogState {
    open: boolean = false;
    title?: string | React.ReactNode;
    content?: string | React.ReactNode;
    leftButtonLabel?: string = "Cancel";
    onLeftButtonClick?: () => void;
    rightButtonLabel?: string = "OK";
    onRightButtonClick?: () => void;
    options: Array<FtOptionDialogItem> = [];
    onClickOption?: (option: FtOptionDialogItem, index: number) => void;
}

export class FtOptionDialogItem {
    key: string = "";
    value: any;
    primaryText: string = "";
    secondaryText?: string;
    icon?: React.ReactElement;

    constructor(key?: string, value?: any, primaryText?: string, secondaryText?: string) {
        if (key)
            this.key = key;
        if (value)
            this.value = value;
        if (primaryText)
            this.primaryText = primaryText;
        if (secondaryText)
            this.secondaryText = secondaryText;
    }
}

export default class FtOptionDialog extends React.Component<FtOptionDialogProps, FtOptionDialogState> {
    static dialog: FtOptionDialog;

    constructor(props: any) {
        super(props);
        this.state = {open: false, options: []};
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onLeftButtonClick = this.onLeftButtonClick.bind(this);
        this.onRightButtonClick = this.onRightButtonClick.bind(this);
    }

    openDialog(content: string | React.ReactNode, options: Array<FtOptionDialogItem>, onClickOption: (option: FtOptionDialogItem, index: number) => void,
               onRightButtonClick?: () => void, onLeftButtonClick?: () => void, rightButtonLabel: string = "OK", leftButtonLabel: string = "Cancel") {
        this.setState({
            open: true,
            content: content,
            rightButtonLabel: rightButtonLabel,
            onRightButtonClick: onRightButtonClick,
            leftButtonLabel: leftButtonLabel,
            onLeftButtonClick: onLeftButtonClick,
            options: options,
            onClickOption: onClickOption
        });
    }

    closeDialog() {
        this.setState({open: false});
    }

    onLeftButtonClick() {
        this.closeDialog();
        if (this.state.onLeftButtonClick) {
            this.state.onLeftButtonClick();
        }
    }

    onRightButtonClick() {
        this.closeDialog();
        if (this.state.onRightButtonClick) {
            this.state.onRightButtonClick();
        }
    }

    render() {
        const actions: Array<JSX.Element> = [];
        if (this.state.leftButtonLabel != null && this.state.leftButtonLabel.length > 0)
            actions.push(<Button variant={"outlined"}
                                 onClick={this.onLeftButtonClick}
                                 style={{float: "left"}}>{this.state.leftButtonLabel}</Button>);
        if (this.state.rightButtonLabel != null && this.state.rightButtonLabel.length > 0)
            actions.push(<Button variant={"contained"} color={"primary"}
                                 onClick={this.onRightButtonClick}>{this.state.rightButtonLabel}</Button>);
        return (
            <Dialog open={this.state.open} maxWidth={"md"}>
                <DialogContent style={{margin: 8}}>
                    {this.state.content}
                    <List component="nav">
                        {this.state.options && this.state.options.map((option: FtOptionDialogItem, index: number) => {
                            return <ListItem key={option.key} button onClick={() => {
                                if (this.state.onClickOption) {
                                    this.state.onClickOption(option, index);
                                }
                            }}>
                                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                                <ListItemText primary={option.primaryText} secondary={option.secondaryText}/>
                            </ListItem>
                        })}
                    </List>
                </DialogContent>
                <DialogActions style={{display: "flex", justifyContent: "space-between", margin: 8}}>
                    {actions}
                </DialogActions>
            </Dialog>
        );
    }
};