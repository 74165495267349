import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Breadcrumbs, Button, Link, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FssCountryInfo from "../model/FssCountryInfo";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssCountryInfoService from "../service/FssCountryInfoService";
import FtRow from "../ui/FtRow";
import {ETwoStatus} from "../model/FtAbstractModel";

export interface FssCountryInfoListPageProp extends RouteComponentProps {
}

class FssCountryInfoListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssCountryInfo> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssCountryInfoService.getPagedList(this.keyword, this.filterColumns, null, null, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssCountryInfo>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssCountryInfoListPage extends FtComponent<FssCountryInfoListPageProp, FssCountryInfoListPageState> {


    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("countryName", i18n("国家名称"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const countryInfo: FssCountryInfo = rowData as FssCountryInfo;
            return <Button color={"primary"}
                           onClick={() => this.props.history.push("/countryInfoDetail/" + countryInfo.id)}>
                {countryInfo.countryName}
            </Button>
        };
        columnProps.push(column2);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("countryCode", i18n("国家编码"));
        columnProps.push(column1);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("currencyUnit", i18n("货币单位"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("currencySymbol", i18n("货币符号"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("decimalPoint", i18n("货币小数点"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("exchangeRate", i18n("汇率"));
        columnProps.push(column8);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("areaCode", i18n("国际区号"));
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("defaultLanguage", i18n("默认语言"));
        columnProps.push(column10);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssCountryInfoListPageState = FtState.pop("FssCountryInfoListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssCountryInfoListPageState"};
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <Breadcrumbs style={{marginLeft: 4, marginBottom: 8}}>
                <Link color="inherit" onClick={() => this.props.history.goBack()}
                      style={{cursor: "pointer"}}>
                    <div style={{display: "flex"}}>
                        <div style={{maxHeight: 24}}><ArrowBack style={{width: 20}}/></div>
                        <span>设置</span>
                    </div>
                </Link>
                <Typography color="textPrimary">
                    国家信息列表
                </Typography>
            </Breadcrumbs>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}/>
        </div>
    }

    // getFilterPanel(): ReactNode {
    //     return <FtRow style={{marginLeft: 8, marginRight: 8}}>
    //         <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
    //             this.state.tableAdapter.keyword = value ? value : "";
    //             this.forceUpdate();
    //         }} value={this.state.tableAdapter.keyword} style={{width: 200}}/>
    //         <FormControl style={{minWidth: 150}}>
    //             <InputLabel> {i18n("状态")} </InputLabel>
    //             <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
    //                     onChange={(event: any) => {
    //                         this.state.tableAdapter.filterColumns.set("status", event.target.value);
    //                         this.forceUpdate();
    //                     }}>
    //                 <MenuItem value={ETwoStatus.NORMAL}>{i18n("正常")}</MenuItem>
    //                 <MenuItem value={ETwoStatus.DISABLED}>{i18n("停用")}</MenuItem>
    //             </Select>
    //         </FormControl>
    //     </FtRow>
    // }

    getToolbarNoSelect(): ReactNode {
        return <Button variant={"outlined"} color={"primary"}
                       onClick={() => this.props.history.push("/countryInfoDetail")}>
            {i18n("新增")}
        </Button>
    }
}
