import FssUser from "../model/FssUser";
import FssHttpService from "../service/FssHttpService";
import FasUser from "../model/FasUser";

export default class FasUserService {


    // fas user
    static getFasUserDetail(successFunction: (data: FasUser) => void) {
        FssHttpService.get("/api/fasUser/getFasUserDetail", null, successFunction, FssHttpService.handleError);
    }

    static saveFasUserDetail(fssUser: FasUser, successFunction: (data: FasUser) => void) {
        FssHttpService.postJson("/api/fasUser/saveFasUserDetail", fssUser, successFunction, FssHttpService.handleError);
    }

    static updateFasUserMobile(mobile: string, verifyCode: string, verifyCodeId: string, successFunction: (data: FssUser) => void) {
        FssHttpService.postFormData("/api/fasUser/updateFasUserMobile", {
            mobile: mobile,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        }, successFunction, FssHttpService.handleError);
    }

    static resetFasUserPassword(mobile: string, password: string, verifyCode: string, verifyCodeId: string, successFunction: (data: string) => any) {
        let params = {
            mobile: mobile,
            password: password,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        };
        FssHttpService.postFormData("/api/fasUser/resetFasUserPassword", params, (apiResponse: any) => {
            if (apiResponse && (apiResponse.errorCode || apiResponse.message)) {
                FssHttpService.handleError(apiResponse);
            } else {
                successFunction(apiResponse);
            }
        }, FssHttpService.handleError);
    }
}