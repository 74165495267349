import * as React from "react";
import {ChangeEvent} from "react";
import {Button, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {Save} from "@material-ui/icons";
import FssAppWebsite from "../../model/FssAppWebsite";
import FtToast from "../../ui/FtToast";
import FssAppWebsiteService from "../../service/FssAppWebsiteService";
import {i18n} from "../../util/I18n";
import FtSnackbar from "../../ui/FtSnackbar";
import FtPage from "../../ui/FtPage";
import FtPanel from "../../ui/FtPanel";
import FtRow from "../../ui/FtRow";
import JtTextField from "../../ui/JtTextField";
import FtUtil from "../../util/FtUtil";

export class FssGooglePageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();

}

export default class FssGooglePage extends React.Component<any, FssGooglePageState> {

    constructor(props: any) {
        super(props);
        this.state = {appWebsite: new FssAppWebsite()};
        this.load = this.load.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.appWebsite, event.target.id, event.target.value);
        this.forceUpdate();
    }

    private load() {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite, (appWebsite: FssAppWebsite) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
            this.setState({appWebsite: appWebsite});
        });
    }

    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                color="primary"
                                                startIcon={<Save/>}
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}
                       toolbarCenterNode={<Typography variant="h6">{i18n("Google参数设置")}</Typography>}>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1"
                                              color={"secondary"}>Google Analytics {i18n("设置")}</Typography>}>
                <FtRow cellWidthS={"1"} cellHorizontalSpace={16}>
                    <FormControlLabel control={<Checkbox id="googleGaFlag" color="primary"
                                                         checked={this.state.appWebsite.googleGaFlag === undefined ? false : this.state.appWebsite.googleGaFlag}
                                                         onChange={(event) => {
                                                             this.state.appWebsite.googleGaFlag = event.target.checked;
                                                             this.forceUpdate();
                                                         }}/>}
                                      label={i18n("开启") + " Google Analytics"}/>
                    {/*<JtTextField id="googleMapKey" label={i18n("Google Key")}*/}
                    {/*             disabled={!this.state.appWebsite.googleGaFlag}*/}
                    {/*             value={this.state.appWebsite.googleMapKey}*/}
                    {/*             style={{width: "100%"}}*/}
                    {/*             onChange={this.onChange}/>*/}
                    <JtTextField id="googleGatrackingId" label={i18n("GA Tracking Id")}
                                 disabled={!this.state.appWebsite.googleGaFlag}
                                 value={this.state.appWebsite.googleGatrackingId}
                                 style={{width: "100%"}}
                                 onChange={this.onChange}/>
                </FtRow>
            </FtPanel>
        </FtPage>;
    };

}