import * as React from "react";
import {ChangeEvent} from "react";
import {Button, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FtToast from "../ui/FtToast";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import {grey} from "@material-ui/core/colors";
import {FssWebSession} from "../model/FssWebSession";
import FssUserService from "../service/FssUserService";
import FtSnackbar from "../ui/FtSnackbar";
import FssClientData from "../data/FssClientData";

interface FssLoginDialogProp {
    defaultOnSuccess?: () => void;
}

export class FssLoginDialogState {
    open: boolean = false;
    loginName: string = "";
    password: string = "";
    message: string = "";
    successFunction?: (data: FssWebSession) => void;
    onCancel?: () => void;
}


export default class FssLoginDialog extends React.Component<FssLoginDialogProp, FssLoginDialogState> {
    static loginDialog: FssLoginDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            loginName: "",
            password: "",
            message: ""
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {
    }

    onCancelClick(): void {
        this.setState({open: false});
        if (this.state.onCancel) {
            this.state.onCancel();
        }
    }

    onLoginClick(): void {
        if (this.state.loginName == "") {
            FtSnackbar.FtSnackbar.show(i18n("请输入手机号"));
            return;
        }
        if (this.state.password == "") {
            FtSnackbar.FtSnackbar.show(i18n("请输入密码"));
            return;
        }
        FtToast.showToastLoading(i18n("正在登录"), 10000);
        FssUserService.loginBySuperAdmin(this.state.loginName, this.state.password, (data: FssWebSession) => {
            FtToast.closeToast();
            FssClientData.updateWebSession(data);
            this.setState({open: false});
            if (this.state.successFunction) {
                this.state.successFunction(data);
            } else if (this.props.defaultOnSuccess) {
                this.props.defaultOnSuccess();
            }
        });
    }

    show(successFunction?: (data: FssWebSession) => void, onCancel?: () => void): void {
        this.setState({open: true, successFunction: successFunction, onCancel: onCancel});
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (event.target.id == "loginName")
            this.setState({loginName: event.target.value});
        else if (event.target.id == "password")
            this.setState({password: event.target.value});
    }

    render() {
        return <Dialog open={this.state.open} fullScreen={true}
                       PaperProps={{
                           className: "paddingTopSafeAreaInset",
                           style: {backgroundColor: grey[200], position: "relative", alignItems: "center"}
                       }}>
            <FtPanel style={{width: 500, position: "absolute", top: "50%", transform: "translateY(-100%)"}}>
                <FtGrid style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={16} cellHorizontalAlign={"center"}>
                        <Typography variant={"h5"}>{i18n("请登录")}</Typography>
                        <JtTextField type={"text"} id="loginName" label={i18n("请输入手机号")} required={true}
                                     style={{width: "100%"}} variant={"outlined"}
                                     value={this.state.loginName}
                                     onChange={(event) => {
                                         this.setState({loginName: event.target.value})
                                     }}/>
                        <JtTextField id="password" type="password" label={i18n("密码")} onChange={this.onChange}
                                     required={true}
                                     style={{width: "100%"}} variant={"outlined"}/>
                        <Button onClick={this.onLoginClick} color="primary" variant={"contained"}
                                style={{width: "100%"}}>
                            {i18n("登录")}
                        </Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </Dialog>;
    }
}
